import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion } from '../../components';
import { useLocalization } from '../../hooks/useLocalization';
import { QuizQuestions } from '../SpeakingLesson/components';
import {EventLogs, MemberStatus} from "../../components/Constants";
import useAnalytics from "../../hooks/useAnalytics";
import ReactPlayer from "react-player";
import useAuth from '../../hooks/useAuth';
import { url } from '../../routes/utility';

const Videos = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const location = useLocation();
    const navigate = useNavigate();
    const { member } = useAuth();

    const language = useSelector(state => state.localizationStore.language);
    const videoData = location.state?.videoData;
    const isActiveStatus = +member?.Status === MemberStatus.Active;

    const [isStartQuiz, setIsStartQuiz] = useState(false);
    const [quizId, setQuizId] = useState(false);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [iframeLoading, setIframeLoading] = useState(true);
    const [openedAccordionIndex, setOpenedAccordionIndex] = useState(-1);
    const [grammarTitle, setGrammarTitle] = useState("");

    //Video url'inden video id'sini yakalayan fonksiyon
    const getVideoId = (url) => {
        const params = new URLSearchParams(new URL(url).search);
        return params.get('v');
    }

    const handleStartQuiz = (idx, content) => {
        if (idx > 4 && !isActiveStatus) {
            navigate(url('market.packagecatalog'));
            return;
        }

        setOpenedAccordionIndex(openedAccordionIndex => idx)
        setQuizId(quizId => content.id);
        setQuizQuestions(quizQuestions => content.questions.map((item) => ({
            ...item,
            questionAnswers: item.questionAnswers.map((answer, index) => ({
                ...answer,
                id: index + 1
            }))
        })));
        setGrammarTitle(content.translation["en"]);

        //LOGGED
        analytics.useAnalytics(EventLogs.RESOURCES_START_TEST, {'kaynak': content.translation["en"] })
    }

    useEffect(() => {
        if (openedAccordionIndex !== -1) {
            !isStartQuiz && window.scrollTo({ top: 125 + openedAccordionIndex * 59, behavior: "smooth" });
        } else {
            //Sayfa ilk yüklendiğinde yukarıya scroll ettiriliyor.
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [openedAccordionIndex, isStartQuiz])

    return (
        <div className="main-content">
            {!isStartQuiz && (
                <div className="transparent-section mt-5">
                    <h5 className="text-secondary">{strings.dashboard.student.all_sources}</h5>
                    <p className="mt-3 text-center">{strings.dashboard.student.all_sources_description}</p>
                    <div className="flex flex-col gap-3 pt-4">
                        {videoData?.contents.map((content, idx) => {

                            //Video url'ini embed video url'ine dönüştürüyoruz.
                            const videoId = getVideoId(content.url);
                            const embedUrl = `https://www.youtube.com/embed/${videoId}`

                            return (
                                <React.Fragment key={idx}>
                                    {idx === 5 && !isActiveStatus && (
                                        <div className="mb-5 btn-outer-div">
                                            <button 
                                                className="button primary-button" 
                                                onClick={() => {
                                                    analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_LOCKED_CLICK);
                                                    navigate(url('market.packagecatalog'));
                                                }}
                                            >
                                                {strings.teachers.teacher_details.not_student_buy_button}
                                            </button>
                                        </div>
                                    )}
                                    <div className={classNames("",{"-mt-7": idx !== 0})}>
                                        <Accordion
                                            title={language === "tr" ? content.title : content.translation[language]}
                                            isOpen={openedAccordionIndex === idx}
                                            isDisabled={idx > 4 && !isActiveStatus}
                                            eventObject={{
                                                event: EventLogs.RESOURCES_OPEN_CONTENT,
                                                params: { 'kaynak': content.translation["en"] }
                                            }}
                                            children={
                                                <>
                                                    <div className="max-w-[500px] px-3 mx-auto relative w-full">
                                                        {iframeLoading && (
                                                            <div
                                                                className="animate-pulse bg-gray-300 rounded-md aspect-video w-full flex items-center justify-center">
                                                                <div
                                                                    className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                                            </div>
                                                        )}
                                                        <div className={`${iframeLoading ? 'hidden' : 'block'} flex items-center mt-2 rounded-[10px] aspect-video mx-auto overflow-hidden`}>
                                                            <ReactPlayer
                                                                url={embedUrl}
                                                                controls
                                                                className="react-player h-full"
                                                                onPlay={() =>
                                                                    analytics.useAnalytics(EventLogs.RESOURCES_VIDEO_PLAY, {'kaynak': {'kaynak': content.translation["en"]}})
                                                                }
                                                                onReady={() => setIframeLoading(false)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className='text-start mt-1  max-w-[500px] px-3 mx-auto'><span
                                                        className='font-bold'>{strings.dashboard.student.video_duration}: </span>{content.duration}
                                                    </p>
                                                    <div className="btn-outer-div my-5">
                                                        <button
                                                            onClick={() => handleStartQuiz(idx, content)}
                                                            className="button primary-button"
                                                        >
                                                            {strings.speaking_lesson.daily_sentence.start_quiz}
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                        />
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            )}
            {isStartQuiz && (
                <QuizQuestions
                    quizId={quizId}
                    bookName={grammarTitle}
                    quizInformation={quizQuestions}
                    setIsStartQuiz={setIsStartQuiz}
                    source="resources"
                />
            )}
            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default Videos