export const Local = {

    brand_name: 'Azər Ingiliscə',

    errors: {
        no_records_found: 'Heç bir qeyd tapılmadı.',
    },

    menu: {
        nav: {
            menu1: "Əsas Menyu",
            menu2: "Danışıq Dərsi",
            menu3: "Müəllimlər",
            menu4: "Market",
            menu5: "Dəstək",
        },
        collapse: {
            messages: "Mesajlar",
            profile: "Profil",
            support: "Dəstək",
            reference: "Dostu dəvət et",
            demo: "Sınaq dərsi keçin",
            logout: "Çıxış",
            contact_us: "Bizə Ulaşın",
            review_packages: "Təhsil Paketlərini Kəşf Edin",
            modal: {
                no_demo_description: "Demo dərs almaq hüququnuz yoxdur.<br/><br/>Demo dərs hüquqları üçün mentorunuzla əlaqə saxlamalısınız.",
                already_have_active_demo: "Aktiv demo dərsiniz var.",
                contact_mentor: "Mentorla əlaqə saxlayın"
            }
        },
        callbar: {
            need_permission: "Bu ekrandan dərslərdə iştirak etmək üçün bildiriş və mikrofon icazəsi verməlisiniz.",
            allow_now: "İcazə verin",
            permission_error: "İnternet brauzeriniz bildiriş icazəsi sorğusunun görünməsinə mane olur. Aşağıdakı linkə klikləməklə bu parametrləri necə etmək barədə kömək ala bilərsiniz.",
            join_your_lesson_button: "Dərsə Qoşul",
            join_your_lesson_message: "<b>Dərsə Qoşul</b> düyməsinə klikləməklə dərsiniz üçün müəlliminizi gözləməyə başlaya bilərsiniz.",
            calling_you: "#teacher# sizə zəng çalır.", //Teacher Jasmine is calling you now.
            talking_to_now: "#teacher# ilə dərsiniz başladı.", //Teacher is talking to you now.
            wait_while_connecting: "Bağlantı qurulana qədər gözləyin.", //Please wait while connecting.
            an_error_occured: 'Əməliyyat zamanı xəta baş verdi.', //An error occurred during operation.
            no_lesson_for_today: 'Bu gün üçün heç bir dərsiniz yoxdur.',//You do not have any lesson today.
            not_in_available_time_range: 'Siz uyğun zaman dilimində deyilsiniz. Dərs saatınızdan 5 dəqiqə əvvəl yenidən cəhd edin.', //You are not in available time range! You can join your lesson 5 minutes before.
            poor_connection: '(İnternet bağlantınız zəifdir. Bağlantınızı yoxlayın.)',
            connection_lost: 'İnternet bağlantınız kəsildi. Müəlliminiz sizə təkrar zəng edəcək.',
            general_error_message: 'Gözlənilməz xəta baş verdi. Zəhmət olmasa sorğu göndərin.',
            callbar_refresh_alert_message: 'Əvvəl icazə vermiş ola bilərsiniz, lütfən səhifənizi yeniləyin.\n Səhifənizi yenilədikdən sonra hələ də İcazə Verin düyməsini görürsünüzsə, icazənizi verin.',
            refresh_page: 'Səhifəni yeniləyin',
            download_application: "Tətbiqə keçin",
            not_avaliable_text: "Zəngləri qəbul etmək üçün mobil tətbiqimizdən istifadə etməlisiniz."
        }
    },

    modal: {
        okey: "Tamam",
        approve: "Təsdiq Edin",
        dont_approve: "İmtina Etmək",
        information_message: "Məlumat Mesajı",
        transaction_information: "Əməliyyatın Təsdiqi",
        required_field_error: "Tələb olunan (*) sahələri boş buraxmaq olmaz. Bütün tələb olunan sahələri doldurmanız lazımdır."
    },

    //auth screens
    auth: {
        layout: {
            right_subtitle_1: "Biz 50.000+ Tələbəsi olan Azərbaycanın Ən Böyük Onlayn İngilis Dili Məktəbiyik!",
            right_subtitle_2: "\“Əla ingilis dili kursu. Bu mənim həyatımı dəyişdi.” Vaqif Əzizov.",
            logo_subtitle: 'İngilis dilində ana dili kimi danışmaq artıq xəyal deyil!',
        },
        form: {
            your_name: 'Ad Soyad',
            create_your_name: 'Ad soyad məlumatını daxil edin',
            phone_number: 'Mobil Telefonu',
            enter_current_password: "Şifrənizi daxil edin",
            your_password: 'Şifrə',
            your_password_again: 'Şifrəniz (Tekrar)',
            current_password: 'Cari Şifrəniz',
            create_your_password: 'Şifrənizi formalaşdırın',
            enter_your_password: 'Şifrənizi daxil edin',
            enter_your_password_again: 'Şifrənizi yenidən daxil edin',
            enter_your_phone_number: 'Mobil Telefonu',
            enter_code: 'Kodu daxil edin',
            enter_sms_code: 'SMS ilə göndərilən kodu daxil edin.',
            sign_up_button: 'Qeydiyyat',
            sign_in_button: 'Daxil Ol',
            forgot_password_button: 'Şifrəmi Unutdum',
            okay_button: 'Tamam',
            send_button: 'Göndər',
            change_button: 'Değiştir',
            watch_video: 'Videoya baxın',
            verification_button: 'Təsdiqlə',
            privacy_and_terms_1: 'Üzv olaraq Azeringilisce ',
            privacy_and_terms_2: 'məxfilik müqaviləsi ',
            privacy_and_terms_3: 'və ',
            privacy_and_terms_4: 'istifadə şərtlərini ',
            privacy_and_terms_5: 'qəbul etmiş sayılırsınız.'
        },
        information_messages: {
            forgot_password_sentence_1: 'Zəhmət olmasa sistemimizə qeydiyyatlı telefon nömrənizi daxil edin və Göndər düyməsini sıxın ',
            forgot_password_sentence_2: 'Şifrəniz telefonunuza sms ilə göndəriləcək.',
            forgot_password_back: 'Girişimi qeyd etmək istəyirəm',
            forgot_password_verification_code_sentence_1: 'Telefonunuza SMS vasitəsilə göndərilən kodu daxil edin və Təsdiqlə düyməsini sıxın.',
            forgot_password_verification_code_sentence_2: 'Təsdiqləmə əməliyyatı uğurlu olarsa, şifrə dəyişikliyi əməliyyatını həyata keçirə bilərsiniz.',
            change_password_success_message: 'Siz şifrənizi uğurla dəyişdiniz.\nGiriş ekranına keçərək telefon nömrəniz və yeni şifrənizlə daxil ola bilərsiniz.',
            code_not_expired: "Son kodun müddəti hələ bitməyib!",
            modal_title: 'Məlumatlandırma Mesajı',
        },
        validation_messages: {
            //1
            invalid_name: 'Zəhmət olmasa ad və soyadınızı daxil edin.',
            invalid_phone: 'Xahiş edirik mövcud mobil nömrə yığasınız!',
            invalid_password: 'Şifrəniz ən azı 4 simvoldan ibarət olmalıdır!',
            invalid_verification_code: 'Doğrulama kodu 4 simvoldan ibarət olmalıdır',
            invalid_change_password: 'Daxil etdiyiniz parollar minimum 4 simvoldan ibarət olmalı və bir-biri ilə eyni olmalıdır.',
            already_created_user: 'Bu telefon nömrəsi ilə daha  əvvəl qeydiyyatdan keçilmişdir.\n\nŞifrənizi bilirsinizsə daxil ola bilərsiniz,  şifrənizi unutmusunuzsa şifrəmi unutdum bölməsinə daxil olub şifrənizi tələb edə bilərsiniz.',
            invalid_username_or_password: 'Daxil etdiyiniz məlumatlara uyğun heç bir qeydiyyat tapılmadı.\nZəhmət olmasa məlumatlarınızı yoxlayın və yenidən cəhd edin.',
            you_entered_an_incorrect_verification_code: 'Doğrulama kodunu yanlış daxil etmisiniz.\nZəhmət olmasa kodu yoxlayın və yenidən cəhd edin.',
        },

    },

    date: {
        today: "Bugün",
        yesterday: "Dünən",
        days_ago: "gün əvvəl"
    },

    //onboarding screens
    onboarding: {
        welcome_1: 'Xoş gəldiniz,',
        welcome_2: 'bir neçə sualımız var.',
        welcome_2_single: 'Bir sualımız var.',
        next_button: 'Növbəti',
        continue_button: 'Növbəti',
        first: {
            title: 'İngilis dili səviyyəniz necədir?',
            button1: 'İngilis dili bazam sıfırdır',
            button2: 'Çox az başa düşürəm',
            button3: 'Qrammatika var, danışıq yox',
            button4: 'Çox asanlıqla çıxış ede bilirəm',
            button5: 'Shakespeare\'dən fərqsizəm',
            level1: "Başlanğıc (Starter)",
            level2: "Təməl (Beginner)",
            level3: "Orta (Intermediate)",
            level4: "İrəli (Advanced)"
        },
        second: {
            title: 'İngilis dilini niyə öyrənmək istəyirsiniz?',
            button1: 'İşim/karyeram üçün',
            button2: 'Xaricə səyahət üçün',
            button3: 'Təhsilim üçün',
            button4: 'Digər',
            use_case1: "Biznesdə istifadə etmək",
            use_case2: "Karyeranızda yüksəlmək",
            use_case3: "İşgüzar görüşlərdə istifadə etmək",
            use_case4: "İş müsahibəsində iştirak etmək",
            use_case5: "Beynəlxalq işgüzar səfərlər",
            use_case6: "Xaricdə tətil",
            use_case7: "Xaricə işləmək üçün getmək",
            use_case8: "Yaxın qohum və ya dostla ünsiyyət",
            use_case9: "Məktəbdə istifadə edəcək şagirdlər",
        },
        third: {
            title: 'Peşəniz nədir?',
            button1: 'Akademik',
            button2: 'Hüquqşünas',
            button3: 'Həkim',
            button4: 'Məmur',
            button5: 'Mühəndis',
            button6: 'Tələbə',
            button7: 'Müəllim Müəllimə',
            button8: 'Özəl sektor',
            button9: 'Müdir',
            button10: 'Maliyyə',
            button11: 'Turizm',
            button12: 'Digər',
        },
        success: {
            congratulations: 'Təbrik edirik!',
            profile_created: 'Profilinizi uğurla yaratdınız.',
            subtitle: 'İndi Azərbaycanın ən effektiv ingilis dili tədris sistemi ilə tanış olmaq vaxtıdır.'
        },
        information_messages: {
            an_error_occured: 'Əməliyyat zamanı xəta baş verdi. Zəhmət olmasa bir daha cəhd edin.',
            make_a_choice: 'Davam etməzdən əvvəl seçim etməlisiniz. Zəhmət olmasa seçimlərdən birini işarələyin və Növbəti düyməsini basın.',
            max_choice_message: 'Maksimum #count# seçim edə bilərsiniz.'
        },
    },

    onboarding_exercises: {
        title: "Günün çalışması",
        dashboard_message_1: "Dialoq, lüğət, cümlə quruluşu və qrammatikadan ibarət ödənişsiz təhsil proqramınız formalaşdırılmışdır.",
        dashboard_message_2: "Aşağıdakı düyməyə basaraq təlimə davam edə bilərsiniz.",
        start_study_error_message: 'Təəssüf, 3 günlük müddətiniz bitdi.',
        day_text: '#dayIndex#. Gün',
        start_study: "İstifadəyə Başla",
        translate: "Tərcüməni Göstər",
        voice: "Dialoqu Səsləndir",
        record_dialog: 'Dialoqu Yaddaşda Saxla',
        recording: 'Qeyd edilir',
        correct_answer: "Düz cavab verdiniz.\nTəbrik edirəm.",
        wrong_answer: "Səhv cavab verdiniz.\nDaha çox çalışmalısınız.",
        congratulate: '"Təbriklər',
        final_message: "Bu gün bir dialoq, üç söz, bir cümlə nümunəsi və bir qrammatik qaydası öyrəndiniz.",
        final_message_2: 'Sabah yeni çalışmalarda görüşənədək.',
        your_answer: "Cavabınız",
        right_answer: "Düzgün cavab",
        read_dialog: "Aşağıdakı dialoqu oxuyun",
        complete_the_blank: "Boşluğu müvafiq sözlə tamamlayın.",
        sentence_pattern: "Cümlə quruluşu",
        grammar_rule: "Qrammatika Qaydaları",
        dialog_info: "Dialoqu oxuyarkən başlıqları (A,B) oxumayın."
    },

    mentor: {
        title: "Şəxsi Mentorunuz",
        dashboard_message_1: "Mentorunuzla şəxsi səyahətinizdə birlikdə irəliləyin!",
        dashboard_message_2: "Mentorunuzla birlikdə məqsədlərə addım-addım yaxlaşın, rəhbərlik və dəyərli rəylərlə şəxsi inkişafınızı gücləndirin!",
        about_mentor: "Mentor Haqqında",
        mentor_informations: "Mentor haqqında məlumat",
        name: "Adı",
        phone: "Telefon Nömrəsi",
        mail: "E-poçt Ünvanı",
        working_hour: "İş Saatları",
        what_is_mentor: "Mentor Nədir?",
        mentor_content1: "Müəlliminizlə tərəqqinizi izləyir.",
        mentor_content2: "Məsləhət və rəhbərlik edir.",
        mentor_content3: "Ara qiymətləndirmə görüşləri keçirir.",
        write_whatsapp: "Whatsappdan Yazın",
        default_mentor: "Mövcud Mentor",
        weekday: "Həftə İçi",
        hello: "Salam"
    },


    messages: {
        your_mentor: "Mentorunuz",
        interested: "Maraqlanıram",
        not_interested: "Maraqlanmıram",
        no_messages: "Hələ heç bir mesajınız yoxdur",
        no_messages_description: "Yeni mesaj gələndə burada görünəcəkdir"
    },

    general_information_messages: {
        prev: "<",
        next: ">",
        an_error_occured: 'Əməliyyat zamanı xəta baş verdi. Zəhmət olmasa bir daha cəhd edin.',
        minute: 'dəq',
        loading: "Yüklənir...",
        processing: "Əməliyyat Aparılır...",
        under_construction_title: "Hazırlanır",
        under_construction_content: "Proqramınızı güncəl saxlamağı unutmayın.",
        error404_title: "Səhifə tapılmadı",
        error404_content: "Axtardığınız səhifə tapılmadı. Menyulardan istifadə edərək istədiyiniz səhifəyə daxil ola bilərsiniz.",
        error500_title: "Data yüklənərkən xəta baş verdi. Səhifəni yeniləyin."
    },

    pagination: {
        next: "İrəli",
        previous: "Geri"
    },

    lesson_type: {
        active: "Aktiv",
        future: "Aktiv (Gələcək Tarix)",
        inactive: "Passiv",
        pending: "Təsdiq Gözlənir",
        attended: 'Dərsə Qatılıb',
        missed: "Dərs Buraxılıb",
        postponed: "Təxirə Salındı",
        frozen: "Dondurulmuş",
        holiday: "Tətil",
        weekend: "Həftə Sonu",
        no_lesson: "Dərs Yoxdur",
        system_postponed: "Sistem Tərəfindən Təxirə Salınıb",
        deleted: "Silinib"
    },

    dashboard: {
        title: "Azer İngilisce",
        none_user: {
            title_1: 'Salam',
            title_2: 'Azərbaycanın ən effektiv ingilis dili öyrənmə sisteminə xoş gəldiniz.',
            inactive_student_title_1: 'Təkrar xoş gəldiniz,',
            inactive_student_title_2: 'Paketinizin vaxtı keçib.',
            inactive_student_title_3: "İnkişafınızı qaldığınız yerdən davam etdirmək üçün <strong class=\"text-primary\">Təlim Paketlərimizi</strong> nəzərdən keçirə bilərsiniz.",
            inactive_student_title_4: 'Keçmiş dərslərinizə baxmaq üçün <strong class=\"text-primary\">klikləyin.</strong>',
            advice_1: 'Bu inkişafınıza olan investisiyadır,',
            advice_2: 'İngilis dilini öyrənin!',
            more_info: 'Ətraflı məlumat',
            view_packages: 'Təhsil Paketlərinə Göz Atın',
            student_comments: 'Tələbə Rəyləri',
            play_video: 'Videoyu İzle'
        },
        how_to_learn: {
            play_video: 'Videoyu İzle',
            most_curious_about: 'Ən çox maraqlandıklarınız',
        },
        demo_student: {
            title: "Günün Dərsi",
            try_demo_title: "Ödənişsiz Sınaq Dərsi ilə Azeringilisce-ni  yoxlayın",
            try_demo_content: "Ana dili ingilis dili olan müəllimlərimizlə tanış olun və Azeringilisce-ni sınayın.",
            plan_demo_lesson: "Sınaq Dərsi Planlayın",
            demo_lesson_reserved: "Sınaq Dərsiniz Uğurla Rezervasiya Edildi!",
            no_permission_text: "<strong>Sınaq dərsinizə hazırlaşın!</strong></br>Sınaq dərsinizdə iştirak etmək üçün Azeringilisce mobil proqramını yükləməli və lazımi icazələri verməlisiniz.",
            permission_text: "<strong>Hər şey hazırdır!</strong></br>Sınaq dərsinizdə iştirak etmək üçün hər şey hazırdır! Dərs vaxtınız çatdıqda, müəlliminiz Azeringilisce mobil proqramı və veb platforması vasitəsilə sizə zəng edəcək.",
            change_demo_lesson_time: "Dərs vaxtınızı #button#buradan#button# dəyişə bilərsiniz.",
            demo_lesson_completed: "Sınaq Dərsinizi Bitirdiniz",
            lesson_completed_content: "#TEACHER# ilə sınaq dərsiniz tamamlandı. Müəlliminizin rəyinə baxmaq üçün #button#buraya#button# klikləyin.",
            lesson_info: "Salam <b>#name#</b>, növbəti danışıq dərsiniz <b>#date# saat #time#-da</b> başlayacaqdır. Dərsinizə təxminən <b>#counter#</b> qaldı. Dərs vaxtınız çatdıqda, müəlliminiz sizə zəng edəcək. Mobil tətbiqimizi quraşdırmaqla siz dərs öncəsi xatırlatmalar ala və daha yaxşı dərs təcrübəsinə sahib ola bilərsiniz.",
            microphone_permission: "Dərsinizə əgər web brauzeriniz üzərindən qatılmaq istəyirsinizsə, mikrofon və bildiriş icazələri verməlisiniz.",
            download_mobil_app: "Mobil Tətbiqi yükləyin",
            give_microphone_permission: "Mikrofon icazəsi verin",
            give_notification_permission: "Bildirişlərə icazə verin",
        },
        student: {
            title: "Günün Danışıq Dərsi",
            word_of_the_day: "Günün Sözü",
            idioms_of_the_day: "Günün İngilis İdiomları",
            view_the_lesson_book: "Dərs kitabınız üçün klikləyin",
            click_for_previous_lessons: "Keçmiş Dərsləriniz üçün Klikləyin",
            want_to_postpone_class: "Dərsimi təxirə salmaq istəyirəm",
            all_sources: "Bütün Resurslar",
            grammar_videos: "Qrammatika Videoları",
            business_english_videos: "Biznes İngilis dili Videoları",
            all_sources_description: "Videoçarx olaraq hazırlanmış dərslər və interaktiv testlərlə öz inkişafınızı təmin edin.",
            video_duration: "Video Müddəti",
            no_lesson_info: "Dərs məlumatını yükləmək mümkün olmadı.",
            no_teacher_info: "Müəllim məlumatını yükləmək alınmadı.",
            your_teacher: "Müəlliminiz ",
            your_female_teacher: "Müəlliməniz ",
            lesson_info: "Salam <b>#name#</b>, növbəti danışıq dərsiniz <b>#date# saat #time#-da</b> başlayacaqdır. Dərsinizə təxminən <b>#counter#</b> qaldı. Dərsdən əvvəl materiallarınıza baxdınızmı?",
            day: "gün",
            hour: "saat",
            minute: "dəq",
            second: "saniyə",
            dashboard_passive_lesson_error: "Seçilmiş paket bitmiş dövrə aiddir",
            in_lesson_time_message: "Salam <b>#name#</b>, son dərsiniz üçün məlumatınız gözlənilir."
        },
        video_exercise: {
            title: "Videolarla İngilis Dilini Öyrənməkdən Zövq Alın!",
            content: "İngilis dilində danışıq, lüğət, yazı, dinləmə və tələffüzü mənimsəmək üçün faydalı videolar və interaktiv fəaliyyətlər dünyasına addım atın.",
            button_text: "Kəşf etməyə başlayın"
        },
        yearly_summary: {
            card_title: '#year# ilində nələr etdiniz?',
            card_content: 'İl sonu xülasəsinə baxın',
            modal: {
              content: 'Əla il keçirdiniz! 🎉 Budur ingilis dilini öyrənmək səyahətinizdə əldə etdiyiniz uğurlar. Özünüzü alqışlamağı unutmayın! 👏',
              total_lesson_count: 'İştirak Etdiyiniz Dərs',
              total_minute: 'Ümumi Dəqiqə',
              ai_session: 'Süni İntellektlə Məşq',
              total_word: 'Tələffüz Etdiyiniz Söz',
              total_sentence: 'Tələffüz Etdiyiniz Cümlə',
              close: 'Bağla'
            }
        }
    },

    profile: {
        title: "Profil",
        profile_settings: "Profil Parametrləri",
        save_button: "Yadda Saxla",
        success_message: "Profil parametrləriniz uğurla yeniləndi.",
        dont_ask_personal_information: "Məktəbdə istifadə etmək üçün tələbələr, şagirdlər",
        dont_ask_how_was_your_day: "Xahiş edirəm günün necə keçdiyini soruşma.",
        profile_visibility_open: "Profilin görünməsi aktivdir",
        profile_visibility_off: "Profilin görünməsi deaktivdir",
        following: "İzlənilən",
        follower: "İzləyici",
        follow: "İzləyin",
        you_are_following: "İzləyirsiniz",
        add_friend: "Tanışları əlavə et",
        join_date_text: "#date# tarixində qoşulub",
        unlock_profile: "Profilin kilidini açın",
        go_to_profile: "Profilimə keçin",
        lesson_method: {
            title: "Dərs keçirilməsi metodu",
            title_one: "Kitaba tamamilə sadiq qalınsın",
            title_two: "Kitaba əsaslanan sərbəst danışıqlar edilsin"
        },
        feedback: {
            title: "Müəllimdən Rəy Alma Növü",
            title_one: "Dərsin sonunda rəy bildirilsin",
            title_two: "Dərs zamanı dərhal rəy bildirilsin"
        },
        reason_to_learn_english: {
            title: "İngilis dilini niyə öyrənmək istəyirsiniz? (Birdən çox seçim edilə bilər)",
            error_message: "İngilis dilini öyrənmək üçün ən azı bir səbəb seçmək məcburidir!",
            1: "İş həyatında istifadə",
            2: "Karyeranızda yüksəliş",
            3: "Karyeranızda yüksəliş",
            4: "İş müsahibəsində iştirak",
            5: "Beynəlxalq işgüzar səfərlər",
            6: "Xaricdə tətil",
            7: "İşləmək üçün xaricə getmək",
            8: "Yaxın qohum və ya dostla ünsiyyət",
            9: "Məktəbdə istifadə etmək üçün tələbələr, şagirdlər"
        },
        statistics: {
            title: "Statistikalar",
            weekly_streak: "Həftəlik seriya",
            total_lesson_duration_title: "İngiliscə danışıq",
            total_lesson_duration: "#minutes# deq",
        },
        recommendations: {
            title: "Tanış Təklifləri",
            multiple_recommend_member_text: "#name# və +#count# nəfər izləyir",
            single_recommend_member_text: "#name# izləyir"
        },
        follow_list: {
            following: "#count# İzlənilən",
            follower: "#count# İzləyici",
            profile_click: "Profilə keçin",
            following_empty_text: "İzlədiyiniz insanlar bu sahədə göstəriləcək.",
            follower_empty_text: "Sizi izləyən insanlar bu sahədə göstəriləcək."
        },
        profile_operations: {
            report_member: "İstifadəçini hesabatla",
            unfollow: "İzləməni dayandırın",
            report_member_approve_text: " <b>#name#</b> haqqında şikayət etmək istədiyinizə əminsiniz?",
            report_member_success_text: "Siz <b>#name#</b> adlı istifadəçini şikayət etdiniz.",
            unfollow_member_approve_text: "<b>#name#</b> adlı istifadəçinin izlənməsini dayandırmaq istədiyinizə əminsiniz?",
            unfollow_member_success_text: "Siz <b>#name#</b> adlı istifadəçini izləməyi dayandırdınız."
        },
        leaderboard: {
            ranking: "Sıralama",
            weekly_streak: "Həftəlik Nəticələr",
            total_lesson_duration: "#duration# deq Toplam Dərs Müddəti",
        },
        calendar: {
            calendar_title: "Seriya Cədvəli",
            disable_calendar_text: "Seriya Təqviminiz Bu Sahədə Göstəriləcəkdir",
            active_streak: "Davam edən Seriya",
            end_streak: "Bitmiş Seriya Həftəsi",
            start_streak: "Seriya Başlanğıcı"
        },
        awards: {
            all_awards_title: "Bütün Hədiyyələr",
            award_description: "Hər həftə ən azı bir dərsə qatılaraq seriyaya davam edə və sürpriz hədiyyələr qazana bilərsiniz.",
            all: "Hamısı",
            awards_title: "Hədiyyələr",
            reward_title: "#streakCount# Həftəlik Seriya",
            reward_content: "Ödülü kazanmak için #streakCount# haftalık seriyi tamamlayınız.",
        }
    },

    demo: {
        title: "Demo Dərs",
        page_title: "Dərs gününüzü və saatınızı seçin",
        description: "Aşağıda sadalanan müəllimlər arasından seçim edərək 10 dəqiqəlik sınaq dərsinizi keçə bilərsiniz.",
        approve_message: "Sınaq dərsiniz #date# saat #hour#da keçiriləcək. \nƏməliyyatı təsdiq edirsiniz?",
        success_message: "Sınaq dərsiniz #date# saat #hour#da keçiriləcək. <br/><br/>Brauzerinizdə Azeringilisce səhifəsini açıq saxlayın və gələn zəngə cavab verin.",
        term_not_found: "Semestr tapılmadı!",
        missed_call_not_found: "Aktiv dərs tapılmadı!",
        unit_selection: {
            title: "Dərsin məzmununu seçin",
            level_test: "Səviyyə ölçmə dərsi",
            free_talk: "Sərbəst mövzu dərsi",
            trial: "Öyrənmə məqsədinizə uyğunlaşdırılmış dərs",
            select_unit: "Zəhmət olmasa dərsin məzmununu seçin!"
        },
        level_selection: {
            title: "İngilis dili səviyyənizi seçin"
        },
        auto_teacher_selection: {
            available_hour: "Sizin üçün Ən Uyğun Saat Seçilir",
            available_teacher: "Sizin Üçün Ən Uyğun Müəllim Seçilir",
            approve_demo_lesson: "Sınaq Dərsinizi Təsdiqləyin",
            ai_choosing_teacher: "Süni intellektimiz səviyyənizi və məqsədlərinizi təhlil edir və sizin üçün ən uyğun müəllimi seçir.",
            ai_choosing_time: "Eyni zamanda, sizin münasibliyinizə uyğun olaraq ideal dərs vaxtını müəyyənləşdiririk.",
            selected_auto_teacher_description: "Dərs günü və saatı sizə uyğundursa, dərsinizi təsdiqləyin, istəsəniz dəyişə bilərsiniz.",
            approve_lesson_button: "Dərsi Təsdiqləyin",
            choose_different_time: "Fərqli bir gün və ya saat seçin",
            your_lesson_date: "Ders Tarihiniz"
        },
        demo_progress: {
            demo_lesson: "Sınaq dərsi",
            demo_lesson_description: "Sizə ən uyğun olan vaxtda ödənişsiz sınaq dərsi alın.",
            demo_lesson_plan: "Sınaq dərsi planlaşdırın",
            demo_lesson_prepare: "Sınaq dərsinə hazırlaşın",
            demo_lesson_attend: "Sınaq dərsində iştirak edin",
            demo_lesson_report: "Sınaq dərsi nəticələrini nəzərdən keçirin",
            demo_faq: "F.A.Q",
            demo_progress_step: "#completedStepCount#/4 tamamlandı"
        }
    },

    market:
    {
        title: 'Market',
        buy_button: 'Satın Al',
        okay_button: 'Tamam',
        gifts: {
            title: "Hədiyyələr"
        },
        additional_rights: {
            title: "Əlavə haqqlar",
            description: "Aşağıdakı variantlardan sizə uyğun olanı seçərək öyrənmə macəranıza fasiləsiz davam edə bilərsiniz.",
            postpone_description: "Dərs təxirə salma paketlərindən sizə uyğun olanı seçə bilərsiniz.",
            postpone_right: "#count# Ədəd Dərs Təxirə Salma Haqqı",
            postpone_right_checkout: "Siz #count# ədəd dərs təxirə salma haqqı üçün ödəniş edirsiniz.",
            market_passive_term_message: "Seçdiyiniz paket qeyri-aktiv paketdir. Aktiv olmayan paketlər üçün əlavə haqqlar almaq mümkün deyil.",
            market_future_term_message: "Seçdiyiniz paket gələcək tarixli paketdir. Gələcək paketlər üçün əlavə haqqlar almaq mümkün deyil.",
            market_pending_term_message: "Seçdiyiniz paket hələ təsdiqlənməyib. Paketiniz təsdiqləndikdən sonra əlavə haqqlar satın ala bilərsiniz.",
        },
        speakingLesson: {
            title: 'Təhsil Paketləri',
            title1: "Həftədə neçə gün dərs ala bilərsiniz?",
            title2: "Gündə neçə dəqiqə dərs ala bilərsiniz?",
            title3: "Neçə ay təhsilinizi davam etdirəcəksiniz?",
            day: "gün",
            minute: "dəqiqə",
            month: "Ay",
            month_single: "Ay",
            total_amount: "Ümumi Məbləğ",
            discount_content: "endirimlə ",
            discount_code: "endirim kodunu daxil edin",
            total_discount: "Ümumi Endirim",
            discount: "endirim",
            apply: "tətbiq et",
            remove_discount: "Tətbiq edilmiş endirimi silin",
            offer_title: "Sizə Özəl",
            price_by_per_lesson: "Dərs Başına Ödəniş",
            total_lesson_price_with_gift: "Toplam #totalLessonCount# Dərs (#totalLessonCountWithoutGift# Dərs + #giftLessonCount# Dərs Hədiyyə)",
            total_lesson_price: "#totalLessonCount# Dərs",
            slogan: "35% Endirimli Yay Kampaniyası Başladı. Məhdud sayda yerlər bitməmiş fürsətdən yararlanın!",
            selected_package: {
                title: 'Seçilmiş paket və məzmunu',
                package_display: '#M# Ay həftədə #D# gün gündə #LM# dəqiqə #T#',
                postpone_right: '#number# ədəd dərs təxirə salma hüququ',
                change_teacher_and_time_right: '#number# ədəd dərs təxirə salma hüququ',
                missed_call_right: '#number# ədəd dərsi qaçırdım hüququ',
                freeze_lesson_right: '2 aylıq kursun dondurulması',
                gift_month: ' aylıq hədiyyə təhsili',
                monthly: "#monthCount# Ay",
                weekly: "Həftədə #dayCount# Gün",
                daily: "Gündə #minuteCount# Dəqiqə",
                extra_rights_one: "Videoçarxlar, dərs materialları və testlər",
                extra_rights_two: "Süni intellektlə limitsiz dərs",
                extra_rights_three: "Sizə özəl mentor",
            },
            information_messages: {
                speaking_lesson_choose_all: 'Zəhmət olmasa, bütün seçimləri (Paket/Gün/Dəqiqə/Ay) edin və satın alma düyməsini basın.',
                modal_title: 'Məlumat Mesajı',
                same_discount_code_error: 'Bu endirim kodu artıq istifadə olunub. Eyni endirim kodunu təkrar istifadə edə bilməzsiniz!',
                same_type_code_error: 'Eyni növdə yalnız bir endirimdən istifadə etmək olar!',
            },

        },
        checkout: {
            price_without_discount: "Endirimsiz Məbləğ",
            price_without_tax: "ƏDV-siz məbləğ",
            tax_rate: "%#KDV# ƏDV",
            discount: "Tətbiq olunan endirim",
            sub_total: "Cəmi",
            click_for_pay_detail: "Ödəniş təfərrüatlarına baxmaq üçün klikləyin",
            total_amount: "Ümumi Məbləğ",
            package_amount: "Paketin Məbləği",
            reference_discount: "Referans endirimi",
            fourty_eight_discount: "48 saat endirimi",
            campaign_discount: "Kampaniya endirimi",
            family_package_discount: "Ailə Paketi Endirimi",
            second_purchase_discount: "İkinci satın alma endirimi",
            moneybox_discount: "Yığılmış endirimlər",
            one_shot_discount: "nağd ödəniş endirimi",
            other_installment_discount: "Digər taksit alternativləri",
            discount_success_message: "Endirim kodunuz uğurla tətbiq olundu.",
            discount_unsuccess_message: "Siz yanlış endirim kodu daxil etmisiniz. Zəhmət olmasa bir daha cəhd edin.",
            discount_limit_exceeded: "Mövcud endirim miqdarı 100%-ə çatdığı üçün yeni endirim kodu əlavə edilə bilməz.",
            cancel_discount: "Endirimi ləğv edin",
            discounts_cleared: "Tətbiq olunan bütün endirimlər sıfırlandı!",
            input_card_info: "Kart məlumatlarını daxil edin",
            card_number: "Kart Nömrəsi",
            card_name_surname: "Kartın Üzərindəki Ad",
            card_exp_date: "İstifadə Müddəti",
            month: "Ay",
            year: "İl",
            bank: "Bank",
            cvv: "CVV kredit kartınızın arxasındakı son üç rəqəmdir.",
            installment_options: "Taksit Seçimləri",
            installment: 'Taksit Sayısı',
            complete_button: 'Davam Et',
            trying_to_pay: "Ödəniş davam edir",
            pay_is_ok: "Ödəniş Uğurlu",
            for_package: 'paketi üçün ödəniş edirsiniz.',
            choose: 'Seçin',
            one_shot: 'Tək Atış',
            card_informations: "Kart məlumatı",
            invoice_informations: "Ödəmə məlumatı",
            preliminary_information_form_title: "İlkin məlumat forması",
            errors: {
                card_number: "Siz kredit kartı nömrənizi natamam/yanlış daxil etmisiniz.\n",
                card_name: "Kartdakı ad sahəsi boş qala bilməz.\n",
                card_month: "Son istifadə tarixi üçün ay hissəsi boş qala bilməz.\n",
                card_year: "Son istifadə tarixi üçün il hissəsi boş qala bilməz.\n",
                card_cvv: "Siz CVV sahəsini natamam/səhv daxil etmisiniz.\n",
                card_bank: "Zəhmət olmasa bank seçin.\n",
                card_installment: "Zəhmət olmasa taksit sayını seçin.\n",

            }
        },
        successful:
        {
            title: "Ödənişiniz uğurla tamamlandı.",
            content: "Təhsil məsləhətçiniz kursunuzun təfərrüatlarını müəyyən etmək üçün ən qısa zamanda sizinlə əlaqə saxlayacaq.",
            postpone_content: "Siz davamiyyət hesabatı səhifəsində qalan əlavə haqqlarınıza baxa bilərsiniz.",
            button: "Tamam",
            term_error: "Eğitim döneminiz eğitim danışmanınız tarafından oluşturulacaktır."
        },
        failed:
        {
            title: "Ödənişiniz tamamlanmadı.",
            content: "Məlumatları yoxlayın və yenidən cəhd edin.",
            error_title: "Xəta təfərrüatları",
            bank_error: 'Aşağıda bankınızın ödəniş xətası ilə bağlı cavabı verilmişdir.',
            button: "Yenidən Cəhd Edin"
        },
        information: {
            title: "Aşağıdakı ekran vasitəsilə məlumatlarınızı daxil etməklə əməliyyatınıza davam edə bilərsiniz.",
            name: "Ad Soyad",
            email: "E-poçt",
            address: "Faktura Ünvanı",
            button: "Davam et",
            errors: {
                name: "Ad Soyad məlumatını daxil edin.\n",
                email: "E-poçt məlumatlarınızı daxil edin.\n",
                address: "Fakturalandırma üçün ünvan məlumatlarınızı daxil edin.\n"
            },
        },
        completed:
        {
            title: "Təbriklər...",
            content: "Məlumatınız uğurla yadda saxlanıldı.",
            button: "Tamam"
        },
        education_packages: {
            title: "Təhsil Proqramları",
            description: "Sizə ən uyğun olan təlim proqramını seçin. Nailiyyətlərinizi, proqramınızı və nümunə kitablarınızı nəzərdən keçirin.",
            total_lesson_count: "#count# dərs",
            program_achievements: '"#title#" Proqramın sonunda nə qazanacaqsınız?',
            word_count: "#count# Lüğət",
            hint_count: "#count# Cümlə Qəlibi",
            downloading: "İndiriliyor..."
        }
    },

    speaking_lesson:
    {
        title: 'Danışıq Dərsi',
        click_for_lesson_detail: "Qeyd təfərrüatları üçün klikləyin",
        letter_note_informations: {
            speaking_grammar: {
                one: "The speaker has a very basic understanding of grammar and struggles to form coherent sentences. Errors in grammar usage are frequent and may interfere with understanding.\nMany errors in grammar usage, including frequent errors in subject-verb agreement, verb tense, and word order. The number of errors could be in the range of 10-20 per minute of speech.",
                two_three: "The speaker has a limited but functional understanding of grammar and can communicate basic information with some errors. Grammar mistakes may still be frequent and noticeable.\nSome errors in grammar usage, including errors in basic structures such as singular and plural forms, articles, and simple verb tenses. The number of errors could be in the range of 5-10 per minute of speech.",
                four_five: "The speaker has a good understanding of grammar and can communicate effectively, though with occasional errors. The speaker can use complex sentence structures and express ideas with relative ease.\nOccasional errors in grammar usage, including errors in complex structures such as subordinate clauses and verb tense consistency. The number of errors could be in the range of 2-5 per minute of speech.",
                six_seven: "The speaker has a near-native command of grammar and can communicate complex ideas with little difficulty. Errors in grammar usage are rare and do not interfere with understanding.\nFew errors in grammar usage, with occasional mistakes in complex structures such as passive voice and reported speech. The number of errors could be in the range of 1-2 per minute of speech.",
                eight_nine: "The speaker has a native-like mastery of grammar and can use language flexibly and effectively for a range of purposes. The speaker can understand and use complex structures, idioms, and other nuanced language features.\nRare errors in grammar usage, with occasional mistakes in subtle nuances such as idiomatic expressions and sentence-level discourse markers. The number of errors could be less than 1 per minute of speech.",
                ten: "The speaker has a mastery of grammar that exceeds that of most native speakers. The speaker can use language creatively and with a high degree of precision, using a wide range of registers and styles as appropriate to the context.\nVirtually no errors in grammar usage, with occasional slips in pronunciation or vocabulary choice. The number of errors could be less than 1 per several minutes of speech."
            },
            speaking_vocabulary_usage: {
                one: "The speaker has a very basic vocabulary and struggles to express ideas with limited words. They may rely on frequent repetition of the same words or phrases.\nFrequent errors in word choice, pronunciation, and usage. The speaker may rely on a small set of words and struggle to convey even basic ideas. The number of errors could be in the range of 10-20 per minute of speech.",
                two_three: "The speaker has a functional vocabulary and can communicate basic information with some errors. However, their vocabulary may be limited to everyday words and phrases, and they may struggle with more complex or specialized terminology.\nSome errors in word choice, pronunciation, and usage. The speaker may struggle with more advanced vocabulary and rely on basic words and phrases. The number of errors could be in the range of 5-10 per minute of speech.",
                four_five: "The speaker has a good vocabulary and can communicate effectively in most situations. They can use a range of vocabulary to express ideas, but may still struggle with more advanced terminology.\nOccasional errors in word choice, pronunciation, and usage. The speaker can use a range of vocabulary to express ideas, but may still struggle with more advanced or specialized terminology. The number of errors could be in the range of 2-5 per minute of speech.",
                six_seven: "The speaker has a broad vocabulary and can use language flexibly and precisely to express complex ideas. They can use specialized terminology and idiomatic expressions appropriately in a range of contexts.\nFew errors in word choice, pronunciation, and usage. The speaker can use language flexibly and precisely to express complex ideas, and can understand and use specialized terminology appropriately. The number of errors could be in the range of 1-2 per minute of speech.",
                eight_nine: "The speaker has an extensive and nuanced vocabulary, and can use language creatively to achieve a range of communicative goals. They can understand and use complex vocabulary in a variety of registers and styles.\nRare errors in word choice, pronunciation, and usage. The speaker has an extensive vocabulary and can use language creatively and effectively, even in specialized or formal contexts. The number of errors could be less than 1 per minute of speech.",
                ten: "The speaker has a near-native command of vocabulary, using language with an exceptional range of precision and nuance. They can understand and use rare or archaic vocabulary, as well as complex idiomatic expressions, with ease.\nVirtually no errors in word choice, pronunciation, and usage. The speaker has an exceptional range of vocabulary and can use language with an exceptional degree of precision and nuance. The number of errors could be less than 1 per several minutes of speech."
            },
            speaking_pronunciation: {
                one: "The speaker is difficult to understand, with poor articulation and significant errors in pronunciation, stress, and intonation. They may struggle to produce even basic sounds and may not be able to be understood by native speakers.\nThe speaker may make numerous mistakes in pronunciation, such as mispronouncing many sounds, omitting or adding sounds, and using incorrect stress and intonation patterns.",
                two_three: "The speaker can produce some sounds and words correctly, but may still have difficulty with more complex sounds and pronunciations. Their intonation and stress may be off, making it difficult to understand them in certain contexts.\nThe speaker may still make some noticeable errors in pronunciation, such as mispronouncing some sounds or having trouble with more complex sounds, but can generally be understood by others.",
                four_five: "The speaker can produce most sounds and words accurately and has a good grasp of basic intonation and stress patterns. They may still make some errors, particularly with less common sounds or words, but can generally be understood by native speakers.\nThe speaker may make occasional errors in pronunciation, such as mispronouncing some less common words or having a slight accent, but can generally communicate fluently and accurately.",
                six_seven: "The speaker can produce nearly all sounds and words accurately and has a good command of intonation and stress patterns, making them sound more natural and fluent. They may still have some slight accent or occasional errors, but overall can be easily understood by native speakers.\nThe speaker may make few errors in pronunciation, and can generally produce all English sounds with accuracy and natural intonation and stress. They may still have a slight accent, but it does not interfere with their communication.",
                eight_nine: "The speaker can produce all sounds and words accurately, with near-native pronunciation and intonation. They are able to make fine distinctions in sound and can adapt their pronunciation to different dialects and contexts. They may still have a slight accent, but are generally indistinguishable from native speakers.\nThe speaker may make very few errors in pronunciation, and can produce all English sounds with near-native accuracy and natural intonation and stress. They may still have a slight accent, but it is difficult to detect.",
                ten: "The speaker has native-level pronunciation and intonation, with no discernible accent. They are able to produce subtle distinctions in sound and can adapt their pronunciation to any dialect or context. They may also be able to imitate different accents or dialects with ease.\nThe speaker is unlikely to make any noticeable errors in pronunciation, and can produce all English sounds with native-like accuracy and natural intonation and stress. They may be mistaken for a native speaker."
            },
            listening_comprehension: {
                one: "The listener has difficulty understanding even basic words and phrases in the language. They may need to rely on visual cues or context to understand the meaning of spoken language.\nThe listener may make numerous mistakes, such as not being able to identify basic vocabulary or verb forms, and having difficulty understanding even simple sentences or phrases.",
                two_three: "The listener can understand simple words and phrases, but may struggle with more complex sentences or idiomatic expressions. They may need to ask for repetition or clarification in order to understand the meaning of spoken language.\nThe listener may make some mistakes, particularly with more complex language or idiomatic expressions, but can generally understand the meaning of spoken language with repetition or clarification.",
                four_five: "The listener can understand most spoken language in familiar contexts, but may still struggle with more complex or abstract language, particularly when it is spoken quickly or with an unfamiliar accent.\nThey may also miss some details or nuances in the language. The listener may make occasional mistakes, such as missing some details or nuances in the language, particularly when it is spoken quickly or with an unfamiliar accent.",
                six_seven: "The listener can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents. They can also understand implied meanings and cultural references.\nThe listener may make few mistakes and can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents.",
                eight_nine: "The listener can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts. They can also understand nuances in tone, register, and intonation.\nThe listener may make very few mistakes, and can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts.",
                ten: "Exceptional proficiency: The listener can understand spoken language with complete accuracy and fluency, even in the most challenging contexts, such as fast-paced conversations between multiple speakers or with strong regional accents. They can also understand nuances in meaning and cultural references at a level that is equivalent to that of a native speaker.\nThe listener is unlikely to make any mistakes and can understand spoken language with complete accuracy and fluency, even in the most challenging contexts."
            },
            reading_vocabulary_awareness: {
                one: "The reader has a very limited vocabulary and struggles to understand even basic words in the language. They may need to rely heavily on a dictionary or translation tool to read simple texts.\nThe reader may make numerous mistakes, such as not recognizing basic words or being unable to infer the meaning of unfamiliar words from context.",
                two_three: "The reader has a basic vocabulary and can understand simple texts with familiar vocabulary, but may struggle with more complex or technical vocabulary.\nThe reader may make some mistakes, particularly with less familiar vocabulary, but can generally understand most of the words in simple texts.",
                four_five: "The reader has a moderate vocabulary and can understand most texts with familiar vocabulary, but may struggle with more advanced or specialized vocabulary.\nThe reader may make occasional mistakes, such as misunderstanding the meaning of more complex or technical vocabulary, but can generally understand most of the words in moderately difficult texts.",
                six_seven: "The reader has an extensive vocabulary and can understand most texts with a high degree of accuracy, including those with advanced or specialized vocabulary.\nThe reader may make few mistakes, and can generally understand most of the words in difficult or specialized texts, but may occasionally need to look up unfamiliar vocabulary.",
                eight_nine: "The reader has an exceptional vocabulary and can understand texts with a high degree of precision, even in very specialized fields or contexts.\nThe reader may make very few mistakes, and can understand most texts with a high degree of accuracy, including those with advanced or technical vocabulary.",
                ten: "The reader has a mastery of vocabulary in the language equivalent to that of a native speaker, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to rely on a dictionary or other tools.\nThe reader is unlikely to make any mistakes, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to look up unfamiliar vocabulary."
            },
            reading_pronunciation: {
                one: "The reader reads slowly and may struggle with basic comprehension, needing to sound out words and reread frequently. Their reading may be halting and choppy, with little or no attention to expression.\nThe reader may make numerous mistakes, such as mispronouncing many words, misreading or skipping words, and struggling to understand basic vocabulary and sentence structure.",
                two_three: "The reader reads with some fluency and is able to understand simple texts with basic vocabulary. Their reading may still be slow and require occasional assistance.\nThe reader may make some mistakes, such as mispronouncing some words, stumbling over more complex vocabulary, and needing some guidance to understand the text.",
                four_five: "The reader reads with moderate fluency and is able to understand moderately complex texts with some unfamiliar vocabulary. They may still need to reread some sections for better comprehension.\nThe reader may make occasional mistakes, such as mispronouncing some specialized vocabulary, missing some nuances or idioms, and occasionally needing to reread sections for better comprehension.",
                six_seven: "The reader reads with good fluency and is able to understand difficult texts with specialized vocabulary. They can read smoothly and efficiently with little difficulty.\nThe reader may make few mistakes, and can generally understand and pronounce most words with accuracy, but may occasionally struggle with highly specialized or technical vocabulary.",
                eight_nine: "The reader reads with exceptional fluency and can easily understand even the most difficult texts with advanced vocabulary. They can read with excellent speed and accuracy and comprehend at a high level.\nThe reader may make very few mistakes, and can accurately and fluently read and understand texts with advanced or specialized vocabulary, without needing to look up unfamiliar words.",
                ten: "The reader reads with near-native fluency and can read and comprehend texts as well as or better than native speakers. They can easily read a wide range of texts with advanced vocabulary and high complexity.\nThe reader is unlikely to make any mistakes, and can accurately and fluently read and understand highly nuanced and idiomatic language with ease. They may also be able to infer meaning from context with a high degree of accuracy."
            }
        },
        speaking_lesson_user: {
            practice_with_ai: 'Danışmaqda İnkişaf (AI)',
            lesson_of_the_day: 'Günün dərsi',
            quiz_test: 'Quiz & Test',
            daily_sentence: 'Günün Cümlə Qəlibi',
            participation_report: 'Dərsdə İştirak',
            progress_report: "Tərəqqi İzləmə",
            term_schedule: 'Dərs Cədvəli',
            education_packages: 'Təhsil Proqramları',
            today: 'Bu gün',
            it_is_not_specified: 'Qeyd edilməmişdir',
            audio_note: "Səs yazılarınız 5 gündən sonra sistemdən silinəcəkdir."
        },
        speaking_lesson_non_user: {
            hello: 'Salam',
            hello_title: 'Azərbaycanın ən effektiv ingilis dili öyrənmə sisteminə xoş gəldiniz.',
            sample_lesson: 'Konuşarak Öğrendə Nümunə Dərs',
            view_packages: 'Təlim Paketlərinə Baxın',
            plan_course_title: 'Şəxsi Təhsil Məsləhətçinizlə kurs cədvəlinizi planlaşdırın',
            plan_course: 'Dərsimi Planlaşdırın',
            modal_title: 'Məlumat Mesajı',
            modal_message: 'Təhsil məsləhətçiniz sistemdə qeydiyyatdan keçmiş telefon nömrəniz vasitəsilə sizinlə ən qısa müddətdə əlaqə saxlaya biləcək. O vaxta qədər Təlimatçılarımızı nəzərdən keçirə bilərsiniz',
            okay_button: 'Təlimatçıları Nəzərdən Keçirin',
        },
        daily_sentence: {
            sample_sentences: 'Nümunə Cümlələr',
            start_quiz: 'Testi Başlat',
            message: 'Kitabınız üçün günün cümlə qəlibini və testini aşağıda görə bilərsiniz.',
            book_hint_not_found: 'Günün kitabı üçün heç bir cümlə nümunəsi və ya test tapılmadı.',
            friday_error_message: 'Günün kitabı sərbəst danışıq kitabı olduğu üçün bu gün üçün cümlə nümunələri və testləri yoxdur.',
            postponed: {
                content_1: "Bu günə olan danışıq dərsinizi təxirə saldınız.",
                content_2: "Cümlə nümunəsini və testi görmək üçün təqvimdən başqa bir tarix seçə bilərsiniz.",
            },
            frozen: {
                content_1: "Salam, dərslərini dondurmusunuz.",
                content_2: "Dərsləriniz yenidən başlamazdan əvvəl göndəriləcək məlumat e-poçtlarına əməl etməklə müəlliminizi və vaxtınızı asanlıqla yeniləyə bilərsiniz.",
            },
            holiday: {
                content_1: "Salam, bu gün tətildir və danışıq dərsiniz yoxdur.",
                content_2: "Cümlə nümunəsini və testi görmək üçün təqvimdən başqa bir tarix seçə bilərsiniz.",
            },
            inactive: {
                content_1: "Paketiniz aktiv olmadığı üçün bu gün dərsləriniz yoxdur. Təqvimdə əvvəlki kurslarınıza keçərək köhnə kurslarınız haqqında məlumat görə bilərsiniz.",
                content_2: "İnkişafınızı qaldığınız yerdən davam etdirmək üçün <strong class=\"text-primary\">Təlim Paketlərimizi</strong> nəzərdən keçirə bilərsiniz.",
            },
        },
        daily_lesson: {
            choose_date: 'Tarixi seçin',
            daily_lesson_title: 'Günün Danışıq Dərsi',
            daily_lesson_title_attended: 'Dərs Qiymətləndirməsi',
            daily_lesson_information: 'Salam, bugünkü danışıq dərsiniz haqqında məlumatlarla aşağıda tanış ola bilərsiniz.',
            view_missed_lesson_book: 'Buraxılmış dərs kitabına baxın',
            your_teacher: 'Müəlliminiz ',
            lesson_time: 'Ders saatiniz',
            lesson_content: 'Dərs məzmununa aşağıdakı bölmələr vasitəsilə daxil ola bilərsiniz.',
            today_lesson_book: 'Günün Dərs Kitabı',
            book: 'Kitab',
            presentation: 'Təqdimat',
            practice: 'Pratik',
            lesson_video: 'Ders Videosu',
            book_voiceover: 'Kitap Seslendirme',
            words: 'Kelimeler',
            lesson_evoluation: 'Ders Değerlendirmesi',
            your_voice_record: 'Derse Ait Ses Kaydınız',
            teacher_comment: 'Dərs haqqında Müəlliminizin şərhləri',
            teacher_suggestions: 'Müəlliminizin dərsə aid məsləhətləri',
            mispronunciation: 'Səhv Tələffüz Edilənlər / Səhv Qurulan Cümlələr',
            correct_pronunciation: 'Doğru Tələffüz Edilənlər / Doğru Qurulan Cümlələr',
            speaking_grammar_usage: 'Qrammatikası İstifadəsi',
            speaking_vocabulary_usage: 'Lüğət İstifadəsi',
            speaking_pronunciation: 'Tələffüz',
            listening_comprehension: 'Dinləyib-anlama',
            reading_vocabulary_awareness: 'Oxuma Lüğət Maarifləndirmə',
            reading_pronunciation: 'Oxuma Tələffüzü',
            lesson_information: "Dərs məlumatı",
            lesson_study: "Dərs sonrası çalışmalar",
            lesson_audios: "Səs yazıları",
            correct_and_speak: "Düzəlt və Danış (AI)",
            lesson_materials: "Dərs materialları",
            teacher_ranking_and_evaluation: "Müəllimin Balları və Rəyləri",
            lesson_ranking: "Dərs Balı",
            teacher_comments: "Müəllimin şərhləri",
            teacher_recommendations: "Müəllimin Məsləhətləri",
            sentence_bank: {
                title: "Cümlə Bankı",
                all_sentences: "Bütün Cümlələr",
                added_list: "Siyahıya əlavə edildi",
                removed_list: "Siyahıdan silindi",
                load_more: "Daha çox yüklə",
                sentence_learning_list: "Cümlə Öyrənmə Siyahısı"
            }
        },
        no_answer: {
            comment_title: "Kurs haqqında müəlliminizin şərhləri",
            comment_content: "Bu gün dərsinizdə iştirak etmədiniz.",
            call_log_title: "Kursun zəng saatları",
            missed_call_content: "#date# tarixdə buraxdığın dərs, #new_date# haqqında #time# vaxtında #teacher# ilə baş tutacaq.",
            missed_call_warning_one: "Əvəz dərsinizin nəticəsi gəldikdən sonra günün dərsliyini yenidən yoxlayın.",
            missed_call_warning_two: "Günün dərs nəticəsi gəldikdən sonra əvəz dərs kitabını yenidən yoxlayın."
        },
        components: {
            holiday: {
                content_1: "Salam, danışıq dərsiniz yoxdur.",
                content_2: "Növbəti danışıq dərsinizə hazırlaşmaq üçün tarixi seçərək davam edə bilərsiniz.",
            },
            postponed: {
                content_1: "Bu günə olan danışıq dərsinizi təxirə saldınız.",
                content_2: "Növbəti danışıq dərsinizə hazırlaşmaq üçün tarixi seçərək davam edə bilərsiniz.",
            },
            frozen: {
                content_1: "Salam, dərslərini dondurmusunuz.",
                content_2: "Dərsləriniz 24.03.2022 tarixində yenidən başlayacaq. Bu tarixdən əvvəl göndəriləcək bildiriş e-poçtlarını qəbul etməklə Müəlliminizi və dərs saatınızı asanlıqla yeniləyə bilərsiniz.",
            },
            inactive: {
                content_1: "Paketiniz aktiv olmadığı üçün bu gün dərsiniz yoxdur.",
                content_2: "Təqvimdə əvvəlki dərslərinizə keçərək əvvəlki dərsləriniz haqqında məlumat görə bilərsiniz.",
                content_3: "İnkişafınızı qaldığınız yerdən davam etdirmək üçün <strong class=\"text-primary\">Təlim Paketlərimizi</strong> nəzərdən keçirə bilərsiniz.",
            },
            study_subjects: {
                title: "Tədris Mövzuları",
                tab_title_1: "Dərs Sualları",
                tab_title_2: "İfadələr",
            },
            audio: {
                book_voiceover_title: "Kitab səsləndirmə",
                lesson_records_title: "Derse Ait Ses Kayıtları",
                lesson_records: "Derse Ait Ses Kayıtları",
            },
            video: {
                title: "Dərs Videosu",
            },
            words: {
                title: "Sözlər",
            },
            reference_bar: {
                description: "Yaxınlarınızı Dəvət Edin, Birlikdə Öyrənin və Mükafatlar Qazanın!",
                button_text: "Dəvət Edin"
            }
        },
        progress_report: {
            attendance_rate: "Dərsdə iştirak dərəcəsi",
            progress_chart: "İnkişaf qrafiki",
            you_are_a_candidate: 'Hələ aktiv paketiniz olmadığı üçün irəliləyiş hesabatınız göstərilə bilməz.',
            same_level_title: "Sizinlə eyni səviyyədə başlayanların 12 ayda çatdığı səviyyə (*)",
            same_level_content: "(*) Həftədə 5 gün dərs alan tələbələrimizin dərslərin 90% və daha çoxunda iştirak etdikdə əldə etdiyi orta səviyyə verilmişdir."
        },
        attendance_report: {
            you_are_a_candidate: 'Hələ aktiv paketiniz olmadığı üçün davamiyyət hesabatınız göstərilə bilməz.',
            not_use: 'Tarix tapılmadı.',
            date_content: ' tarixli dərs',
            report_1: {
                title: 'Davamiyyət hesabatı',
                begin_date: 'Başlama tarixi : ',
                total_lesson_count: 'Dərslərin ümumi sayı : ',
                attended_lesson_count: 'İştirak edilən dərslərin sayı  : ',
                missed_lesson_count: 'Buraxılmış Dərslərin Sayı : ',
                remaining_lesson_count: 'Qalan Dərslərin Sayı : ',
                postpone_right_count: 'Qalan "Dərs təxirə salma" hüququ : ',
                missed_call_right_count: 'Qalan "Dərsimi Buraxdım" hüququ : ',
                change_teacher_time_right_count: 'Qalan "Təlimatçı/Saat Dəyişikliyi" hüququ :',
                freeze_right_count: 'Qalan "Kursun Dondurulması" hüququ : ',
            },
            report_2: {
                title: 'İştirak etmədiyiniz dərslər',
            },
            report_3: {
                title: 'Təxirə salınan dərslər',
            },
            report_4: {
                title: 'Dərsimi buraxdım istifadə olunan dərslər',
            },
        },
        holidays: {
            information: 'Aşağıdakı günlərdə yalnız canlı danışıq dərsləri keçirilməyəcək. Proqramlara, kurs materiallarına və testlərə 24/7 girişiniz var.',
            title: 'Tətil Günləri'
        },
        quiz: {
            title: "Bütün Testlər",
            description: "Aşağıda paketiniz üçün olan testləri görüb həll edə bilərsiniz.",
            answered_quiz: "Əvvəllər cavablandırılmış testlər <span class=\"material-symbols-outlined text-primary\">check</span> işarəsi ilə göstərilir.",
            view_book: "Kitaba Baxın",
            show_report: "Nəticəyə Baxın",
            start: "Başla",
            remaining_time: "Qalan vaxt",
            control: "Yoxla",
            continue: "Davam et",
            quiz_completed: "Testi Bitirdiniz",
            report_description: "Bu test haqqında ətraflı məlumatı aşağıda tapa bilərsiniz.",
            solve_again: "Testi yenidən başladın",
            start_time: "Başlanğıc",
            end_time: "Bitiş",
            total_question: "Ümumi sual",
            right_answer: "Düzgün cavab",
            wrong_answer: "Səhv cavab",
            empty_answer: "Boş buraxılan",
            timer_end_description: "Test müddətiniz başa çatıb. Test nəticəsini görmək üçün aşağıdakı düyməni basın.",
            quit_modal_text: "Sistemdən çıxsanız, həll etdiyiniz test yarımçıq qalacaq. Çıxmaq istədiyinizə əminsiniz?",
            quit: "Çıxış",
            want_continue: "Davam etmək istəyirəm",
            leave_empty: "Boş burax",
            last_lesson: "Son dərs",
            show_result: "Nəticəyə Baxın",
            return_video: "Dərs Videosuna Qayıt",
            no_book_found: "Seçdiyiniz paketə aid kitabların testi tapılmadı.",
            not_active_student: "Aktiv paketiniz olmadığı üçün Quiz & Test bölməsinə girişiniz yoxdur."
        },
        education_packages: {
            title: "Proqram məzmunu",
            start_lessons: 'Dərslərə başlayın',
            units: "Vahidlər",
            icons: {
                awsome: "Mükəmməl",
                knowledgeable: "Bilikli",
                completed: "Dərs Tamamlandı",
                preparation: "İlkin hazırlıq",
                not_started: "Başlanmayıb",
                quiz: "Quiz",
                awsome_description: "(AI ile çalışıldı)",
                knowledgeable_description: "(Quiz həll edildi)",
                preparation_description: "(Kitab nəzərdən keçirildi)"
            }
        },
        speaking_lab: {
            title: "Danışıq Labı",
            description: "Danışıq Labı ilə ingiliscə danışıq bacarıqlarınızı inkişaf etdirin! Tələffüz, cümlə tamamlama və quraşdırma məşğələləri sizi gözləyir. Elə indi başlayaq!",
            description_content: "Danışıq Labı ilə ingiliscə danışıq bacarıqlarınızı inkişaf etdirin! Tələffüz, cümlə tamamlama və quraşdırma məşğələləri sizi gözləyir.",
            go_lab: "Laboratoriyaya Get",
            pronounce_sentence_title: "Aşağıdakı cümləni tələffüz edin",
            fill_in_the_blank_title: "Aşağıdakı cümlədə, boşluğa uyğun gələcək kəliməni tələffüz edərək əlavə edin",
            create_sentence_title: "Aşağıdaki sözleri düzgün sırada seçərək, dinlədiyiniz cümləni quraşdırın",
            create_sentence_correct: "Təbriklər! Cümləni düzgün quraşdırdınız. Quraşdırdığınız cümləni tələffüz edin.",
            create_sentence_wrong: "Cümləni xətalı şəkildə quraşdırdınız. Xahiş edirik təkrar cəhd edin.",
            drag_drop_wrong_message: "Cümləni xətalı quraşdırdınız.",
            drag_drop_correct_message: "Cümləni uğurla quraşdırdınız.",
            sentence_hint: "İpucu",
            wrong_answer: "Xətalı Cavab",
            correct_answer: "Düzgün cavab",
            try_again_button: "Təkrar Cəhd Edin",
            voice_again: "Təkrar Səsləndir",
            results_loading: "Nəticəniz Yüklənir...",
            your_voice: "Siz",
            sample_voice: "Nümunə",
            first_card_counter_text: "#second# saniyə içində başlayır...",
            second_card_counter_text: "#second# saniyədir davam edir..",
            pronunce_error_text: "Tələffüzünüz analiz edilərkən bir xəta baş verdi.",
            motivation_text: "Təbriklər! #count# məşğələnidə tamamladınız!",
            exercise_completed_text: "Təbriklər! Bu məşğələni uğurla tamamladınız.",
            start_again_modal_text: "Bu məşğələ üçün əvvəlcədən qalan irəliləyişiniz mövcuddur.\n Qaldığınız yerdən davam etmək istəyirmisinizmi?",
            start_again: "Başdan Başlamaq",
            exit_exercise_question_text: "Meşğələdən çıxmaq istəyirsinizmi?",
            skip_this_question: "Bu Sualı Keçin",
            are_you_there: "Ordasınız? 👋🏻 15 saniyəyə qədər audio yazmaq olar."
        },
        conversational_ai: {
            description: 'İzah',
            goal: 'Hədəf',
            also_try: 'Bundan əlavə',
            click_for_speak: 'Danışmaq üçün klikləyin',
            press_the_speak_button: 'Danış düyməsini klikləyin',
            try_saying: 'SÖYLƏMƏYƏ CƏHD ET:',
            history: "Söhbət Tarixçəsi",
            conversation_text: "Transkript",
            conversation_text_description: "Səhvlərinizi görmək üçün transkriptinizi nəzərdən keçirə bilərsiniz.",
            beginning_of_story: "Hekayənin başlanğıcı",
            question_title: "Sual #content#",
            section_title: "Bölüm #content#",
            explain_to_me: 'Mənə izah et',
            continue_or_end_modal: {
                continue_text: "Qaldığınız yerdən davam etmək istəyirsiniz?",
                end_text: "Söhbəti bitirmək istədiyinizə əminsiniz?",
                end: "Sonlandır",
                continue_chat: 'Davam et'
            },
            correct_and_speak: {
                ai_corrections: "AI bölməsində səhvlər üçün bu başlığı seçin.",
                lesson_corrections: "Dərsdə səhvlər üçün bu başlığı seçin."
            }
        }
    },

    teachers:
    {
        title: 'Müəllimlər',
        teacher_details: {
            birth_place: "Doğum Yeri",
            age: "Yaş",
            about: "Haqqında",
            education: "Təhsil",
            interest_fields: "Maraq sahələri",
            buy_lesson_from_teacher: "Təlimçidən Dərs Al",
            not_student_content: "Təlimatçıdan dərs ala bilmək üçün aktiv paketiniz olmalıdır. Paketləri Təhsil Paketləri səhifəsinə daxil olaraq əldə edə bilərsiniz.",
            not_student_buy_button: "Təlim Paketlərinə Baxın",
            not_student_cancel_button: "İmtina Etmək",
            teacher_audio: "Müəllimin Səs Yazısı",
        },
        teachers_list: {
            page_title: 'Ümumilikdə #currentCount#/#total# təlimçi siyahıya alınıb.',
            current_teacher_text: 'Ümumi #currentCount#/#total# müəllim',
            detail_information: "Ətraflı məlumat",
            age: " yaş"
        },
        rating: {
            rate: "Qiymətləndir",
            rate_your_teacher: 'Müəlliminizi qiymətləndirin',
            rate_your_teacher_female: 'Müəllimənizi qiymətləndirin',
            rate_description: 'Aşağıdakı düyməyə klikləməklə, #teacherName# haqqında fikirlərinizi bizimlə bölüşə bilərsiniz.',
            your_comment: 'Şərhiniz',
            your_comment_error_message: 'Şərh sahəsi 300 simvoldan çox ola bilməz.',
            rating_success_message: 'Müəllim qiymətləndirməniz uğurla alındı. Günün dərsi ekranına istiqamətləndirilirsiniz...'
        },
        filter: {
            title: "Müəllimləri Siyahıla",
            start_time: "Başlanğıc Saatı",
            end_time: "Bitiş Saatı",
            lesson_time: 'Dərs Saatı',
            and: 'ilə',
            my_packages: "Paketlər",
            filter_teachers: 'Müəllimləri Filtrlə',
            teacher_feature: 'Müəllim Xüsusiyyəti',
            choose_teacher_feature: 'Müəllim Xüsusiyyətini Seçin',
            choose_teacher_feature_number: 'Seçilmiş Müəllim Xüsusiyyəti (#count#)',
            avaliable_time_not_found: "Təlimatçı üçün uyğun dərs saatları tapılmadı.",
            teacher_features_list: {
                beginner_friendly: "Başlanğıc səviyyəsinə uyğun",
                child_friendly: "Uşaqlar üçün uyğun",
                grammar_focused: "Qrammatikaya diqqət edən"
            }
        }
    },

    support:
    {
        title: 'Dəstək Mərkəzi',
        teacher_time_change_title: "Təlimçi/Vaxt Dəyişikliyi",
        buy: "Al",

        main_page: {
            send_request: "Sorğu Göndər",
            ticket_history: "Əvvəlki Sorğular",
            postpone_lesson: "Dərs Təxirə Salma",
            teacher_lesson_time_change: "Təlimçi/Vaxt Dəyişikliyi",
            missed_call: "Dərsimi Buraxdım",
            frozen_lesson: "Dərs Dondurma",
            change_password: "Şifrə Dəyişdirmə",
            delete_account: "Hesabımı Sil",
            holidays: 'Tətil günləri',
            not_student_content: "Bu funksiyalardan istifadə etmək üçün aktiv paketiniz olmalıdır. Paketləri Təhsil Paketləri səhifəsinə daxil olaraq əldə edə bilərsiniz.",
            not_student_buy_button: "Təlim Paketlərinə Baxın",
            not_student_cancel_button: "İmtina Etmək",
            passive_term_message: "Bu funksiya seçdiyiniz paket üçün mövcud deyil. Aktiv paketiniz var. Bu funksiyadan istifadə etmək üçün seçdiyiniz paketi dəyişdirin.",
            future_term_message: "Seçdiyiniz paket gələcək paketdir. Seçdiyiniz əməliyyat gələcək paketlərdə həyata keçirilə bilməz. Aktiv paketi seçmək üçün Seçilmiş Paketi Dəyişdir düyməsini klikləyin.",
            pending_term_message: "Seçdiyiniz paket hələ təsdiqlənməyib. Paketiniz təsdiqləndikdən sonra seçdiyiniz əməliyyatı həyata keçirə bilərsiniz.",
            choose_active_term: "Seçilmiş Paketi Dəyişin"
        },
        send_request: {
            title: "Buradan suallar, problemlər və bütün təklifləriniz bizimlə paylaşa bilərsiniz.",
            placeholder: "Sorğunuzu buraya yazın",
            send: "Gönder",
            modal_content: "Müraciətiniz uğurla müvafiq şöbələrə göndərildi.",
            modal_content_error: "Göndəriləcək sorğu 10 simvoldan az ola bilməz. Zəhmət olmasa bir daha cəhd edin.",
            request_limit_reached: "Gündəlik sorğu göndərmə limitiniz keçdiyi üçün sorğunuz göndərilə bilməz."
        },
        previous_request: {
            //1
            title: "Daha əvvəl göndərdiyiniz sorğu və cavabları aşağıda tapa bilərsiniz.",
            not_yet_answered: 'Hələ cavab verilməyib.',
        },
        postpone_lesson: {
            postponed_lesson_title: "Əvvəl təxirə salınmış dərslər",
            postponed_lesson_content: "#date# tarixində istifadə olunub.",
            postpone_right: "<b>#counter#</b> dərsi təxirə salmaq hüququnuz var.",
            postpone_information: "Kursun təxirə salınması prosesinin uğurlu olması üçün proses kursdan ən az 2 saat əvvəl baş verməlidir.",
            choose_postpone_date: "Təxirə salmaq istədiyiniz (iştirak edə bilməyəcəyiniz) tarixi seçin",
            second_modal_message: "Dərsin təxirə salınması <b>dönməz</b> prosesdir. Təxirə salmaq istədiyinizə əminsiniz?",
            third_modal_message: "Dərsiniz uğurla təxirə salınmışdır.",
            postpone: "Təxirə Salın",
            you_have_frozen_your_courses: "#date1# Siz dərslərinizi son tarixə qədər dondurmusunuz. #date2# tarixindən etibarən yenidən emal edə bilərsiniz",
            modal_1_content: "#program# Proqramınızın #date# ilə dərsi təxirə salmaq istədiyinizə əminsiniz?",
            buy_postpone_lesson: "Təxirə Salınması Hüququ Satın Alın",
            postpone_lesson_count_description: "Ardıcıl #count# dərsi təxirə salsanız, sizə ən uyğun proqramı yaratmaq üçün sistem tərəfindən müəllim və vaxt dəyişiklikləri ediləcəkdir."
        },
        postpone_lesson_information: {
            information_title: "Dərs Təxirə Salma bölməsindən istifadə etməzdən əvvəl yadda saxlamalı olduğunuz şeylər",
            information_one: "Əgər birdən çox proqramınız varsa, yuxarıdakı bölmədən əməliyyat etmək istədiyiniz proqramı seçməlisiniz.",
            information_two: "Dərsi təxirə salmaq daimi əməliyyatdır və geri qaytarıla bilməz.",
            information_three: "Aşağıdakı təqvimdən iştirak edə bilməyəcəyiniz dərsin tarixini seçib təxirə salma düyməsini sıxmalısınız.",
            information_four: "Düyməni basdıqdan sonra sizdən iki dəfə təsdiq tələb olunacaq. Bu 2 təsdiqi verdikdən sonra dərsin təxirə salınması baş verəcək.",
            information_five: "Təxirə salınmış dərslər kurs cədvəlinizin sonuna əlavə edilir.",
            information_six: "Bunu dərsdən ən azı 2 saat əvvəl etməlisiniz.",
        },
        postpone_lesson_no_right: {
            no_postpone_right: "Seçdiyiniz proqram üzrə dərsləri təxirə salmaq hüququnuz yoxdur.",
            no_postpone_right_information: "Fərqli proqramınız varsa, yuxarıda həmin proqramı seçə və ya dərsi təxirə salmaq hüququnu ala bilərsiniz."
        },
        teacher_time_change: {
            previously_changed_courses: 'Əvvəllər Dəyişdirilmiş Kurslar',
            you_changed_your_lesson: '#teacher# / #time# dərsini dəyişdin.',
            teacher_time_change_question: "Dəyişmək istədiyiniz müəllimi və zaman aralığını seçin.",
            list_hours: "Saatleri Listele",
            modal_content: "Göstərdiyiniz vaxt intervalı üçün uyğun müəllimlər tapılmadı. \Fərqli vaxt intervalında yenidən cəhd edin.",
            teacher_change_information: "Göstərdiyiniz vaxt intervalında uyğun məşqçi tapılmadı.\Lütfən, fərqli vaxt intervalında yenidən cəhd edin.",
            process: "Sənin hərəkətin  ",
            process_continue: "  proqramı yeniləyəcək.",
            lesson_time_change_dialog: "Təlimatçı saatının dəyişdirilməsi prosesi ilə dərsinizi öyrədə bilərsiniz #lesson# güncəlləmək istədiyinizə əminsiniz?",
            teacher_change_success: "Təlimatçınız/saat dəyişikliyi uğurla tamamlandı.",
            with: "ilə",
            esd_two_hour_error: "Bu gün üçün aktiv dərsiniz var. Dərs saatınızdan 2 saat sonra vaxtı dəyişə bilərsiniz.",
            esd_time_frame_error: "Dərsin başlamasına 2 saatdan az vaxt qaldığından heç bir əməliyyat edilə bilməz."
        },
        teacher_time_change_information: {
            information_title: "Təlimatçı/saat dəyişdirməzdən əvvəl yadda saxlamalı olduğunuz şeylər",
            information_one: "Əgər birdən çox proqramınız varsa, yuxarıdakı bölmədən dəyişiklik olunacaq proqramı seçməlisiniz",
            information_two: "Müəllim/saat dəyişikliyi daimi dəyişiklikdir.",
            information_three: "Dəyişiklikdən sonra bütün dərsləriniz yeni müəllim ilə və yeni vaxtınızda keçiriləcəkdir.",
            information_four: "Çünki müəlliminiz və saatınız bloklanmadığı üçün başqa tələbə tərəfindən istifadə edilə bilər.",
            information_five: "Bu dəyişikliyi dərsdən ən azı 2 saat əvvəl etməlisiniz.",
            select_teacher: "Müəllim Seçin"
        },
        teacher_time_change_frozen: {
            frozen: "Bu proqram üçün dərslərinizi dondurmusunuz",
        },
        teacher_time_change_not_now: {
            not_now: "Təlimatçının vaxt dəyişikliyi hazırda həyata keçirilə bilməz",
            not_now_information: "Siz ya dərsinizi bu günə təxirə sala, ya da dərs statusunuz təlimatçı vaxtının dəyişməsi üçün yekunlaşdıqda bu prosesi yenidən sınaya bilərsiniz."
        },
        teacher_time_change_no_right: {
            no_change_right: "Seçdiyiniz proqram üçün təlimatçı/saat dəyişdirmək hüququnuz yoxdur.",
            no_change_right_information: "Fərqli proqramınız varsa, yuxarıda həmin proqramı seçə və ya dərsi təxirə salmaq hüququnu ala bilərsiniz."
        },
        teacher_list: {
            teacher_name: "Təlimatçı Adı",
            lesson_date: "Tarix",
            lesson_time: "Dərs Saatları",
            choose: "SEÇİN",
            date: "Tarix"
        },
        missed_call: {
            buy_make_up_lesson: "Makiyaj dərsi keçin",
            previously_missedcall_courses: 'Düzgün istifadə etdiyim Dərs Kurslarını Buraxdım',
            missedcall_lesson_content: " siz tarixli dərsinizdə istifadə etdiniz.",
            not_used: 'Siz indiyə qədər heç vaxt "Dərsimi Buraxdım"dan istifadə etməmisiniz.',
            no_suitable_instructor: 'Makiyaj dərsi üçün uyğun təlimatçı tapılmadı.\n\nLütfən, sonra yenidən cəhd edin.',
            lesson_time_not_suitable_error_message: "Seçdiyiniz əvəz dərsinin vaxtı digər dərsinizlə eyni vaxt intervalındadır.",
            missed_lesson_conflicted: "Seçilmiş buraxılmış dərs vaxtı digər aktiv dərslərinizlə ziddiyyət təşkil edir!"
        },
        missed_call_used: {
            used_call: "Dərsimi buraxdım, əvvəl haqqınızdan istifadə etdiniz.",
            used_content: "#date1# tarixdə buraxdığın dərs, #date2# haqqında #time# vaxtında #teacher# ilə baş tutacaq."
        },
        missed_call_no_right: {
            no_right: "Seçdiyiniz proqram üçün Mənim Sinfimi buraxmaq hüququnuz yoxdur.",
            no_right_information: "Fərqli proqramınız varsa, yuxarıda həmin proqramı seçə və ya Miss My Class hüququ əldə edə bilərsiniz."
        },
        missed_call_out_of_time: {
            out_of_time: "Dərsimi qaçırdım bölməsindən istifadə etmək üçün aktiv saatların xaricindəsiniz.",
            out_of_time_information: "Əgər dərs zamanı onlayn ola bilmirsinizsə və ya texniki probleminiz varsa, dərsimi qaçırdım səhifəsindən əvəz dərsi ala bilərsiniz."
        },
        missed_call_information: {
            information_title: "İstifadə etməzdən əvvəl xatırlamalı olduğum şeylər dərsimi buraxdım",
            information_one: "Əgər birdən çox proqramınız varsa, yuxarıdakı bölmədən lazımi proqramı seçməlisiniz.",
            information_two: "Dərsimi buraxdım bölməsi dərsiniz bitdikdən 20 dəqiqə sonra aktivləşir.",
            information_three: "Buraxılmış dərsim aktivləşdirildikdən sonra 40 dəqiqə ərzində istifadə edilməlidir.",
            information_four: "Make-Up Lesson düyməsinə basdıqdan sonra qeyd olunan saatlar sistemdə ən uyğun saatlardır.",
            information_five: '"Dərsi buraxdım" bölməsindən götürülmüş makiyaj dərsi gündəlik prosesdir və təlimatçı/saat dəyişikliyini əvəz etmir.'
        },
        missed_call_set_lesson: {
            title: "Əvəz dərsləri müəllim siyahısı",
            information: "Aşağıda qeyd olunan müəllimlərdən seçim edərək əvəz dərsi ala bilərsiniz.",
            set_lesson_success: "Əlavə dərsi seçiminiz uğurla tamamlandı.",
            modal_transaction: "Əvəz dərsinizi #lesson# olaraq təyin etmək istədiyinizə əminsiniz?",
            process_done: 'Əvəz dərsi seçiminiz uğurla tamamlandı.',
            already_used: 'Siz bu dərs üçün əvvəllər "Dərsimi Buraxılmışam" hüququndan istifadə etmisiniz.',
        },
        missed_call_no_lesson_result: {
            title: "Dərs nəticəsi hələ yüklənməyib.",
            information: "Dərs nəticəsi yükləndikdə, bu ekranda görünəcək. Bir neçə dəqiqədən sonra yenidən cəhd edin."
        },
        freeze_lesson: {
            freeze_lesson: "Dərsləri Dondurun",
            modal_content: `Kursun dondurulması bir dəfə istifadə edilə bilər. Edəcəyiniz hərəkət kurslarınızı <b>2 ay</b> müddətinə donduracaq.\n #lesson# proqramınızın dərslərini dondurmaq istəyirsiniz?`,
            second_modal_content: "Kursun dondurulması <b>bir dəfə</b> istifadə edilə bilər. Edəcəyiniz hərəkət kurslarınızı <b>2 ay</b> müddətinə donduracaq.\n Bu əməliyyat geri qaytarıla bilməz.<b>Dərs dondurmaq</b> stədiyinizə əminsiniz?",
            modal_success_message: "Dərsin dondurulması uğurla tamamlandı."
        },
        freeze_lesson_success: {
            success_message: "Dərsləriniz #date# tarixinə qədər dondurulur."
        },
        freeze_lesson_information: {
            information_title: "Dərsin Dondurulması bölməsindən istifadə etməzdən əvvəl yadda saxlamalı olanlar",
            information_one: "Əgər birdən çox proqramınız varsa, yuxarıdakı bölmədən lazımi proqramı seçməlisiniz.",
            information_two: "Dərsi Dondurmaq əməliyyatı hissə-hissə deyil, 2 aylıq blok şəklində istifadə olunur.",
            information_three: "Dərsi Dondurmaq əməliyyatı edildikdən sonra ləğv edilə bilməz.",
            information_four: "2 ayın sonunda sistem tərəfindən müvafiq olaraq müəllim təyinatı həyata keçirilir. Bu səbəbdən eyni müəllim ilə davam edə bilməyəcəksiniz.",
            information_five: "Yeni müəlliminizi Müəllim/Saat Dəyişikliyi bölməsindən asanlıqla dəyişə bilərsiniz.",
            information_six: "Dərsin dondurulması əməliyyatı aparıldığı gündən qüvvədə olacaq və gələcəkdə heç bir dondurma həyata keçirilə bilməz.",
            information_seven: "Dərsin dondurulması prosesini yerinə yetirərkən sizdən 2 dəfə təsdiqləməniz istəniləcək."
        },
        freeze_lesson_no_right: {
            no_freeze_right: "Seçdiyiniz proqram üçün Dərsi Dondurmaq hüququnuz yoxdur.",
            no_freeze_right_information: "Fərqli proqramınız varsa, yuxarıda həmin proqramı seçə və ya dərsi təxirə salmaq hüququnu satın ala bilərsiniz."
        },
        change_password: {
            title: "Aşağıdakı məlumatları dolduraraq şifrənizi dəyişə bilərsiniz.",
            modal_change_password_success: "<b>Şifrə dəyişikliyiniz</b> uğurla tamamlandı.",
            modal_current_password_error: "Siz cari şifrənizi səhv daxil etmisiniz. Daxil etdiyiniz məlumatları yoxlayın və yenidən cəhd edin.",
            modal_current_password_same_error: "Yeni şifrəniz cari şifrənizlə eyni ola bilməz. Daxil etdiyiniz məlumatları yoxlayın və yenidən cəhd edin."
        },
        delete_account: {
            modal_delete_account_first_content: "Hesabınızı silmək istədiyinizə əminsiniz?",
            modal_delete_account_second_content: "Hesabınız silindikdə keçmiş və gələcək kurs məlumatlarınız daxil olmaqla hesabınıza aid bütün məlumatlar silinəcəkdir.\nHesabın silinməsi <b>geri dönülməz</b> bir əməliyyatdır.",
            modal_delete_account_third_content: "Hesabın silinməsi üçün sorğunuz qəbul edildi. Hesabınızın silinməsi mümkün olan ən qısa zamanda həyata keçiriləcək və sizə məlumat veriləcəkdir.",
            title: "Sizi İtirmək Kədərlidir...",
            description: "Bizimlə bölüşmək istədiyiniz hər hansı rəyiniz varsa, biz dinləməyə və təcrübənizi yaxşılaşdırmaq üçün əlimizdən gələni etməyə hazırıq.",
            write_message_button: "Mesaj Yaz"
        },
        error_list: {
            term_not_found: 'Term tapılmadı',
            lesson_date_must_be_equal_or_greater_than_today: 'Kursun tarixi bu günə bərabər və ya daha çox olmalıdır',
            lesson_time_is_invalid: 'Dərs vaxtı etibarsızdır',
            active_or_postponed_lesson_not_found_with_specified_date: 'Göstərilən tarixdə aktiv və ya təxirə salınmış kurs tapılmadı!',
            postpone_right_is_already_used_for_this_date: 'Təxirə salma hüququ artıq bu tarix üçün istifadə olunur!',
            you_are_not_in_the_postponement_time_range: 'Dərs vaxtınıza 2 saatdan az vaxt qaldığından dərsinizi təxirə sala bilmərik. Kursun təxirə salınmasının uğurla həyata keçirilməsi üçün kursdan ən azı 2 saat əvvəl edilməlidir. Anlayışınız üçün təşəkkür edirik.',
            you_are_not_in_the_freeze_time_range: 'Siz dondurucu vaxt intervalında deyilsiniz!',
            you_do_not_have_enough_postpone_right: 'Kifayət qədər süründürməçiliyiniz yoxdur!',
            freeze_right_already_used_by_specified_date: 'Siz artıq dərsinizi müəyyən edilmiş tarixə qədər dondurmusunuz!',
            you_do_not_have_any_freeze_right: 'Sizin dərsi dondurmaq hüququnuz yoxdur!',
            the_lesson_time_of_term_invalid: 'Dövr dərs vaxtı etibarsızdır!',
        },
        assign_term_teacher: {
            message_single: "Davammiyyətinizlə əlaqədar dərs saatınız #teacherInformation# olaraq yeniləndi.",
            message_multiple: "Davammiyyətinizlə əlaqədar aşağıdakı paketlərinizin dərs saatları yeniləndi."
        }
    },

    popup: {
        nps: {
            enter_your_comment: "Şərhinizi bura daxil edin",
            success_message: "Cavabınız alındı. Təşəkkür edirik...",
            comment_error_message: 'Şərh sahəsi 250 simvoldan çox ola bilməz.',
        },
        download_app: {
            content: "Dərsdən əvvəl bildirişləri almaq və daha yaxşı dərs təcrübəsi əldə etmək üçün mobil tətbiqimizi yükləyin.",
            button: "Yüklə",
        }
    },

    error404: {
        title: "Səhv",
    },

    ai_chatbot: {
        back: "Geri",
        start: "Danışmağa başlayın",
        stop: "Danışığı dayandırın",
        write_here: "Bura yazın",
        level_test_ai: "Süni İntellektlə Səviyyənizi Müəyyən Edin",
        ai_volume_up: "AI Səsini Aç",
        ai_volume_down: "AI Səsini Bağla",
        switch_write_mode: "Yazı Rejimine Keçid",
        switch_speak_mode: "Danışıq Rejimine Keçid",
        note: "Yox",
        speak_end: "Danışığınızı yekunlaşdırmaq üçün 'End' ifadəsindən istifadə etməyiniz kifayətdir",
        write_end: "Danışığınızı yekunlaşdırmaq üçün 'End' yazmağınız kifayətdir",
        give_microphone_permission_text: "Bu funksiyadan istifadə etmək üçün mikrofona girişə icazə verməlisiniz. Mikrofona girişə icazə vermək üçün videomuzdan kömək ala bilərsiniz. Süni intellektdən istifadə etməyə davam etmək üçün mətn rejiminə keçid düyməsini sıxa bilərsiniz.",
        determine_your_level: "AI ilə Səviyyənizi Müəyyən edin",
        errors: {
            correction_not_found_error: "Bu Dövr üçün Düzəlişlər Tapılmadı",
            microphone_not_allowed_error: "Bu səhifədən istifadə etmək üçün mikrofon icazəsi verməlisiniz.",
            selected_term_not_found_error: "Seçilmiş semestrdə dərsiniz yoxdur. Günün Dərsi səhifəsindən semestr seçə bilərsiniz."
        }
    },

    permissions: {
        title: "İcazələr",
        description: "Siz bu sahədən icazələrinizi idarə edə bilərsiniz.",
        whatsapp: "WhatsApp",
        lesson_record_title: "Dərsinizə aid səs yazmasını dinləyə bilmək üçün aşağıdakı icazələri verməlisiniz.",
        approve_lesson_record: "Səs qeydinin alınmasına icazə verirəm"
    },

    more: {
        title: "Daha Çox",
    },

    learn_together: {
        learn_together: "Birlikdə Öyrənin",
        family_package: "Ailə Paketi",
        invite_friends: "Yaxınlarınızı Dəvət Edin",
        family_list: "Ailə Siyahısı",
        members: "Üzvlər",
        edit: "Düzəliş et",
        manager: "İdarəçi",
        member: "Üzv",

        attend_family_request: "Təsdiq gözlənilir",
        accepted_attend_family_request_success_message: "Siz ailə paketiniz üçün <b>#NAME#</b> adlı istifadəçini uğurla qəbul etdiniz.",
        rejected_attend_family_request_success_message: "Ailə paketiniz üçün <b>#NAME#</b> adlı istifadəçini qəbul etmədiniz.",
        accept_attend_family_request_approve_message: "<b>#NAME#</b> adlı istifadəçini təsdiqləmək istədiyinizə əminsiniz?",
        reject_attend_family_request_approve_message: "<b>#NAME#</b> adlı istifadəçinin sorğusundan imtina etmək istədiyinizə əminsiniz?",
        decide_attend_family_request_manager_not_found: "Ailəyə qoşulmaq sorğusunu təsdiqləmək və ya imtina etmək üçün tələb olunan idarəçi məlumatı tapılmadı.",
        decide_attend_family_request_not_found: "İstifadəçinin ailənizə qoşulma sorğusu tapılmadı.",
        decide_attend_family_request_quota_full: "Ailənizin kvotası artıq dolu olduğu üçün tranzaksiya tamamlanmadı.",
        member_is_already_included_another_family_error: "Siz artıq başqa ailənin üzvü olduğunuz üçün tranzaksiya icra oluna bilmədi.",
        member_is_already_included_another_family_manager_error: "Bu istifadəçi artıq başqa ailənin üzvü olduğu üçün əməliyyat həyata keçirilə bilmədi.",
        decide_attend_family_request_already_decided: "Siz artıq istifadəçinin ailənizə qoşulmaq istəyinə cavab vermisiniz.",
        decide_attend_family_title: "<b>#NAME#</b> Azər İngiliscə Ailə Paketinizə qoşulmaq istəyir!",
        decide_attend_family_content: "Ailəyə daxil etmək istəyirsiniz?",
        decide_attend_family_approve: "Təsdiqlə",
        decide_attend_family_reject: "İmtina Etmək",
        decide_attend_family_already_accepted: "Ailənizə qoşulmaq üçün <b>#NAME#</b> adlı istifadəçinin sorğusunu artıq təsdiq etmisiniz.",
        decide_attend_family_already_rejected: "Siz artıq <b>#NAME#</b> adlı istifadəçinin ailənizə qoşulmaq xahişindən imtina etmisiniz.",
        family_package_description: "Sevdiklərinizlə birlikdə öyrənin",
        family_member_message: "Dəvət etdiyiniz hər bir şəxsə 10% endirim",
        family_manager_message: "Paket alan hər bir şəxs üçün sizə 10% endirim",
        family_attend_family_request_message: "Fərqli ailəyə qoşulmaq istəyirsinizsə, ailə idarəçisinə qoşulma sorğusu göndərə bilərsiniz.",
        family_learn_together: "Birlikdə öyrənmə təcrübəsi",
        send_attend_family_request: "Ailəyə Qoşulma Sorğusu göndərin",
        send_attend_family_request_manager_not_found_error: "Bu telefon nömrəsi ilə heç bir ailə idarəçisi tapılmadı",
        send_attend_family_request_already_invited_error: "Bu idarəçi artıq sizi ailəyə dəvət edib",
        send_attend_family_request_already_sent_error: "Siz daha öncə bu ailəyə qoşulmaq üçün sorğu göndərmisiniz.",
        send_attend_family_request_success_message: "Təbrik edirik! Ailəyə qoşulmaq üçün sorğunuz uğurla təqdim edildi.\nAilə idarəçisi sorğunuzu təsdiqlədikdə, siz ailəyə üzv kimi qoşula biləcəksiniz.",
        send_attend_family_request_quota_full_error: "Qoşulmaq istədiyiniz ailənin kvotası dolduğu üçün əməliyyatınız tamamlana bilmədi.",
        
        
        add_new_member: "Yeni Üzv əlavə edin",
        remove_approve_message: "<b>#NAME#</b> adlı üzvü silmək istədiyinizə əminsiniz?",
        remove_success_message: "<b>#NAME#</b> adlı üzvü ailə paketindən uğurla çıxardınız.",
        add_member_title: "Ailə üçün İngilis dili: Birlikdə Öyrənməyin Sevinci Ailə Paketində !",
        invite_message: "<b>#NAME#</b> sizi ailə paketinə dəvət edir. Azeringilisce-də ailə hesabına qoşulun. Reklamsız video məşqlərdən, süni intellekt alətlərindən və yüzlərlə məzmundan yararlanın.",
        invited_title: "<b>#NAME#</b> sizi Azeringilisce Ailə Paketinə dəvət etdi!",
        invited_content: "İngilis dilini öyrənməyə başlayaq.",
        join_family: "Ailəyə Qoşulun",
        join_success_message: "<b>#NAME#</b> adlı üzvün ailə paketinə əlavə olundunuz.",
        already_registered_family: "Siz artıq bir ailə ilə qeydiyyatdasınız!",
        already_manager: "Siz artıq ailə idarəçisiniz!",
        family_quota_full: "Qoşulmaq istədiyiniz ailənin kvotası dolmuşdur.",
        remaining_count: "#COUNT# üzv qalıb",
        add_member_content: "Ailə üzvləriniz və ya dostlarınızla birlikdə ingilis dilini öyrənməyə başlayın.",
        add_member_content_title_2: "İngilis dilini ailə paketi ilə öyrənmək çox daha rahatdır.",
        add_member_content_2: "Bu paket sizə ingilis dilini öyrənməyin ən rahat yolunu təklif edir. Bir və ya bir neçə nəfəri dəvət etməklə ingilis dilini endirimli qiymətlərlə öyrənməyə başlaya bilərsiniz.",
        add_member_content_title_3: "Ailə Paketi Planı",
        add_member_content_3: "Hər ailə üzvünə 10% endirim tətbiq olunur.",
        share_text: "Dostlarını və ya ailə üzvlərinizi dəvət etmək üçün aşağıdakı seçimlərdən istifadə edə bilərsiniz.",
        add_member_with_phone: "Telefonla üzv əlavə edin",
        add_member_info: "Əlavə ediləcək şəxsin məlumatını daxil edin.",
        add_member: "Əlavə et",
        add_member_success_message: "Ailəyə dəvət mesajı uğurla göndərildi.",
        add_member_error_message: "Ailəyə dəvət mesajı göndərilərkən xəta baş verdi.",
        moneybox_transfer: {
            title: "Qazanılmış Endirimlərinizi Transfer Edin",
            info: {
                title: "Qazanılmış Endirimlər nədir və nə üçün istifadə olunur?",
                description: "Qazanılmış Endirimlər, birini dəvət etdiyinizdə və ya ailənizdən biri paket satın aldıqda hesabınıza avtomatik olaraq təyin edilən xüsusi bir endirim növüdür.",
                description_2: "Dəvət etdiyiniz şəxs paket satın aldıqda, hər alış-veriş edən şəxs üçün <span class='font-bold'>10% endirim qazanırsınız.</span>",
                description_3: "İdarəçisi olduğunuz ailənin üzvlərindən hər hansı biri paket satın aldıqda, hər alış üçün <span class='font-bold'>10% endirim qazanırsınız.</span>",
                description_4: "Başqa bir istifadəçinin sizə endirimini transfer etməsi ilə də bu endirimi əldə edə bilərsiniz.",
            },
            discount_transfer: {
                title: "Transfer Edin",
                transfer_discount_rate: "Köçürüləcək Endirim Faizi",
                transfer_discount_rate_description: "Bu əməliyyatı etdiyinizdə, endirim hüquqlarınızı yazdığınız telefon nömrəsinin hesabına köçürəcəyik.",
                transfer_discount_rate_title: "Endirim Haqqlarınızı Transfer Edin",
                transfer_confirm_description: "Təsdiqlədiyiniz anda endirim haqqlarınız köçürüləcəkdir.",
                transfer_success_message: "Qazanılmış endirimlərin köçürməsi uğurla tamamlandı.",
                transfer_same_person_error_message: "Köçürən və köçürüləcək şəxs məlumatları eyni ola bilməz!",
                transfer_not_found_error_message: "Köçürmək istədiyiniz şəxs sistemdə qeydiyyatda deyil. Zəhmət olmasa, telefon nömrəsini yoxlayın."
            },
            moneybox_discount_follow: {
                title: "Qazanılmış Endirimlərə Nəzarət",
                total_discount_rate: "Ümumi Qazanılan Endirim",
                used_discount: "İstifadə Edilən Endirim",
                remaining_discount: "Qalan Endirim",
            },
            transfer_history: {
                title: "Qazanılmış Endirimləri Köçürmə Tarixi",
                all: "Hamısı",
                received: "Gələn",
                sent: "Göndərilən",
                sender: "Göndərən",
                receiver: "Alan",
                description: "Bu sahədə qazanılmış endirimlərin köçürmə tarixi görüntülənəcəkdir."
            },
            friend_list: {
                title: "Tanış Siyahısı",
                not_found: "Dəvət edilən şəxs tapılmadı.",
                search: "Axtar...",
                reference: "Referans",
                table: {
                    name: "Ad",
                    status: "Durum",
                    invite_type: "Dəvət Növü",
                    invite_date: "Dəvət Tarixi",
                    student: "Tələbə",
                    candidate: "Tələbə Namizədi",
                    inactive: "Keçmiş Tələbə"
                }
            }
        }
    },


    reference: {
        give_reference_title: "Yaxınlarınıza İngilis Dili Hədiyyəsi, Sizə Keşbek",
        give_reference_description: "Yaxınlarınızı Azeringilisce-yə dəvət edin, yaxınlarınız #referenceDiscountRate#% endirim qazansın və siz də #price# AZN geri alın.* Son iştirak tarixi: #lastDate#",
        give_reference_description_2: "*Yaxınlarınız təlim paketini #lastDate# vaxtından əvvəl almalıdırlar.",
        invite_friend_title: "Yaxınlarınızı Azeringilisce-yə Dəvət Edin",
        invite_friend_description: "Dəvət et, qazandır! 5 yaxınınız 1 həftə ərzində ödənişsiz Azeringilisce təhsili almaq imtiyazına sahib olacaq.*",
        invite_friend_information: "* Hədiyyə dərslər 3 günlükdür. <br/> * Yaxınlarınız %#referenceDiscountRate#, siz isə paket alan hər bir yaxınınız üçün %#moneyboxDiscountRate# endirim əldə edəcəksiniz.",
        copy_link_and_share: "Linki kopyalayıb paylaşa bilərsiniz.",
        invite_activated: "#activeReferenceStudentCount#/#targetReferenceStudentCount# Dəvət Aktivləşdirildi",
        copy: "Kopyalayın",
        copied: "Kopyalandı",
        whatsapp_x_message_text: "İngilis dilini öyrənmək üçün əla platforma kəşf etdim, adı Azeringilisce! Mən çox faydalandım, sizin də marağınızı çəkəcəyini düşünürəm. Budur linki:",
        mail_message_text: "Dostlar, sizə əla xəbərim var! Azeringilisce proqramı ilə ingilis dilini öyrənməyə başladım və bu çox maraqlıdır. Həm ana dili ingilis dili olan müəllimlərlə praktika edirəm,, həm də əylənirəm. Siz də sınamaq istərdinizmi? Referal kodumdan istifadə etsəniz, #referenceDiscountRate#% endirim əldə edə bilərsiniz. Gəlin, birlikdə ingiliscə danışaq! 😊 #Azeringilisce #İngilisDiliniÖyrənin",
        reference_message: "<b>#NAME#</b> sizi endirimli üstünlüklərlə Azər İngiliscə-yə dəvət edir. Azər İngiliscə-yə indi qoşulun. Reklamsız video məşqlərdən, süni intellektdən və yüzlərlə ödənişsiz məzmundan yararlanın.",
        invite_reference_with_phone: "Telefonla dəvət edin",
        invite_reference_add_info: "Dəvət edəcəyiniz şəxsin məlumatını daxil edin.",
        invite_reference_success_message: "Referans mesajı uğurla göndərildi.",
        invite_reference_error_message: "Referans mesajı göndərilərkən xəta baş verdi."
    },

    video_practice: {
        title: "Videoçarxlarla Öyrən",
        complete: "Tamamla",
        home: {
            video_duration: "Müddət",
            current_video_count: 'Ümumi #currentCount#/#total# video',
        },
        video: {
            video_speed: "Oynatma sürəti",
            turkish_subtitle: "Subtitrlər - Azərbaycan dilində"
        },
        detail_search: {
            title: "Videoları Kateqoriyalamaq",
            list_videos: "Videoları Listələmək",
            selected_data_title: "Seçilmiş #selectedTagTitle# (#count#)"
        },
        word_card: {
            synonyms: "Eyni mənalılar",
            phonetics: "Fonetika",
            english_description: "İngiliscə Açıqlaması",
            sample_sentence: "Nümunə Cümlə",
            sample_sentence_translation: "Nümunə Cümlə Azərbaycancası",
            add_member_word_store_success_message: "<span class='font-bold'>#word#</span> sözü, söz öyrənmə listinizə uğurla əlavə edildi.",
            add_member_word_store_error_message: "<span class='font-bold'>#word#</span> sözü, onsuzda sizin söz öyrənmə listinizdə vardır.",
            add_learn_word_list: "Siyahıma əlavə et",
            share: "Paylaş",
            word_pronunciation: "Tələffüz Et",
            word_origin: "Kəlimənin Kökü",
            word_origin_translation: "Kəlimənin Kökü Azərbaycancası",
            writing_exercise: "Yazma məşğələsi",
            today_english_word: "Günün İngiliscə Sözü",
            reset: "Sıfırlayın",
            use_keyboard: "Aşağıdakı boş yerləri doldurmaq üçün klaviaturanızdan istifadə edə bilərsiniz.",
            share_text: "Paylaşmaq üçün aşağıdaki seçimlərdən istifadə edə bilərsiniz.",
            give_microphone_permission: "Bu funksiyadan istifadə etmək üçün mikrofona icazə verməlisiniz. Mikrofona icazə vermək üçün videomuzdan kömək ala bilərsiniz."
        },
        pronunciation: {
            score_breakdown: "Xallar Yekunu",
            pronunciation_score: "Tələffüz Xalı",
            accuracy_score: "Dəqiqlik Xalı",
            completeness_score: "Tamamlama Xalı",
            fluency_score: "Axıcılıq Xalı",
            prosody_score: "Prosody Xalı",
            pronunciation_description: "Göstərilən nitqin tələffüz keyfiyyətini göstərən ümumi xal. Bu xal Dəqiqlik xalı, Axıcılıq xalı, Tamlıq xalı (əgər varsa) və Prosody xalının (əgər varsa) cəmindən əldə edilir.",
            accuracy_description: "Nitqin tələffüz dəqiqliyi. Dəqiqlik fonemlərin ana dilində danışanın tələffüzünə nə qədər uyğun olduğunu göstərir. Söz və tam mətn dəqiqliyi xalları fonem səviyyəsindəki dəqiqlik xalından toplanır.",
            completeness_description: "Tələffüz olunan sözlərin daxil edilmiş istinad mətninə nisbəti ilə hesablanan nitqin tamlığı.",
            fluency_description: "Verilən nitqin səlisliyi. Axıcılıq nitqin ana dlində danışanın sözlər arasında səssiz fasilələrdən istifadə etməsi ilə nə dərəcədə uyğunlaşdığını göstərir.",
            prosody_description: "Nitqin ölçüsü. Ölçü vurğu, intonasiya, danışma sürəti və ritm kimi nitqin xarakterini göstərir.",
            click_microphone_for_try_again: "Təkrar cəhd etmək üçün mikrofon işarəsinə yenidən klikləyə bilərsiniz.",
            omission: "Tələffüz edilmir",
            mispronunciation: "Səhv Tələffüz",
            none: "Səhv yoxdur"
        },
        word: {
            title: "Söz Öyrən",
            description: "Seçdiyimiz #count# vacib ingiliscə sözü kəşf edə bilərsiniz."
        },
        dialog: {
            title: "Dialoq Oxu",
            description: "Seçdiyimiz #count# mühim ingiliscə sözə aid dialoqları kəşf edə bilərsiniz."
        },
        grammar: {
            title: "Qrammatika Öyrən",
        }
    },

    social_button: {
        whatsapp: "Whatsapp Vasitəsilə Paylaşın",
        mail: "E-poçt Vasitəsilə Paylaşın",
        copy_link: "Linki Kopyalayın",
        download: "Yüklə"
    },

    corporate_customer: {
        title: "Korporativ Müştəri",
        button_text: "Korporativ Müştəri ol",
        hero: {
            title: "Şirkətinizin İngilis Dili Baryerini Birlikdə Aşaq!",
            description: "Ana dili ingilis dili olan müəllimlər ilə təkbətək canlı dərslər, fərdiləşdirilmiş kurikulum və ölçülə bilən nəticələr"
        },
        advantages: {
            title: "Azer İngilisce Korporativ ilə Potensialınızı Kəşf Edin",
            live_classes_title: "Fərdi Canlı Dərslər",
            live_classes_description: "Fərdiləşdirilmiş, effektiv öyrənmə",
            native_teacher_title: "Ana Dili İngilis Dili Olan Müəllimlər",
            native_teacher_description: "Düzgün tələffüz, səlis danışıq",
            flexible_schedule_title: "Çevik Proqram",
            flexible_schedule_description: "İşçilərinizin vaxtına uyğundur",
            measurable_results_title: "Ölçülə Bilən Nəticələr",
            measurable_results_description: "Hesabatlarla tərəqqi izləyin",
            mentor_support_title: "24/7 Mentor Dəstəyi",
            mentor_support_description: "Hər zaman yanınızdayıq"
        },
        form: {
            title: "Şirkətinizə Məxsus Ödənişsiz Təklif Alın",
            description: "Ehtiyacınıza uyğun həllər üçün korporativ təlim məsləhətçimiz dərhal sizinlə əlaqə saxlasın.",
            phone_number: "Telefon Nömrəsi",
            phone_number_error: "Zəhmət olmasa etibarlı telefon nömrəsi daxil edin",
            email: "Korporativ E-poçt",
            email_error: "Zəhmət olmasa etibarlı korporativ e-poçt ünvanı daxil edin",
            email_placeholder: "example@company.com",
            full_name_error: "Zəhmət olmasa ad və soyad daxil edin",
            corporate_name: "Şirkət Adı",
            corporate_name_error: "Zəhmət olmasa şirkət adını daxil edin",
            corporate_name_placeholder: "Şirkət adını daxil edin",
            number_of_employees: "İşçilərin Sayı",
            submit: "Ödənişsiz Məlumat Alın",
            success_message: "Sorğu uğurla göndərildi. Biz ən qısa zamanda sizinlə əlaqə saxlayacağıq."
        },
        references: {
            title: "Referanslarımız",
        }
    }
}