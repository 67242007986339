import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ChatBubble, LessonMaterial } from "../../../assets/svg";
import { Loading } from "../../../components";
import { AIKeys } from "../../../components/Constants";
import useAiRouting from "../../../hooks/useAiRouting";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import { url } from "../../../routes/utility";
import { GetSessionAndLessonCorrections } from "../../../services/AIUseCaseHistory/AIUseCaseHistoryService";

const CorrectAndSpeak = () => {
    const { bookUnitId, aiUseCaseId } = useParams();

    const strings = useLocalization();
    const navigate = useNavigate();
    const { member } = useAuth();

    const selectedTermId = useSelector(state => state.termStore.termId);
    const selectedDate = localStorage.getItem('selectedDate') || '';
    const useCaseId = useAiRouting(AIKeys.CORRECTION);

    const [selectedType, setSelectedType] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        sessionCorrections: [],
        lessonCorrections: []
    });
    
    const handleNavigateAiChatbot = () => {
        const selectedCorrections = selectedType === 'session' 
            ? data.sessionCorrections 
            : data.lessonCorrections;

        navigate(url("speakinglesson.chatbot", 
            { useCaseId: useCaseId }),
            { state: { corrections: selectedCorrections }}
        );
    }

    useEffect(() => {
        if(selectedTermId) {
            GetSessionAndLessonCorrections({
                aiUseCaseId: +aiUseCaseId,
                bookUnitId: +bookUnitId,
                lessonDate: selectedDate,
                termId: selectedTermId,
                memberId: member?.MemberId
            })
                .then(result => {
                    if(result.status === 200) {
                        const responseData = JSON.parse(result.content);
                        setData({
                            sessionCorrections: responseData.sessionCorrections || [],
                            lessonCorrections: responseData.lessonCorrections || []
                        });
                    }
                })
                .catch(error => {})
                .finally(() => setLoading(false));
        } else {
            navigate(-1);
        }
    }, []);

    // İlk yüklemede session corrections varsa onu, yoksa lesson corrections'ı seç
    useEffect(() => {
        if (data.sessionCorrections?.length > 0) {
            setSelectedType('session');
        } else if (data.lessonCorrections?.length > 0) {
            setSelectedType('lesson');
        }
    }, [data]);

    return (
        <div className="main-content">
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col h-[calc(100vh-220px)]">
                    <div className="flex-shrink-0">
                        <div className="w-full flex items-center ml-2 justify-between mt-5">
                            <p className="text-secondary font-bold">{strings.speaking_lesson.daily_lesson.correct_and_speak}</p>
                            <button
                                className="ml-auto mr-2 border border-onboarding-border hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:text-base gap-2 h-8 max-w-max px-3 flex flex-row items-center justify-center rounded-[10px]"
                                onClick={() => navigate(-1)}
                            >
                                <span className="material-symbols-outlined">keyboard_return</span>
                                {strings.ai_chatbot.back}
                            </button>
                        </div>
                        <div className="flex flex-col bg-white mt-2">
                            <div className="flex flex-col h-full">
                                <div className="flex flex-col py-4 space-y-8">
                                    {data.sessionCorrections?.length > 0 && (
                                        <button
                                            onClick={() => setSelectedType('session')}
                                            className={`w-full rounded-lg border-2 transition-all duration-200 overflow-hidden ${
                                                selectedType === 'session' 
                                                    ? 'border-primary bg-white' 
                                                    : 'border-gray-200 bg-gray-50'
                                            }`}
                                        >
                                            <div className="flex items-center p-3 bg-box border-b border-gray-200">
                                                <img src={ChatBubble} alt="Chat Bubble" className="w-6 h-6 mr-2" />
                                                <h3 className="font-bold">{strings.speaking_lesson.conversational_ai.correct_and_speak.ai_corrections}</h3>
                                            </div>
                                            <div className="p-4 space-y-2">
                                                {data.sessionCorrections.map((item, index) => (
                                                    <div key={index} className="flex items-center">
                                                        <div className="w-2.5 h-2.5 rounded-full bg-green flex-shrink-0"></div>
                                                        <p className="text-[14px] md:text-base ml-3">{item}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </button>
                                    )}

                                    {data.lessonCorrections?.length > 0 && (
                                        <button
                                            onClick={() => setSelectedType('lesson')}
                                            className={`w-full rounded-lg border-2 transition-all duration-200 overflow-hidden ${
                                                selectedType === 'lesson' 
                                                    ? 'border-primary bg-white' 
                                                    : 'border-gray-200 bg-gray-50'
                                            }`}
                                        >
                                            <div className="flex items-center p-3 bg-box border-b border-gray-200">
                                                <img src={LessonMaterial} alt="Lesson Material" className="w-6 h-6 mr-2" />
                                                <h3 className="font-bold">{strings.speaking_lesson.conversational_ai.correct_and_speak.lesson_corrections}</h3>
                                            </div>
                                            <div className="p-4 space-y-2">
                                                {data.lessonCorrections.map((item, index) => (
                                                    <div key={index} className="flex items-center">
                                                        <div className="w-2.5 h-2.5 rounded-full bg-green flex-shrink-0"></div>
                                                        <p className="text-[14px] md:text-base ml-3">{item}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {selectedType && (
                        <div className="flex-shrink-0">
                            <div className="btn-outer-div">
                                <button 
                                    onClick={handleNavigateAiChatbot}
                                    className="button button-mt primary-button"
                                >
                                    {strings.speaking_lesson.daily_lesson.correct_and_speak}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CorrectAndSpeak;