import { useNavigate } from "react-router-dom";
import { url } from "../../../../../../routes/utility";
import { Accordion } from "../../../../../../components";
import { useSelector } from "react-redux";
import useTermAccordionActivity from "../../../../../../hooks/useTermAccordionActivity";

const ConversationalAIContent = ({ data, selectedDate }) => {

    const navigate = useNavigate();

    const selectedTermId = useSelector(state => state.termStore.termId) || false;
    const { activities, addTermAccordionActivity } = useTermAccordionActivity(data?.termLessonAccordionActivities);

    const handleNavigate = (bookUnitAiUseCaseId, aiUseCaseId) => {
        navigate(url("speakinglesson.conversationalai.chat", { bookUnitAiUseCaseId, aiUseCaseId }));
        localStorage.setItem("selectedDate", selectedDate);
    }

    // Accordion'un açık olup olmadığını kontrol eden yardımcı fonksiyon
    const getIsAccordionOpened = (termLessonAccordionType) => {
        return activities?.find(l => termLessonAccordionType === l.termLessonAccordionType)?.isOpened || false;
    }

    // Accordion açıldığında çağrılan fonksiyon
    const handleAccordionOpen = (termLessonAccordionType) => {
        addTermAccordionActivity(termLessonAccordionType, selectedTermId, selectedDate );
    };

    return (
        <>
            {data?.book?.bookUnitAiUseCases?.map((item, index) => (
                <Accordion
                    key={index}
                    title={item.aiUseCaseTitle}
                    onClick={() => handleNavigate(item.bookUnitAiUseCaseId, item.aiUseCaseId)}
                    disableOutsideClick={true}
                    isScrollable={false}
                    isDownArrowIcon={false}
                    icon={item.aiUseCaseIconUrl}
                    openCloseCheckboxStatus={getIsAccordionOpened(item.termLessonAccordionType)}
                    action={() => handleAccordionOpen(item.termLessonAccordionType)}
                />
            ))}
        </>
    )
}

export default ConversationalAIContent;