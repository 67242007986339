import { getBrowserName, getOSId } from '../../utils';
import { fetchApi } from '../index';

export const AddMemberFamily = async (model) => {

    const url = '/_services/member/v1/member_family/add_member_family/';

    const payload = {
        sourceType: 1, //web
        deviceName: getBrowserName(),
        deviceOs: getOSId(),
        ...model
    };

    return await fetchApi(url, payload);
};

export const RemoveMemberFamily = async (model) => {

    const url = '/_services/member/v1/member_family/remove_member_family/';

    const payload = {
        ...model,
    };

    return await fetchApi(url, payload);
};

export const SendAttendFamilyRequest = async (model) => {

    const url = '/_services/member/v1/member_family/send_attend_member_family_request/';

    const payload = {
        ...model,
        sourceType: 1, //web
        deviceOs: getOSId(),
        deviceName: getBrowserName()
    };

    return await fetchApi(url, payload);
};

export const DecideAttendFamilyRequest = async (model) => {

    const url = '/_services/member/v1/member_family/decide_attend_member_family_request/';

    const payload = {
        ...model,
        sourceType: 1, //web
        deviceOs: getOSId(),
        deviceName: getBrowserName()
    };

    return await fetchApi(url, payload);
};

export const GetFamilyMemberStatus = async (memberId, managerFamilyCode) => {

    const url = '/_services/member/v1/member_family/get_member_family_status/';

    const payload = {
        memberId: memberId,
        managerFamilyCode: managerFamilyCode
    };

    return await fetchApi(url, payload);
};

export const GetFamilyMember = async (memberId) => {

    const url = '/_services/member/v2/member_family/get_family_members/';

    const payload = {
        memberId: memberId,
    };

    return await fetchApi(url, payload);
};

export const GetFamilyManagerNameByFamilyCode = async (familyCode) => {

    const url = '/_services/member/v1/member_family_code/get_family_manager_name_by_family_code/';

    const payload = {
        familyCode: familyCode,
    };

    return await fetchApi(url, payload);
};

export const AddMemberFamilyWithInvite = async (model) => {

    const url = '/_services/member/v1/member_family/add_member_family_with_invite/';

    const payload = {
        brandId: +process.env.REACT_APP_BRAND_ID,   
        sourceType: 1, //web
        deviceName: getBrowserName(),
        deviceOs: getOSId(),
        ...model,
    };

    return await fetchApi(url, payload);
};

export const GetMoneyboxRateDetails = async (memberId) => {

    const url = '/_services/member/v1/member/get_moneybox_rate_details/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
};

export const TransferMoneybox = async (model) => {

    const url = '/_services/member/v1/member/transfer_moneybox/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
};

export const GetTransferMoneyboxActivities = async (memberId) => {

    const url = '/_services/member/v1/member/get_transfer_moneybox_activities/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
};

export const GetMoneyboxIntivedMembers = async (model) => {

    const url = '/_services/member/v1/member/get_moneybox_invited_members/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
};