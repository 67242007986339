import {
  Chevron_Right,
  Home,
  Home_Active,
  Logo,
  Market,
  Market_Active,
  Microphone,
  Microphone_Active,
  Settings,
  Support,
  Support_Active,
  Teachers,
  Teachers_Active,
  MenuFamily,
} from "../assets/svg";
import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useLocalization} from "../hooks/useLocalization";
import {url} from "../routes/utility";
import {Collapse, CollapseOverlay} from "../components";
import {openCollapse} from "../utils";
import useAuth from "../hooks/useAuth";
import classNames from "classnames";
import { useSelector } from "react-redux";

const Menu = ({ title }) => {

  const strings = useLocalization();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = useLocation();
  const { member } = useAuth();
  const topHeightVisibility
      = useSelector(state => state.signalrHubStore.callBarVisibility.isTopHeightVisible);

  const callBarVisibility
      = useSelector(state => state.signalrHubStore.callBarVisibility.isCallBarVisible);

  const collapseRef = useRef();
  const collapseOverlayRef = useRef();

  //Aktif dönemi olan tüm kullanıcılar için Join Your Lesson butonu yukarıda görüntülenecek
  const activeTerm = typeof (member.Terms) !== "undefined" && JSON.parse(member.Terms).find(term => term.Status === 1);
  const isMobile = location.pathname.includes("/mobile_market/");
  const isOnboarding = location.pathname.includes("/onboarding/") || location.pathname === '/success';

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('');

  const unreadMessageCount = useSelector(state => state.messageStore.unreadCount);

  const getActiveTab = (pathname) => {
    if (pathname.includes("dashboard")) {
      setActiveTab("dashboard");
    } else if (pathname.includes("speakinglesson")) {
      setActiveTab("speakinglesson");
    } else if (pathname.includes("teachers")) {
      setActiveTab("teachers");
    } else if (pathname.includes("market")) {
      setActiveTab("market");
    } else if (pathname.includes("support")) {
      setActiveTab("support");
    } else {
      setActiveTab("dashboard");
    }
  }

  useEffect(() => {
    const controller = new AbortController();

    getActiveTab(pathname);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {/* <!-- top navbar --> */}
      <nav className={`top-navbar ${(activeTerm && !isMobile && !isOnboarding && (topHeightVisibility || callBarVisibility)) ? "!top-[50px]" : ""}`}>

        <Link
            onClick={() => setActiveTab('dashboard')}
            to={url("home")}
            className="hidden md:flex items-center max-md:mr-2"
        >
          <div className="ml-2 flex items-center">
            <img className="w-[28px] lg:w-[36px]" src={Logo} alt=""/>
            <p className="font-bold text-[14px] lg:text-lg ml-1 lg:ml-2">
              {strings.brand_name}
            </p>
          </div>
        </Link>
        <div className="flex items-center max-md:w-full">
          <div className="hidden md:flex">
            <Link
                onClick={() => setActiveTab('dashboard')}
                to={url("home")}
                className="flex mr-3 lg:mr-5 xl:mr-7 text-nav-button-border"
            >
              <img
                  className="mr-[2px] xl:mr-1 w-[16px] h-[16px] lg:w-[26px] lg:h-[26px]"
                  src={activeTab === "dashboard" ? Home_Active : Home}
                  alt=""
              />
              <span className={classNames({
                "text-active-text": activeTab === "dashboard",
                "text-nav-button-border": activeTab !== "dashboard"
              })}>
                {strings.menu.nav.menu1}
              </span>
            </Link>
            <Link
                onClick={() => setActiveTab('speakinglesson')}
                to={url("speakinglesson")}
                className="flex mr-3 lg:mr-5 xl:mr-7 text-nav-button-border"
            >
              <img
                  className="mr-[2px] xl:mr-1 w-[16px] h-[16px] lg:w-[26px] lg:h-[26px]"
                  src={activeTab === "speakinglesson" ? Microphone_Active : Microphone}
                  alt=""
              />
              <span className={classNames({
                "text-active-text": activeTab === "speakinglesson",
                "text-nav-button-border": activeTab !== "speakinglesson"
              })}>
                {strings.menu.nav.menu2}
              </span>
            </Link>
            <Link
                onClick={() => setActiveTab('teachers')}
                to={url("teachers")}
                className="flex mr-3 lg:mr-5 xl:mr-7 text-nav-button-border"
            >
              <img
                  className="mr-[2px] xl:mr-1 w-[16px] h-[16px] lg:w-[26px] lg:h-[26px]"
                  src={activeTab === "teachers" ? Teachers_Active : Teachers}
                  alt=""
              />
              <span className={classNames({
                "text-active-text": activeTab === "teachers",
                "text-nav-button-border": activeTab !== "teachers"
              })}>
                {strings.menu.nav.menu3}
              </span>
            </Link>
            <Link
                onClick={() => setActiveTab('market')}
                to={url("market")}
                className="flex mr-3 lg:mr-5 xl:mr-7 text-nav-button-border"
            >
              <img
                  className="mr-[2px] xl:mr-1 w-[16px] h-[16px] lg:w-[26px] lg:h-[26px]"
                  src={activeTab === "market" ? Market_Active : Market}
                  alt=""
              />
              <span className={classNames({
                "text-active-text": activeTab === "market",
                "text-nav-button-border": activeTab !== "market"
              })}>
                {strings.menu.nav.menu4}
              </span>
            </Link>
            <Link
                onClick={() => setActiveTab('support')}
                to={url("support")}
                className="flex mr-3 lg:mr-5 xl:mr-7 text-nav-button-border"
            >
              <img
                  className="mr-[2px] xl:mr-1 w-[16px] h-[16px] lg:w-[26px] lg:h-[26px]"
                  src={activeTab === "support" ? Support_Active : Support}
                  alt=""
              />
              <span className={classNames({
                "text-active-text": activeTab === "support",
                "text-nav-button-border": activeTab !== "support"
              })}>
                {strings.menu.nav.menu5}
              </span>
            </Link>
          </div>
          <div className="max-md:w-full flex items-center">

            {/* market ekranlarında geri butonunu göstermiyoruz */}
            {!pathname.includes("/market/") && (
                pathname !== "/dashboard" ? (
                    <Link
                        onClick={() => navigate(-1)}
                        className="navbar-btn flex-shrink-0 flex md:hidden"
                    >
                      <img className="w-[10px] h-[18px]" src={Chevron_Right} alt=""/>
                    </Link>
                ) : (
                    <div className="navbar-btn flex-shrink-0 flex md:hidden">
                  <span className="material-symbols-outlined text-base-text">
                    in_home_mode
                  </span>
                    </div>
                )
            )}

            <div className="navbar-btn ml-3 xl:ml-5 flex-shrink-0 !border-transparent !bg-transparent md:hidden"></div>

            <p className="block md:hidden font-bold w-full text-center">
              {title}
            </p>

            <Link
                to={url("learntogether")}
                className="navbar-btn flex-shrink-0 relative mr-3 xl:mr-5"
            >
              <img
                  className="md-lg:w-[16px] md-lg:h-[16px] w-[24px] h-[24px]"
                  src={MenuFamily}
                  alt=""
              />
            </Link>

            <button
                onClick={() => {
                  openCollapse(collapseRef, collapseOverlayRef);
                  setIsCollapseOpen(true);
                }}
                className="navbar-btn flex-shrink-0 relative"
                type="button"
            >
              <img
                  className="md-lg:w-[15px] md-lg:h-[10.5px] w-[18px] h-[14px]"
                  src={Settings}
                  alt=""
              />
              {/* Notification Badge */}
              {unreadMessageCount > 0 && (
                <div className="absolute -top-[5px] -right-[6px] min-w-[20px] h-[20px] bg-red rounded-[10px] flex justify-center items-center border border-white shadow-[0_1px_2px_0_rgba(0,0,0,0.2)]">
                  <span className="text-white text-[11px] font-bold leading-[16px] text-center">
                    {unreadMessageCount > 9 ? '9+' : unreadMessageCount}
                  </span>
                </div>
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* <!-- bottom navbar --> */}
      <nav className="bottom-navbar">
        <Link
            onClick={() => setActiveTab('dashboard')}
            to={url("home")}
        >
          <img
            className="mr-[2px] xl:mr-1"
            src={activeTab === "dashboard" ? Home_Active : Home}
            width="26"
            height="26"
            alt=""
          />
        </Link>
        <Link
          onClick={() => setActiveTab('speakinglesson')}
          to={url("speakinglesson")}
        >
          <img
            className="mr-[2px] xl:mr-1"
            src={activeTab === "speakinglesson" ? Microphone_Active : Microphone}
            width="26"
            height="26"
            alt=""
          />
        </Link>
        <Link
          onClick={() => setActiveTab('teachers')}
          to={url("teachers")}
        >
          <img
            className="mr-[2px] xl:mr-1"
            src={activeTab === "teachers" ? Teachers_Active : Teachers}
            width="26"
            height="26"
            alt=""
          />
        </Link>
        <Link
          onClick={() => setActiveTab('market')}
          to={url("market")}
        >
          <img
            className="mr-[2px] xl:mr-1"
            src={activeTab === "market" ? Market_Active : Market}
            width="26"
            height="26"
            alt=""
          />
        </Link>
        <Link
          onClick={() => setActiveTab('support')}
          to={url("support")}
        >
          <img
            className="mr-[2px] xl:mr-1"
            src={activeTab === "support" ? Support_Active : Support}
            width="26"
            height="26"
            alt=""
          />
        </Link>
      </nav>
      {(activeTerm && !isMobile && !isOnboarding && (topHeightVisibility || callBarVisibility)) && <div className="h-[50px]"></div>}

      <CollapseOverlay ref={collapseOverlayRef} />
      <Collapse ref={collapseRef} overlayRef={collapseOverlayRef} isCollapseOpen={isCollapseOpen} setIsCollapseOpen={setIsCollapseOpen} />
    </>
  );
};

export default React.memo(Menu);
