import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { HidePassword, MagicWand, ShowPassword, Speaker, StopButton, Translate } from "../../../../../assets/svg";
import { EventLogs } from "../../../../../components/Constants";
import AILoader from "../../AIPractice/AILoader";

const AIChatBubble = ({ 
    content,
    trySayingMessage = null,
    translation,
    isLoading, 
    onSpeakComplete, 
    onSpeakingStateChange, 
    audioUrl = null,
    messageId,
    currentPlayingId,
    stopCurrentAudio,
    isReadOnly = false,
    ttsVoice,
    speakSentence, // Sadece audioUrl'in olmadığı durumda kullanılır.
    outsideSpeakingCompleted, // Sadece audioUrl'in olmadığı durumda kullanılır.
    logAction = null,
    tutor = null,
    explainToMeText = null,
    onShowClue = null, 
    timestamp = null
}) => {

    const [hideContent, setHideContent] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [lineHeights, setLineHeights] = useState([]);
    const [showTranslation, setShowTranslation] = useState(false);
    const [showClue, setShowClue] = useState(false);

    const audioRef = useRef(new Audio());
    const contentRef = useRef(null);
    const isAnotherPlaying = currentPlayingId !== null && currentPlayingId !== messageId;

    const handleSpeak = async () => {
        try {
            if(!audioUrl){
                if (isSpeaking) {
                    setIsSpeaking(false);
                    onSpeakingStateChange?.(false, null);
                    return;
                }

                setIsSpeaking(true);
                onSpeakingStateChange?.(true, audioRef.current);
                
                await speakSentence(content, ttsVoice || "")
                    .then(() => {
                        setIsSpeaking(false);
                        onSpeakingStateChange?.(false, null);
                        onSpeakComplete?.();
                    })
                    .catch(error => {
                        setIsSpeaking(false);
                        onSpeakingStateChange?.(false, null);
                    });
                return;
            }

            if (isSpeaking) {
                stopCurrentAudio?.();
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setIsSpeaking(false);
                onSpeakingStateChange?.(false, null);
                return;
            }

            setIsSpeaking(true);
            onSpeakingStateChange?.(true, audioRef.current);
            
            audioRef.current.src = audioUrl;
            audioRef.current.onended = () => {
                setIsSpeaking(false);
                onSpeakingStateChange?.(false, null);
                onSpeakComplete?.();
            };

            const playPromise = audioRef.current.play();
            
            if (playPromise !== undefined) {
                playPromise.catch(error => {
                    setIsSpeaking(false);
                    onSpeakingStateChange?.(false, null);
                });
            }
        } catch (error) {
            setIsSpeaking(false);
            onSpeakingStateChange?.(false, null);
        }
    };

    // Görünen satırları hesaplar
    useEffect(() => {
        if (contentRef.current && !isLoading) {
            const element = contentRef.current;
            const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
            const height = element.offsetHeight;
            const lines = Math.floor(height / lineHeight);
            
            // Her satır için bir genişlik belirler
            const widths = Array(lines).fill(0).map((_, index) => {
                if (index === lines - 1) {
                    // Son satır için daha kısa genişlik belirler
                    return '50%';
                }
                // Diğer satırlar için tam genişlik belirler
                return '95%';
            });
            
            setLineHeights(widths);
        }
    }, [content, isLoading]);

    // currentPlayingId değiştiğinde isSpeaking'i günceller
    useEffect(() => {
        // Eğer bu mesajın sesi çalmıyorsa, isSpeaking'i false yap
        if (currentPlayingId !== messageId) {
            setIsSpeaking(false);
        }
    }, [currentPlayingId, messageId]);

    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = '';
            }
        };
    }, []);

    useEffect(() => {
        if (content && !isLoading && audioUrl) {
            // Eğer tutor.sectionCompletionMessage varsa, seslendirmeyi geciktir
            const delay = tutor?.sectionCompletionMessage ? 1500 : 0;
            const timer = setTimeout(() => {
                handleSpeak();
            }, delay);

            return () => clearTimeout(timer);
        }
    }, [content, isLoading, audioUrl]);

    // outsideSpeakingCompleted değiştiğinde isSpeaking'i günceller.
    useEffect(() => {
        if (outsideSpeakingCompleted) {
            setIsSpeaking(false);
            onSpeakingStateChange?.(false, null);
        }
    }, [outsideSpeakingCompleted]);

    return (
        <div className="flex flex-row my-1 pl-2">
            <motion.div
                initial={{ x: -150, scale: 0 }}
                animate={{ x: 0, scale: 1 }}
                transition={{ duration: 0.3 }}
                className="bg-box rounded-2xl max-w-[80%] w-fit px-4 py-3 rounded-tl-sm"
            >
                <div className="flex flex-col w-full">
                    {isLoading ? (
                        <div className="w-6">
                            <AILoader color="#309DFF" />
                        </div>
                    ) : (
                        <>
                            <div className="relative">
                                <p 
                                    ref={contentRef}
                                    className={`text-sm leading-6 transition-colors duration-200 break-words whitespace-pre-wrap
                                        ${hideContent ? 'invisible' : 'text-base-text'}`}
                                        dangerouslySetInnerHTML={{ __html: trySayingMessage ? trySayingMessage + "<br>" + content.trim() : content.trim() }}
                                />
                                {timestamp && (
                                    <p className="text-base-text-light text-[12px] text-right">{timestamp}</p>
                                )}
                                {!isReadOnly && !isLoading && content && (
                                    <div className="flex flex-row items-center gap-1 mt-2">
                                        <button 
                                            type="button"
                                            onClick={() => {
                                                handleSpeak();
                                                logAction?.(EventLogs.CONVERSATIONAL_AI_LISTEN_CLICKED);
                                            }} 
                                            disabled={isAnotherPlaying}
                                            className={`transition-opacity p-1 ${
                                                isAnotherPlaying 
                                                    ? 'opacity-50 cursor-not-allowed' 
                                                    : 'hover:opacity-80'
                                            }`}
                                            aria-label={isSpeaking ? "Stop speaking" : "Start speaking"}
                                        >
                                            <img 
                                                src={isSpeaking ? StopButton : Speaker} 
                                                className={`w-[18px] h-[18px] ${
                                                    isAnotherPlaying ? 'text-gray-400' : 'text-base-text'
                                                }`}
                                                alt={isSpeaking ? "stop speaking" : "start speaking"}
                                            />
                                        </button>
                                        <button 
                                            type="button"
                                            className="transition-all duration-200 hover:bg-gray-100 p-1 rounded-full"
                                            aria-label="Toggle translation"
                                            onClick={() => {
                                                setShowTranslation(!showTranslation);
                                                logAction?.(EventLogs.CONVERSATIONAL_AI_TRANSLATE_CLICKED);
                                            }}
                                        >
                                            <img 
                                                src={Translate} 
                                                className="w-[18px] h-[18px]"
                                                style={{
                                                    filter: showTranslation 
                                                        ? 'invert(40%) sepia(95%) saturate(1752%) hue-rotate(199deg) brightness(100%) contrast(101%)'
                                                        : 'none'
                                                }}
                                                alt="translate"
                                            />
                                        </button>
                                        <button 
                                            type="button"
                                            onClick={() => setHideContent(!hideContent)}
                                            className="transition-all duration-200 hover:bg-gray-100 p-1 rounded-full"
                                            aria-label={hideContent ? "Show content" : "Hide content"}
                                        >
                                            <img 
                                                src={hideContent ? ShowPassword : HidePassword} 
                                                className="w-[18px] h-[18px]"
                                                style={{
                                                    filter: hideContent 
                                                        ? 'invert(40%) sepia(95%) saturate(1752%) hue-rotate(199deg) brightness(100%) contrast(101%)'
                                                        : 'none'
                                                }}
                                                alt={hideContent ? "show content" : "hide content"}
                                            />
                                        </button>
                                    </div>
                                )}
                                <AnimatePresence>
                                    {showTranslation && translation && (
                                        <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.2 }}
                                            className="mt-2 pt-2 border-t border-gray-200"
                                        >
                                            <p className="text-sm text-gray-600">
                                                {translation}
                                            </p>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                                {hideContent && (
                                    <div className="absolute inset-0 !bottom-10 space-y-2">
                                        {lineHeights.map((width, index) => (
                                            <div
                                                key={index}
                                                className="bg-gray-200 rounded"
                                                style={{
                                                    height: '16px',
                                                    width: width,
                                                    marginBottom: '4px'
                                                }}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                            {tutor?.clue && (
                                <div className="w-full">
                                    <div className="h-[1px] bg-gray-200 my-2" />
                                    <div className="flex items-center gap-2">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setShowClue(!showClue);
                                                onShowClue?.(!showClue);
                                            }}
                                            className="flex items-center gap-2 text-sm transition-colors duration-200"
                                        >
                                            <img 
                                                src={MagicWand} 
                                                className="w-[18px] h-[18px]"
                                                style={{
                                                    filter: showClue 
                                                        ? 'invert(40%) sepia(95%) saturate(1752%) hue-rotate(199deg) brightness(100%) contrast(101%)'
                                                        : 'none'
                                                }}
                                                alt={showClue ? "hide content" : "show content"}
                                            />
                                            <span className="font-bold" style={{ color: showClue ? '#309DFF' : '#333333' }}>
                                                {explainToMeText}
                                            </span>
                                        </button>
                                    </div>
                                    <AnimatePresence>
                                        {showClue && (
                                            <motion.div
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: 'auto' }}
                                                exit={{ opacity: 0, height: 0 }}
                                                transition={{ duration: 0.2 }}
                                                className="bg-box border-t border-gray-100"
                                            >
                                                <p className="text-sm break-words whitespace-pre-wrap pt-2">
                                                    {tutor.clue}
                                                </p>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </motion.div>
        </div>
    );
};

export default AIChatBubble;