import React from "react";
import {Outlet} from "react-router-dom";
import {Menu, SideBar} from "../../components";
import {useLocalization} from "../../hooks/useLocalization";

const MessagesLayout = () => {
    const strings = useLocalization();

    return (
        <>
            <Menu title={strings.menu.collapse.messages} />
            <section className="container">
                <Outlet />
                <SideBar />
            </section>
        </>
    );
};

export default MessagesLayout;