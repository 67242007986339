import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    unreadCount: parseInt(localStorage.getItem('unreadMessageCount')) || 0
};

export const MessageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setUnreadCount: (state, action) => {
            state.unreadCount = action.payload;
            localStorage.setItem('unreadMessageCount', action.payload);
        }
    }
});

export const { setUnreadCount } = MessageSlice.actions; 
export default MessageSlice.reducer