import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DemoLessonAttend, DemoLessonPlan, DemoLessonPrepare, DemoLessonReport, DemoVideoLesson } from "../../../assets/svg";
import { Accordion, ModalOneButton, ModalOverlay } from "../../../components";
import { DemoStatusType, EventLogs, LessonResultAccordionType, ServiceErrorMessages, TermLessonAccordionType } from "../../../components/Constants";
import useAccordionActivity from "../../../hooks/useAccordionActivity";
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import useTermAccordionActivity from "../../../hooks/useTermAccordionActivity";
import { url } from "../../../routes/utility";
import { CheckIsDemoStudent, CheckMemberDemoRight } from "../../../services/MemberDemoRight/MemberDemoRightServices";
import { closeModal, formatJustDateDotToDash, openModal } from "../../../utils";
import { DemoAutoTeacher } from "../../Demo";
import DemoTermContent from "./DemoTermContent";

const DemoProgress = (props) => {

    const { lessonData, lessonInfo, terms } = props;

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const selectedTermId = useSelector(state => state.termStore.termId);

    const { member } = useAuth();
    const { activities: termAccordionActivities, addTermAccordionActivity } = useTermAccordionActivity(
        [
            {
                termLessonAccordionType: TermLessonAccordionType.PREPARE_DEMO_LESSON,
                isOpened: lessonData?.accordionModel?.isPrepareLessonAccordionOpened ?? false
            }
        ]
    );
    const { activities: accordionActivities, addAccordionActivity } = useAccordionActivity(
        lessonData?.lessonResultId ?? "",
        [
            {
                lessonResultAccordionType: LessonResultAccordionType.REVIEW_DEMO_LESSON_RESULT,
                isOpened: lessonData?.accordionModel?.isShowReportAccordionOpened ?? false
            }
        ] ?? []
    );

    const firstVisit = useRef(true);
    const childrenRef = useRef();

    const modalOverlayRef = useRef();
    const modalAutoDemoTeacherSelectionRef = useRef();
    const modalMemberDemoRightRef = useRef();

    const circleCircumference = 2 * Math.PI * 18; // r = 18, çember çevresi hesabı
    const isHaveDemoLesson = (lessonData.demoStatus === DemoStatusType.NormalDemo || lessonData.demoStatus === DemoStatusType.AutoDemo) && terms;
    const formattedLessonDate = Object.keys(lessonData).length > 0 ? formatJustDateDotToDash(lessonData?.lessonDate) : "";

    // Akordiyonun açılıp kapanıp olup olmadığını kontrol ediyor.
    const [open, setOpen] = useState(!isHaveDemoLesson);
    // Tamamlanan steplerin sayısını tutar
    const [completedSteps, setCompletedSteps] = useState(0);
    // Çemberin çevresi
    const [progress, setProgress] = useState(0);
    // Demo derse ait otomatik eğitmen seçme modali
    const [showAutoDemoTeacherSelectionModal, setShowAutoDemoTeacherSelectionModal] = useState(false);
    // Üye demo hakkı var mı kontrolü
    const [modalMessage, setModalMessage] = useState({});
    // Üye demo öğrenci mi kontrol eder
    const [isDemoStudent, setIsDemoStudent] = useState(false);

    // Akordiyon açıp/kapama işlemi sonrası çalışacak fonksiyon
    // Animasyon kontrolü için kullanılıyor.
    const handleTransitionEnd = () => {
        if (!open) {
            childrenRef?.current?.classList.add('hidden');
        }
    };

    const checkMemberDemoRight = async (memberId) => {
        const result = await CheckMemberDemoRight(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            if (resultContent) {
                setShowAutoDemoTeacherSelectionModal(true);
                openModal(modalOverlayRef, modalAutoDemoTeacherSelectionRef);
                analytics.useAnalytics(EventLogs.DEMO_PLAN_CLICKED, undefined, false, true);
                analytics.useAnalytics(EventLogs.DEMO_LESSON_CLICKED, undefined, true, false);
            } else {
                setModalMessage({
                   text: strings.menu.collapse.modal.no_demo_description,
                   buttonText: strings.menu.collapse.modal.contact_mentor,
                   navigate: true
                });
                openModal(modalOverlayRef, modalMemberDemoRightRef);
            }
        } else {
            if(result.content === ServiceErrorMessages.ALREADY_ACTIVE_DEMO)
                setModalMessage({
                    text: strings.menu.collapse.modal.already_have_active_demo,
                    buttonText: strings.modal.okey,
                    navigate: false
                });
            else
                setModalMessage({
                    text: strings.general_information_messages.an_error_occured,
                    buttonText: strings.modal.okey,
                    navigate: false
                });
            openModal(modalOverlayRef, modalMemberDemoRightRef);
        }
    }

    const checkIsDemoStudent = async (memberId) => {
        const result = await CheckIsDemoStudent(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setIsDemoStudent(resultContent);
        } else {
            setIsDemoStudent(false);
        }
    }

    useEffect(() => {
        if (open) {
            childrenRef?.current?.classList.remove('hidden');
        }
    }, [open]);

    useEffect(() => {
        let timer;
        if (open) {
            // Animasyonu hemen başlatmak yerine, bir sonraki frame'de başlatıyoruz
            timer = requestAnimationFrame(() => {
                setProgress((completedSteps / 4) * circleCircumference);
            });
        } else {
            setProgress(0);
        }
        return () => cancelAnimationFrame(timer);
    }, [open, completedSteps, circleCircumference]);

    useEffect(() => {
        if (isHaveDemoLesson) {
            setCompletedSteps(completedSteps => completedSteps + 1);
        }
    }, [lessonData])

    // Demo derse katıldıysa 1 adım tamamlanmış olarak işaretle
    useEffect(() => {
        if (lessonData.isAttendedLesson) {
            setCompletedSteps(completedSteps => completedSteps + 1);
        }
    }, [lessonData.isAttendedLesson])

    // Demo derse hazırlandıysa 1 adım tamamlanmış olarak işaretle
    useEffect(() => {
        if (termAccordionActivities?.find(l => TermLessonAccordionType.PREPARE_DEMO_LESSON === l.termLessonAccordionType)?.isOpened) {
            setCompletedSteps(completedSteps => completedSteps + 1);
        }
    }, [termAccordionActivities])

    // Demo derse katıldıysa 1 adım tamamlanmış olarak işaretle
    useEffect(() => {
        if (accordionActivities?.find(l => LessonResultAccordionType.REVIEW_DEMO_LESSON_RESULT === l.lessonResultAccordionType)?.isOpened) {
            setCompletedSteps(completedSteps => completedSteps + 1);
        }
    }, [accordionActivities])

    useEffect(() => {
        member?.MemberId && checkIsDemoStudent(member?.MemberId);
    }, [member?.MemberId])

    return (
        <>
            <div className="outline-none text-sm mt-5 mb-5">
                <div
                    className={classNames(`flex justify-between border-b rounded-[10px] px-4 py-[7px] items-center transition duration-500 cursor-pointer relative group-focus-within:border-b-0 w-full pr-1 bg-box`, {
                        "rounded-b-none border-b-[#e5e5e5]" : open
                    })}
                    onClick={() => {
                        setOpen(open => !open);
                        firstVisit.current = false
                    }}
                >
                    <div className="flex gap-2 py-2 justify-center items-center">
                        <div className="relative w-[50px] h-[50px] flex flex-shrink-0 items-center justify-center">
                            <img src={DemoVideoLesson} width={open ? 30 : 38} height={open ? 30 : 38} alt="demo-video-lesson" className="absolute z-10 transition-all duration-300" />
                            {open && 
                                <svg className="w-full h-full absolute inset-0" viewBox="0 0 40 40">
                                    <circle cx="20" cy="20" r="18" fill="none" stroke="#e5e5e5" strokeWidth="4" />
                                    <circle 
                                        cx="20" 
                                        cy="20" 
                                        r="18" 
                                        fill="none" 
                                        stroke="#66bb6a" 
                                        strokeWidth="4" 
                                        strokeDasharray={circleCircumference}
                                        strokeDashoffset={circleCircumference - progress}
                                        transform="rotate(-90 20 20)"
                                        className="transition-all duration-1000 ease-in-out"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            }
                        </div>
                        <div>
                            <p className="text-start font-bold text-[18px]">
                                {strings.demo.demo_progress.demo_lesson}
                            </p>
                            {!open && 
                                <p className="text-start mr-7 text-[16px]">
                                    {strings.demo.demo_progress.demo_lesson_description}
                                </p>
                            }

                            {open && 
                                <div className="flex items-center">
                                    <p className="text-[16px]">
                                        {strings.demo.demo_progress.demo_progress_step
                                            .replace("#completedStepCount#", completedSteps)
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div
                        className={classNames("items-center inline-flex justify-center transform transition duration-500 absolute right-0 mr-2 ml-auto h-full", {
                            "rotate-90" : open
                        })}
                    >
                        <span className={classNames("material-symbols-outlined transition-colors duration-300", {
                            "text-primary" : open,
                            "text-base-text": !open
                        })}>
                            arrow_forward_ios
                        </span>
                    </div>
                </div>
                <div
                    className={classNames(`transition-all duration-500 border-box rounded-[10px] rounded-t-none px-4 bg-box`, {
                        "border-x border-b pt-2" : open,
                        "max-h-0" : !open
                    })}
                    onTransitionEnd={handleTransitionEnd}
                >
                    {open && (
                        <div
                            ref={childrenRef}
                            className={`${!open && 'animate-fadeOut'} ${firstVisit.current ? 'hidden' : ''}`}
                        >
                            <Accordion
                                title={strings.demo.demo_progress.demo_lesson_plan}
                                disableOutsideClick={true}
                                isScrollable={false}
                                isDownArrowIcon={false}
                                isButton={true}
                                isOpenCloseIcon={false}
                                variant="white"
                                iconContent={<img src={DemoLessonPlan} width={35} height={35} className="mx-0.5 py-1"  alt="demo-lesson-plan" />}
                                openCloseCheckboxStatus={isHaveDemoLesson}
                                titleVariant="normal"
                                action={() => checkMemberDemoRight(member?.MemberId)}
                                isDisabled={isDemoStudent}
                            >
                            </Accordion>
                            <Accordion
                                title={strings.demo.demo_progress.demo_lesson_prepare}
                                disableOutsideClick={true}
                                isScrollable={false}
                                isDownArrowIcon={false}
                                isButton={lessonData?.isAttendedLesson}
                                isClickable={(lessonData.demoStatus === DemoStatusType.NormalDemo || lessonData.demoStatus === DemoStatusType.AutoDemo) && !lessonData.isAttendedLesson}
                                isOpenCloseIcon={false}
                                variant="white"
                                iconContent={<img src={DemoLessonPrepare} width={35} height={35} className="mx-0.5 py-1"  alt="demo-lesson-prepare" />}
                                titleVariant="normal"
                                isDisabled={!isHaveDemoLesson}
                                openCloseCheckboxStatus={
                                    termAccordionActivities
                                        ?.find(l => TermLessonAccordionType.PREPARE_DEMO_LESSON === l.termLessonAccordionType)?.isOpened
                                    || false
                                }
                                action={() => {
                                    addTermAccordionActivity(
                                        TermLessonAccordionType.PREPARE_DEMO_LESSON, 
                                        selectedTermId, 
                                        formattedLessonDate
                                    );
                                }}
                            >
                                {!lessonData?.isAttendedLesson && 
                                    <DemoTermContent 
                                        lessonData={lessonData}
                                        lessonInfo={lessonInfo}
                                        terms={terms}
                                    />
                                }
                            </Accordion>
                            <Accordion
                                title={strings.demo.demo_progress.demo_lesson_attend}
                                disableOutsideClick={true}
                                isScrollable={false}
                                isDownArrowIcon={false}
                                isButton={true}
                                isClickable={false}
                                isOpenCloseIcon={false}
                                variant="white"
                                iconContent={<img src={DemoLessonAttend} width={35} height={35} className="mx-0.5 py-1"  alt="demo-lesson-attend" />}
                                openCloseCheckboxStatus={lessonData.isAttendedLesson ?? false}
                                titleVariant="normal"
                                isDisabled={!lessonData.isAttendedLesson}
                            >
                            </Accordion>
                            <Accordion
                                title={strings.demo.demo_progress.demo_lesson_report}
                                disableOutsideClick={true}
                                isScrollable={false}
                                isDownArrowIcon={false}
                                isButton={true}
                                isOpenCloseIcon={false}
                                variant="white"   
                                iconContent={<img src={DemoLessonReport} width={35} height={35} className="mx-0.5 py-1"  alt="demo-lesson-report" />}
                                titleVariant="normal"
                                isDisabled={!lessonData.isAttendedLesson}
                                openCloseCheckboxStatus={
                                    accordionActivities
                                        ?.find(l => LessonResultAccordionType.REVIEW_DEMO_LESSON_RESULT === l.lessonResultAccordionType)?.isOpened
                                    || false
                                }
                                action={() => {
                                    addAccordionActivity(
                                        LessonResultAccordionType.REVIEW_DEMO_LESSON_RESULT, 
                                        lessonData?.lessonResultId
                                    );
                                    localStorage.setItem("selectedDate", formattedLessonDate);
                                    navigate(url("speakinglesson.dailylesson"));
                                }}
                            >
                            </Accordion>
                        </div>
                    )}

                    {!open && <></>}
                </div>
            </div>

            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalMemberDemoRightRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage?.text}
                buttonText={modalMessage?.buttonText}
                buttonClick={() => {
                    modalMessage?.navigate && navigate(url("mentor"));
                    closeModal(modalOverlayRef, modalMemberDemoRightRef);
                }}
            />
            {showAutoDemoTeacherSelectionModal &&
                <DemoAutoTeacher
                    ref={modalAutoDemoTeacherSelectionRef}
                    overlayRef={modalOverlayRef}
                    closeAction={() => {
                        setShowAutoDemoTeacherSelectionModal(false);
                        closeModal(modalOverlayRef, modalAutoDemoTeacherSelectionRef);
                    }}
                />
            }
        </>
    );
}

export default DemoProgress;