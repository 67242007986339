import classNames from "classnames";
import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from "react";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { BottomSheet } from "react-spring-bottom-sheet";
import '../../../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import checkmark from "../../../../assets/animation/checkmark.json";
import error from "../../../../assets/animation/error.json";
import { Dropdown } from "../../../../components";
import { EventLogs, ServiceErrorMessages } from "../../../../components/Constants";
import { ModalContent, ModalOverlay } from "../../../../components/Modals";
import { useLocalization } from "../../../../hooks/useLocalization";
import useWindowSize from "../../../../hooks/useWindowSize";
import { validPhoneNumber } from '../../../../services/Auth/AuthValidation';
import { TransferMoneybox } from "../../../../services/LearnTogether/LearnTogetherService";
import { closeModal, openModal } from "../../../../utils";
import useAnalytics from "../../../../hooks/useAnalytics";

const DiscountTransfer = ({ activeMoneyboxRate = 0, memberId, onTransferComplete }) => {
    
    const strings = useLocalization();
    const analytics = useAnalytics();

    const modalOverlayRef = useRef();
    const modalRef = useRef();

    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 640;

    const [showTransferModal, setShowTransferModal] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState('+');
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [phoneNumberValue, setPhoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });

    const transferContentLocalizations = {
        title: strings.learn_together.moneybox_transfer.discount_transfer.transfer_discount_rate_title,
        phone_number: strings.auth.form.phone_number,
        phone_number_error: strings.auth.validation_messages.invalid_phone,
        transfer_discount_rate: strings.learn_together.moneybox_transfer.discount_transfer.transfer_discount_rate,
        transfer_description: strings.learn_together.moneybox_transfer.discount_transfer.transfer_discount_rate_description,
        button: strings.learn_together.moneybox_transfer.discount_transfer.title
    };

    const confirmContentLocalizations = {
        title: strings.learn_together.moneybox_transfer.discount_transfer.transfer_discount_rate_title,
        phone_number: strings.auth.form.phone_number,
        transfer_discount_rate: strings.learn_together.moneybox_transfer.discount_transfer.transfer_discount_rate,
        transfer_description: strings.learn_together.moneybox_transfer.discount_transfer.transfer_confirm_description,
        button: strings.modal.approve,
        close_button: strings.dashboard.yearly_summary.modal.close,
        edit: strings.learn_together.edit,
        processing: strings.general_information_messages.processing,
        success_message: strings.learn_together.moneybox_transfer.discount_transfer.transfer_success_message,
        same_person_error_message: strings.learn_together.moneybox_transfer.discount_transfer.transfer_same_person_error_message,
        not_found_error_message: strings.learn_together.moneybox_transfer.discount_transfer.transfer_not_found_error_message,
        default_error_message: strings.general_information_messages.an_error_occured
    };

    const sendLog = (event) => {
        analytics.useAnalytics(event);
    };

    const handleNextStep = (discount, phoneValue) => {
        setSelectedDiscount(discount);
        setPhoneNumberValue(phoneValue);
        setCurrentStep(2);
    };

    const resetStates = () => {
        setShowTransferModal(false);
        setCurrentStep(1);
        setPhoneNumber('+');
        setSelectedDiscount(null);
        setPhoneNumberValue({ iso2: 'tr', dialCode: '90', phone: '' });
        !isMobile && closeModal(modalOverlayRef, modalRef);
        onTransferComplete();
    };

    useEffect(() => {
        if(isMobile) {
            const rootElement = document.getElementById('root');
            if (showTransferModal) {
                rootElement.setAttribute('inert', '');
                rootElement.removeAttribute('aria-hidden');
            } else {
                rootElement.removeAttribute('inert');
            }

            return () => {
                rootElement.removeAttribute('inert');
            };
        }   
    }, [showTransferModal]);

    return (
        <>
            {/* Ana Buton */}
            <button
                type="button"
                className={classNames("button primary-button mt-5 max-w-[330px] mx-auto", {
                    "pointer-events-none opacity-70": activeMoneyboxRate === 0
                })}
                onClick={() => {
                    sendLog(EventLogs.TRANSFER_DISCOUNT_BUTTON_CLICK);
                    setShowTransferModal(true);
                    if (!isMobile) {
                        openModal(modalOverlayRef, modalRef);
                    }
                }}
            >
                <span>{strings.learn_together.moneybox_transfer.discount_transfer.title}</span>
            </button>

            <ModalOverlay ref={modalOverlayRef} />
            {isMobile ? (
                <BottomSheet
                    open={showTransferModal}
                    onDismiss={resetStates}
                    className="mobile-view"
                    snapPoints={({ maxHeight }) => {
                        if (windowSize.height <= 667) {
                            return [Math.min(475, maxHeight * 0.9)];
                        }
                        return [Math.min(475, maxHeight * 0.7)];
                    }}
                    blocking={true}
                    defaultSnap={({ maxHeight }) => {
                        if (windowSize.height <= 667) {
                            return Math.min(475, maxHeight * 0.9);
                        }
                        return Math.min(475, maxHeight * 0.7);
                    }}
                >
                    {currentStep === 1 ? (
                        <TransferContent 
                            onNextStep={handleNextStep}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            initialPhoneValue={phoneNumberValue}
                            initialDiscount={selectedDiscount}
                            localizations={transferContentLocalizations}
                            activeMoneyboxRate={activeMoneyboxRate}
                            sendLog={sendLog}
                        />
                    ) : (
                        <ConfirmContent 
                            phoneNumber={phoneNumber}
                            selectedDiscount={selectedDiscount}
                            setCurrentStep={setCurrentStep}
                            memberId={memberId}
                            localizations={confirmContentLocalizations}
                            resetStates={resetStates}
                            sendLog={sendLog}
                        />
                    )}
                </BottomSheet>
            ) : (
                <ModalContent
                    ref={modalRef}
                    show={showTransferModal}
                    overlayRef={modalOverlayRef}
                    showCloseButton={true}
                    closeAction={resetStates}
                    className="sm:w-[500px] sm:h-auto"
                    aria-modal="true"
                >
                    {currentStep === 1 ? (
                        <TransferContent 
                            onNextStep={handleNextStep}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            initialPhoneValue={phoneNumberValue}
                            initialDiscount={selectedDiscount}
                            localizations={transferContentLocalizations}
                            activeMoneyboxRate={activeMoneyboxRate}
                            sendLog={sendLog}
                        />
                    ) : (
                        <ConfirmContent 
                            phoneNumber={phoneNumber}
                            selectedDiscount={selectedDiscount}
                            setCurrentStep={setCurrentStep}
                            memberId={memberId}
                            localizations={confirmContentLocalizations}
                            resetStates={resetStates}
                            sendLog={sendLog}
                        />
                    )}
                </ModalContent>
            )}
        </>
    );
}

const TransferContent = ({ onNextStep, phoneNumber, setPhoneNumber, initialPhoneValue, initialDiscount, localizations, activeMoneyboxRate, sendLog }) => {

    const strings = useLocalization();

    const phoneErrorRef = useRef();

    const [phoneNumberValue, setPhoneNumberValue] = useState(initialPhoneValue || { iso2: 'tr', dialCode: '90', phone: '' });
    let inputProps = { placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : strings.auth.form.enter_your_phone_number };

    const generateDiscountRates = (maxRate) => {
        const rates = [];
        // En az 5, en fazla activeMoneyboxRate kadar, 5'er 5'er artacak
        for (let i = 5; i <= maxRate; i += 5) {
            rates.push({ 
                id: i/5, 
                name: `%${i}`, 
                value: i 
            });
        }
        return rates;
    };

    const discountRates = generateDiscountRates(activeMoneyboxRate);
    const [selectedDiscount, setSelectedDiscount] = useState(initialDiscount || (discountRates.length > 0 ? discountRates[0] : null));

    const phoneNumberHandle = val => {
        if (val.phone.startsWith('0')) {
            val.phone = val.phone.slice(1);
        }
        if (val.dialCode !== phoneNumberValue.dialCode) {
            setPhoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
        } else {
            setPhoneNumberValue(val);
        }
    };

    const handleNextStep = () => {
        if (validPhoneNumber(phoneNumber)) {
            sendLog(EventLogs.TRANSFER_DISCOUNT_DETAILS_SUBMIT);
            onNextStep(selectedDiscount, phoneNumberValue);
            phoneErrorRef.current.classList.add('hidden');
        } else {
            phoneErrorRef.current.classList.remove('hidden');
        }
    };

    useEffect(() => {
        let phoneNum = '+' + phoneNumberValue.dialCode + phoneNumberValue.phone;
        setPhoneNumber(phoneNum);
    }, [phoneNumberValue, setPhoneNumber]);

    return (
        <div className="p-4">
            <h2 className="text-center font-bold text-xl mb-2.5">
                {localizations.title}
            </h2>

            <div className="flex flex-col items-center">
                {/* Telefon Numarası */}
                <div className="w-full max-w-[330px] mb-2.5">
                    <label className="block text-start text-base-text-light mb-0.5 font-bold">
                        {localizations.phone_number}
                    </label>
                    <ReactIntlTelInput 
                        className="keypress input-area w-full"
                        inputProps={inputProps}
                        intlTelOpts={{ preferredCountries: ['tr', 'az', 'de'] }}
                        value={phoneNumberValue}
                        onChange={phoneNumberHandle}
                    />
                    <p ref={phoneErrorRef} className="text-[11px] text-[#FF0000] text-start ml-2 hidden font-bold">   
                        {localizations.phone_number_error}
                    </p>
                </div>

                {/* İndirim Oranı */}
                <div className="w-full max-w-[330px] mb-2.5">
                    <label className="block text-start text-base-text-light font-bold">
                        {localizations.transfer_discount_rate}
                    </label>
                    <Dropdown
                        selected={selectedDiscount}
                        setSelected={setSelectedDiscount}
                        data={discountRates}
                        classnames="w-full"
                        optionsClassnames="max-h-[130px]"
                    />
                </div>

                {/* Aktarma Butonu */}
                <div className="w-full max-w-[330px] mt-2.5">
                    <button
                        type="button"
                        className="button primary-button w-full"
                        onClick={handleNextStep}
                    >
                        {localizations.button}
                    </button>

                    <p className="text-[14px] text-center mt-2.5">
                        {localizations.transfer_description}
                    </p>
                </div>
            </div>
        </div>
    );
};

const ConfirmContent = ({ phoneNumber, selectedDiscount, setCurrentStep, localizations, memberId, resetStates, sendLog }) => {

    const [loading, setLoading] = useState(false);
    const [showResultContent, setShowResultContent] = useState({
        show: false,
        success: false,
        message: ""
    });

    const sendDiscount = () => {
        setLoading(true);

        TransferMoneybox({
            memberId: memberId,
            transferPhoneNumber: phoneNumber,
            transferMoneyboxRate: selectedDiscount.value
        })
            .then(result => {
                if(result.status === 200) {
                    sendLog(EventLogs.TRANSFER_DISCOUNT_APPROVE);
                    setShowResultContent({
                        show: true,
                        success: true,
                        message: localizations.success_message
                    });
                } else {
                    let tmpShowResultContent = {
                        show: true,
                        success: false
                    };

                    if(result.content === ServiceErrorMessages.MONEYBOX_TRANSFER_SAME_PERSON_ERROR) {
                        tmpShowResultContent.message = localizations.same_person_error_message;
                    } else if(result.content === ServiceErrorMessages.MONEYBOX_TRANSFER_NOT_FOUND_ERROR) {
                        tmpShowResultContent.message = localizations.not_found_error_message;
                    } else {
                        tmpShowResultContent.message = localizations.default_error_message;
                    }

                    setShowResultContent(tmpShowResultContent);
                }
            })
            .catch()
            .finally(() => setLoading(false));
    };
    
    return (
        <div className="p-4">
            {showResultContent.show && (
                <TransferResultContent 
                    isSuccess={showResultContent.success}
                    message={showResultContent.message} 
                    resetStates={resetStates}
                    closeButtonText={localizations.close_button}
                />
            )}

            {!showResultContent.show && ( 
                <>
                    <h2 className="text-center font-bold text-xl mb-6">
                        {localizations.title}
                    </h2>

                    <div className="flex flex-col items-center">
                        {/* Telefon Numarası */}
                        <div className="w-full max-w-[330px] mb-2.5">
                            <div className="flex items-center justify-between">
                                <label className="block text-start text-base-text-light font-bold">
                                    {localizations.phone_number}
                                </label>
                            </div>
                            <div className="text-[18px] font-bold mt-1">
                                {phoneNumber}
                            </div>
                        </div>

                        {/* İndirim Oranı */}
                        <div className="w-full max-w-[330px] mb-2.5">
                            <label className="block text-start text-base-text-light font-bold">
                                {localizations.transfer_discount_rate}
                            </label>
                            <div className="text-[18px] font-bold mt-1">
                                {selectedDiscount?.name}
                            </div>
                        </div>

                        {/* Onayla Butonu */}
                        <div className="w-full max-w-[330px] mt-4">
                            <button
                                type="button"
                                className={classNames("button primary-button w-full", {
                                    "pointer-events-none opacity-70": loading
                                })}
                                disabled={loading}
                                onClick={sendDiscount}
                            >
                                {loading ? localizations.processing : localizations.button}
                            </button>

                            <button 
                                type="button"
                                className="!text-primary hover:!text-secondary underline underline-offset-4 cursor-pointer w-full mt-2 mx-auto"
                                disabled={loading}
                                onClick={() => setCurrentStep(1)}
                            >
                                {localizations.edit}
                            </button>

                            <p className="text-[14px] text-center mt-4 text-[#999999]">
                                {localizations.transfer_description}
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const TransferResultContent = ({ isSuccess, message, resetStates, closeButtonText }) => {
    
    // Tik işareti lottie animasyonu ayarları
    const defaultOptions = {
        autoplay: true,
        animationData: isSuccess ? checkmark : error,
        loop: false,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="p-4">
            <div className="flex flex-col items-center gap-4">
                <Lottie 
                    animationData={defaultOptions.animationData}
                    loop={defaultOptions.loop}
                    autoplay={defaultOptions.autoplay}
                    style={{ height: 140, width: 140 }}
                />
                <h2 className="text-center mb-4">
                    {message}
                </h2>

                <button
                    type="button"       
                    className="button primary-button w-full max-w-[330px]"
                    onClick={resetStates}
                >
                    {closeButtonText}
                </button>
            </div>
        </div>
    );
};

export default DiscountTransfer;