import { fetchApi } from '../index';

export const GetBookUnitAIUseCase = async (model) => {

    const url = '/_services/book/v1/book_unit_ai_use_case/get_book_unit_ai_use_case_content/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetBookUnitAiUseCasePrompt = async (aiUseCaseId, bookUnitId, languageTitleType) => {

    const url = '/_services/book/v1/book_unit_ai_use_case/get_book_unit_ai_use_case_prompt/';

    const payload = {
        aiUseCaseId: aiUseCaseId,
        bookUnitId: bookUnitId,
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const GetBookUnitAiUseCaseByNearestLesson = async (memberId, isStorytelling = false) => {

    const url = '/_services/book/v1/book_unit_ai_use_case/get_book_unit_ai_use_case_by_nearest_lesson/';

    const payload = {
        memberId: memberId,
        isStorytelling: isStorytelling
    };

    return await fetchApi(url, payload);
}