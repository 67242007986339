import { useEffect, useState, useCallback } from "react";
import { useLocalization } from "../../../../hooks/useLocalization";
import { GetMoneyboxIntivedMembers } from "../../../../services/LearnTogether/LearnTogetherService";
import classNames from "classnames";
import { Loading } from "../../../../components";
import { MemberStatus } from "../../../../components/Constants";
import { getTimeZone } from "../../../../utils";
import { formatDateFriendly } from "../../../../utils/Date";
import Pagination from "../../../../components/Pagination";
import { ChangeOrder } from "../../../../assets/svg";

const FriendList = ({ memberId }) => {

    const strings = useLocalization();

    const timeZone = getTimeZone();
    const limit = 5;
    const MoneyboxInviteSourceType = Object.freeze({
        REFERENCE: 0,
        FAMILY: 1
    });

    const [friendList, setFriendList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nameOrder, setNameOrder] = useState(null);
    const [statusOrder, setStatusOrder] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [start, setStart] = useState(0);

    const formatDate = (date) => {
        const formattedDate = formatDateFriendly(date, timeZone, {
            today: strings.date.today,
            yesterday: strings.date.yesterday,
            daysAgo: strings.date.days_ago
        });
        
        return formattedDate.displayDate;
    }

    // Debounce fonksiyonu
    const debounce = (func, wait) => {
        let timeout;
        
        const debounced = (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };

        // Cancel fonksiyonunu ekleyelim
        debounced.cancel = () => {
            clearTimeout(timeout);
        };

        return debounced;
    };

    const getFriendList = (nameAscending, statusAscending, search = "") => {
        const model = {
            memberId: memberId,
            page: currentPage - 1,
            limit: limit,
            isNameAscendingOrder: nameAscending,
            isMemberStatusAscendingOrder: statusAscending,
            fullName: search.trim()
        }

        GetMoneyboxIntivedMembers(model)
            .then(result => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    setFriendList(resultContent.moneyboxInvitedMemberItems);
                    setTotalCount(resultContent.totalMemberCount);
                }
            })
            .catch()
            .finally(() => setLoading(false));
    };

    // Debounce edilmiş arama fonksiyonu
    const debouncedSearch = useCallback(
        debounce((searchValue) => {
            getFriendList(nameOrder, statusOrder, searchValue);
        }, 500),
        [nameOrder, statusOrder]
    );

    // Search handler
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        
        // Enter tuşuna basılmadıysa debounce'u çalıştır
        if (e.type !== 'keydown') {
            debouncedSearch(value);
        }
    };

    // Enter tuşu için yeni handler
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // Debounce'u iptal et ve direkt servise git
            debouncedSearch.cancel();
            getFriendList(nameOrder, statusOrder, searchText);
        }
    };

    const handleNameOrder = () => {
        const newNameOrder = nameOrder === null ? true : !nameOrder;
        setNameOrder(newNameOrder);
        setStatusOrder(null);
        setCurrentPage(1);
        getFriendList(newNameOrder, null, searchText);
    };

    const handleStatusOrder = () => {
        const newStatusOrder = statusOrder === null ? true : !statusOrder;
        setStatusOrder(newStatusOrder);
        setNameOrder(null);
        setCurrentPage(1);
        getFriendList(null, newStatusOrder, searchText);
    };

    // Sayfa değiştiğinde yeniden yükleme yapması için useEffect
    useEffect(() => {
        getFriendList(nameOrder, statusOrder, searchText);
    }, [currentPage]);

    return (
        <div id="word-modal-content" className="transparent-section mt-5 p-4">
            {/* Başlık */}
            <h2 className="flex items-center gap-2 text-[18px] font-bold mb-2.5 text-left">
                <span className="material-symbols-outlined text-primary">group</span>
                <span>{strings.learn_together.moneybox_transfer.friend_list.title}</span>
            </h2>

            {/* Arama Alanı */}
            <div className="relative mb-2.5">
                <span className="material-symbols-outlined absolute left-4 top-1/2 -translate-y-1/2 text-[#999]">
                    search
                </span>
                <input 
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    placeholder={strings.learn_together.moneybox_transfer.friend_list.search}
                    className="w-full pl-12 pr-4 py-3 bg-white border border-[#F5F5F5] rounded-[10px] outline-none focus:ring-2 focus:ring-primary/20 transition-all"
                />
            </div>

            {/* Tablo container */}
            <div className="overflow-x-auto -mx-4 md:mx-0">
                <div className="min-w-[500px] px-4 md:px-0">
                    {/* Tablo Başlık */}
                    <div className="flex items-center bg-primary/5 rounded-[10px] text-left mb-0.5">
                        <div 
                            className="w-[35%] flex items-center gap-0.5 p-1 md:p-3 font-bold cursor-pointer hover:bg-primary/10 rounded-l-[10px] transition-colors"
                            onClick={handleNameOrder}
                        >
                            <span className="text-[14px]">{strings.learn_together.moneybox_transfer.friend_list.table.name}</span>
                            {nameOrder === null ? (
                                <img 
                                    src={ChangeOrder} 
                                    alt="sort" 
                                    className="w-[14px] h-[14px] rotate-90 mx-0.5"
                                />
                            ) : (
                                <span className={classNames("material-symbols-outlined text-[18px] transition-transform", {
                                    "rotate-180": nameOrder === false
                                })}>
                                    expand_more
                                </span>
                            )}
                        </div>
                        <div 
                            className="w-[25%] flex items-center gap-0.5 p-1 md:p-3 font-bold cursor-pointer hover:bg-primary/10 transition-colors"
                            onClick={handleStatusOrder}
                        >
                            <span className="text-[14px]">{strings.learn_together.moneybox_transfer.friend_list.table.status}</span>
                            {statusOrder === null ? (
                                <img 
                                    src={ChangeOrder} 
                                    alt="sort" 
                                    className="w-[14px] h-[14px] rotate-90 mx-0.5"
                                />
                            ) : (
                                <span className={classNames("material-symbols-outlined text-[18px] transition-transform", {
                                    "rotate-180": statusOrder === false
                                })}>
                                    expand_more
                                </span>
                            )}
                        </div>
                        <div className="w-[20%] p-1 md:p-3 font-bold">
                            <span className="text-[14px]">{strings.learn_together.moneybox_transfer.friend_list.table.invite_date}</span>
                        </div>
                        <div className="w-[20%] p-1 md:p-3 font-bold">
                            <span className="text-[14px]">{strings.learn_together.moneybox_transfer.friend_list.table.invite_type}</span>
                        </div>
                    </div>

                    {/* Tablo İçeriği */}
                    {loading ? (
                        <div className="flex items-center justify-center w-full h-full py-5">
                            <Loading classnames="mb-7" />
                        </div>
                    ) : (
                        <>
                            {friendList.length === 0 ? (
                                <div className="flex items-center justify-center w-full h-full py-5">
                                    <p className="text-[14px]">{strings.learn_together.moneybox_transfer.friend_list.not_found}</p>
                                </div>
                            ) : (
                                <>
                                    <div>
                                        {friendList.map((friend, index) => (
                                            <div key={index} className="flex items-center text-left">
                                                <div className="w-[35%] p-1 md:p-3">
                                                    <span className="text-[12px] md:text-[14px] line-clamp-2">
                                                        {friend.fullName}
                                                    </span>
                                                </div>
                                                <div className="w-[25%] p-1 md:p-3">
                                                    <span className={classNames("inline-block px-2 py-0.5 rounded-full text-[12px] md:text-[14px] whitespace-nowrap", {
                                                        "bg-primary/10 text-primary": friend.memberStatusType === MemberStatus.Active,
                                                        "bg-secondary/10 text-secondary": friend.memberStatusType === MemberStatus.Candidate,
                                                        "bg-red/10 text-red": friend.memberStatusType === MemberStatus.Passive
                                                    })}>
                                                        {friend.memberStatusType === MemberStatus.Active 
                                                            ? strings.learn_together.moneybox_transfer.friend_list.table.student
                                                            : friend.memberStatusType === MemberStatus.Candidate
                                                                ? strings.learn_together.moneybox_transfer.friend_list.table.candidate
                                                                : strings.learn_together.moneybox_transfer.friend_list.table.inactive
                                                        }
                                                    </span>
                                                </div>
                                                <div className="w-[20%] p-1 md:p-3 text-[12px] md:text-[14px]">
                                                    {formatDate(friend.inviteDate)}
                                                </div>
                                                <div className="w-[20%] p-1 md:p-3 text-[12px] md:text-[14px] truncate">
                                                    {friend.inviteSourceType === MoneyboxInviteSourceType.REFERENCE
                                                        ? strings.learn_together.moneybox_transfer.friend_list.reference
                                                        : strings.learn_together.family_package
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Pagination */}
            <div className="flex justify-end">
                <Pagination
                    totalCount={totalCount}
                    limit={limit}
                    start={start}
                    setStart={setStart}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    loadScreen={false}
                />
            </div>
        </div>
    );
};

export default FriendList;