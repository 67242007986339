import { motion } from "framer-motion";

const TutorTitle = ({ text, section, title }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
            className="w-full max-w-[768px] mx-auto px-2 bg-white"
        >
            <div className="flex flex-col items-center justify-center text-center">
                <h1 className="text-[20px] font-bold text-base-text">
                    {text.replace("#content#", `${section}: ${title}`)}
                </h1>
            </div>
        </motion.div>
    );
};

const TutorQuestion = ({ text, questionNumber, totalQuestions}) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
            className="w-full max-w-[768px] mx-auto bg-white !mt-2"
        >
            <div className="flex flex-col items-center justify-center text-center">
                <div className="flex items-center w-full max-w-md">
                    <div className="flex-1 h-[1px] bg-gradient-to-r from-transparent to-gray-500"></div>
                    <div className="px-4 text-sm text-gray-500">
                        {text.replace("#content#", `${questionNumber}/${totalQuestions}`)}
                    </div>
                    <div className="flex-1 h-[1px] bg-gradient-to-r from-gray-500 to-transparent"></div>
                </div>
            </div>
        </motion.div>
    );
};

export { TutorQuestion, TutorTitle };