import React, { useEffect, useRef, useState } from 'react'
import { AddPerson } from '../../../assets/svg'
import useAuth from '../../../hooks/useAuth';
import { useLocalization } from '../../../hooks/useLocalization'
import useAnalytics from "../../../hooks/useAnalytics";
import { EventLogs, ServiceErrorMessages } from "../../../components/Constants";
import { AddMemberWithPhone } from '../components';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { ModalContent, ModalOverlay } from '../../../components/Modals'
import useWindowSize from '../../../hooks/useWindowSize'
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { validPhoneNumber } from '../../../services/Auth/AuthValidation';
import { closeModal, openModal } from '../../../utils';
import { SendAttendFamilyRequest } from '../../../services/LearnTogether/LearnTogetherService';
import Lottie from 'lottie-react';
import checkmark from "../../../assets/animation/checkmark.json";
import error from "../../../assets/animation/error.json";
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

const AddFamilyMember = () => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const { member } = useAuth();
  const location = useLocation();
  const showSendRequestButton = location.state?.showSendRequestButton;

  const copiedRef = useRef();

  const FAMILY_PACKAGE_URL = process.env.REACT_APP_SITE_BASE_URL + '/mobile/joinfamily/?utm_content=family&utm_medium=family&familyCode=' + member.FamilyCode;

  const [showFamilyRequestModal, setShowFamilyRequestModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('+');
  const modalOverlayRef = useRef();
  const modalRef = useRef();
  const windowSize = useWindowSize();
  const isMobile = windowSize?.width < 640;
  const [showResultContent, setShowResultContent] = useState({
    show: false,
    success: false,
    message: ""
  });

  const sendShareEvent = (source) => {
    analytics.useAnalytics(EventLogs.REFERENCE_FAMILY_SHARE_CLICK, { share_platform: source });
  };

  const shareWithWhatsapp = () => {
    const shareLink = `${process.env.REACT_APP_WHATSAPP_API_URL}?text=${encodeURIComponent(FAMILY_PACKAGE_URL)}`;
    window.open(shareLink, "_blank");
    sendShareEvent("whatsapp");
  }

  const sendMail = () => {
    const body = encodeURIComponent(FAMILY_PACKAGE_URL);

    const mailtoLink = `mailto:?&body=${body}`;
    window.location.href = mailtoLink;

    sendShareEvent("email");
  }

  const copyLink = () => {
    handleCopy(FAMILY_PACKAGE_URL);
    sendShareEvent("link-copy");
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);

    copiedRef.current.classList.remove("hidden");

    setTimeout(() => {
      copiedRef.current.classList.add("hidden");
    }, 1500);
  }

  const SOCIAL_LINKS = [
    {
      name: strings.social_button.whatsapp,
      action: shareWithWhatsapp
    },
    {
      name: strings.social_button.mail,
      action: sendMail
    },
    {
      name: strings.social_button.copy_link,
      action: copyLink
    },
  ]

  const resetStates = () => {
    setShowFamilyRequestModal(false);
    setPhoneNumber('+');
    setShowResultContent({
      show: false,
      success: false,
      message: ""
    });
    !isMobile && closeModal(modalOverlayRef, modalRef);
  };

  useEffect(() => {
    analytics.useAnalytics(EventLogs.REFERENCE_PAGE_VIEW);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const rootElement = document.getElementById('root');
      if (showFamilyRequestModal) {
        rootElement.setAttribute('inert', '');
        rootElement.removeAttribute('aria-hidden');
      } else {
        rootElement.removeAttribute('inert');
      }

      return () => {
        rootElement.removeAttribute('inert');
      };
    }
  }, [showFamilyRequestModal]);

  return (
    <div className="main-content">
      <div className="mx-auto text-center mt-7 px-4">
        <img
          className="inline-block"
          src={AddPerson}
          alt=""
          width="128"
          height="128"
        />
        <p className="font-bold mt-7 text-primary">{strings.learn_together.family_package}</p>
        <p className="mt-2">{strings.learn_together.family_package_description}</p>

        <div className="bg-white rounded-2xl p-5 mt-7 shadow-md">
          <div className="flex items-center mb-4">
            <div className="w-10 h-10 bg-[#EBF5FF] rounded-full flex items-center justify-center mr-3">
              <span className="text-xl">💰</span>
            </div>
            <p className="flex-1 text-left">{strings.learn_together.family_member_message}</p>
          </div>

          <div className="flex items-center mb-4">
            <div className="w-10 h-10 bg-[#EBF5FF] rounded-full flex items-center justify-center mr-3">
              <span className="text-xl">🎁</span>
            </div>
            <p className="flex-1 text-left">{strings.learn_together.family_manager_message}</p>
          </div>

          <div className="flex items-center">
            <div className="w-10 h-10 bg-[#EBF5FF] rounded-full flex items-center justify-center mr-3">
              <span className="text-xl">👥</span>
            </div>
            <p className="flex-1 text-left">{strings.learn_together.family_learn_together}</p>
          </div>
        </div>
      </div>

      <div className="transparent-section mt-7">
        <p className="text-start">{strings.video_practice.word_card.share_text}</p>
        <div className="flex flex-col gap-3 my-4">
          {SOCIAL_LINKS.map((social, index) => (
            <div key={index} className="relative group">
              {social.name === strings.social_button.copy_link && (
                <div ref={copiedRef} className="tooltip-top-content hidden -top-[34px] duration-500">{strings.reference.copied}</div>
              )}
              <button
                className="button primary-button max-w-[330px] mx-auto w-full"
                onClick={() => {
                  social.action();
                }}
              >
                {social.name}

              </button>
            </div>
          ))}
        </div>
      </div>

      <AddMemberWithPhone
        memberId={member?.MemberId}
        source="family"
      />

      {showSendRequestButton && (
        <div className="transparent-section mt-7">
          <p className="text-center py-4">{strings.learn_together.family_attend_family_request_message}</p>
          <div className="flex flex-col gap-3 my-4">
            <button
              className="button primary-button max-w-[330px] mx-auto w-full"
              onClick={() => {
                setShowFamilyRequestModal(true);
                if (!isMobile) {
                  openModal(modalOverlayRef, modalRef);
                }
              }}
            >
              {strings.learn_together.send_attend_family_request}
            </button>
          </div>
        </div>
      )}

      <div className="left-margin-bottom"></div>

      <ModalOverlay ref={modalOverlayRef} />
      {isMobile ? (
        <BottomSheet
          open={showFamilyRequestModal}
          onDismiss={resetStates}
          className="mobile-view"
          snapPoints={({ maxHeight }) => {
            if (windowSize.height <= 667) {
              return [showResultContent.show ? Math.min(500, maxHeight * 0.8) : Math.min(350, maxHeight * 0.7)];
            }
            return [showResultContent.show ? Math.min(500, maxHeight * 0.6) : Math.min(350, maxHeight * 0.5)];
          }}
          blocking={true}
          defaultSnap={({ maxHeight }) => {
            if (windowSize.height <= 667) {
              return showResultContent.show ? Math.min(500, maxHeight * 0.8) : Math.min(350, maxHeight * 0.7);
            }
            return showResultContent.show ? Math.min(500, maxHeight * 0.6) : Math.min(350, maxHeight * 0.5);
          }}
        >
          <FamilyRequestContent
            memberId={member?.MemberId}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            showResultContent={showResultContent}
            setShowResultContent={setShowResultContent}
            onClose={resetStates}
          />
        </BottomSheet>
      ) : (
        <ModalContent
          ref={modalRef}
          show={showFamilyRequestModal}
          overlayRef={modalOverlayRef}
          showCloseButton={true}
          closeAction={resetStates}
          className="sm:w-[500px] sm:h-auto"
          aria-modal="true"
        >
          <FamilyRequestContent
            memberId={member?.MemberId}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            showResultContent={showResultContent}
            setShowResultContent={setShowResultContent}
            onClose={resetStates}
          />
        </ModalContent>
      )}
    </div>
  )
}

const FamilyRequestContent = ({
  memberId,
  phoneNumber,
  setPhoneNumber,
  showResultContent,
  setShowResultContent,
  onClose
}) => {
  const strings = useLocalization();
  const phoneErrorRef = useRef();
  const [loading, setLoading] = useState(false);

  const [phoneNumberValue, setPhoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
  let inputProps = { placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : strings.auth.form.enter_your_phone_number };

  const phoneNumberHandle = val => {
    if (val.phone.startsWith('0')) {
      val.phone = val.phone.slice(1);
    }
    if (val.dialCode !== phoneNumberValue.dialCode) {
      setPhoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
    } else {
      setPhoneNumberValue(val);
    }
  };

  const handleSubmit = () => {
    if (validPhoneNumber(phoneNumber)) {
      setLoading(true);
      phoneErrorRef.current.classList.add('hidden');

      SendAttendFamilyRequest({
        memberId: memberId,
        brandId: process.env.REACT_APP_BRAND_ID,
        managerPhoneNumber: phoneNumber
      })
        .then(result => {
          let success = result.status === 200;
          let message = "";

          if (success) {
            message = strings.learn_together.send_attend_family_request_success_message;
          } else {
            if (result.content === ServiceErrorMessages.SEND_ATTEND_FAMILY_REQUEST_MANAGER_NOT_FOUND)
              message = strings.learn_together.send_attend_family_request_manager_not_found_error;
            else if (result.content === ServiceErrorMessages.SEND_ATTEND_FAMILY_REQUEST_ALREADY_INVITED)
              message = strings.learn_together.send_attend_family_request_already_invited_error;
            else if (result.content === ServiceErrorMessages.SEND_ATTEND_FAMILY_REQUEST_ALREADY_SENT)
              message = strings.learn_together.send_attend_family_request_already_sent_error;
            else if (result.content === ServiceErrorMessages.SEND_ATTEND_FAMILY_REQUEST_QUOTA_FULL)
              message = strings.learn_together.decide_attend_family_request_quota_full;
            else if(result.content === ServiceErrorMessages.MEMBER_IS_ALREADY_INCLUDED_ANOTHER_FAMILY)
              message = strings.learn_together.member_is_already_included_another_family_error;
            else
              message = strings.general_information_messages.an_error_occured;
          }

          setShowResultContent({
            show: true,
            success: success,
            message: message
          });
        })
        .catch(() => {
          setShowResultContent({
            show: true,
            success: false,
            message: strings.general_information_messages.an_error_occured
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      phoneErrorRef.current.classList.remove('hidden');
    }
  };

  useEffect(() => {
    let phoneNum = '+' + phoneNumberValue.dialCode + phoneNumberValue.phone;
    setPhoneNumber(phoneNum);
  }, [phoneNumberValue]);

  if (showResultContent.show) {
    return (
      <div className="p-4">
        <div className="flex flex-col items-center gap-4">
          <Lottie
            animationData={showResultContent.success ? checkmark : error}
            loop={false}
            autoplay={true}
            style={{ height: 140, width: 140 }}
          />
          <h2 className="text-center mb-4 whitespace-pre-line">
            {showResultContent.message}
          </h2>

          <button
            type="button"
            className="button primary-button w-full max-w-[330px]"
            onClick={onClose}
          >
            {strings.modal.okey}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h2 className="text-center font-bold text-xl mb-2">
        {strings.learn_together.send_attend_family_request}
      </h2>
      <div className="flex flex-col items-center mt-4">
        <div className="w-full max-w-[330px] mb-2.5">
          <label className="block text-start text-base-text-light font-bold pb-1.5">
            {strings.mentor.phone}
          </label>
          <ReactIntlTelInput
            className="keypress input-area w-full"
            inputProps={inputProps}
            intlTelOpts={{ preferredCountries: ['tr', 'az', 'de'] }}
            value={phoneNumberValue}
            onChange={phoneNumberHandle}
          />
          <p ref={phoneErrorRef} className="text-[11px] text-[#FF0000] text-start ml-2 hidden font-bold">
            {strings.auth.validation_messages.invalid_phone}
          </p>
        </div>

        <div className="w-full max-w-[330px] mt-2">
          <button
            type="button"
            className={classNames("button primary-button w-full", {
              "pointer-events-none opacity-70": loading
            })}
            onClick={handleSubmit}
          >
            {loading ? strings.general_information_messages.processing : strings.auth.form.send_button}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFamilyMember