import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { Recording } from "../../../../../assets/svg";
import { EventLogs } from "../../../../../components/Constants";
import { useLocalization } from "../../../../../hooks/useLocalization";

const RecordButton = ({ 
    onRecordingStateChange, 
    isMicrophoneDisabled = false, 
    isAnimateIcon = false, 
    stopCurrentAudio,
    logAction = null
}) => {

    const strings = useLocalization();
    const [isRecording, setIsRecording] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const wave1Controls = useAnimation();
    const wave2Controls = useAnimation();
    const wave3Controls = useAnimation();

    const pulseVariants = {
        pulse: {
            scale: [1],
            transition: {
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
            }
        }
    };

    const createWaveAnimation = async (control) => {
        const animate = async () => {
            if (!isRecording) return;

            await control.start({
                scale: [1, 1.6],
                opacity: [0.6, 0],
                transition: {
                    duration: 2.8,
                    times: [0, 1],
                    ease: [0.4, 0, 0.2, 1],
                    repeat: Infinity,
                    repeatType: "loop",
                    repeatDelay: -0.8
                }
            });
        };

        animate();
    };

    const voiceLineVariants = (index) => ({
        initial: { height: "20%" },
        animate: {
            height: ["20%", "80%", "20%"],
            transition: {
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
                delay: index * 0.2,
                repeatType: "loop",
                times: [0, 0.5, 1]
            }
        }
    });

    const handlePress = () => {
        if (isMicrophoneDisabled) return;
        
        stopCurrentAudio?.();
        setIsRecording(!isRecording);
        onRecordingStateChange?.(!isRecording);
        logAction?.(EventLogs.CONVERSATIONAL_AI_RECORDING_BUTTON_CLICKED);
    };

    useEffect(() => {
        if (!isMicrophoneDisabled && !isRecording) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [isMicrophoneDisabled, isRecording]);

    useEffect(() => {
        let timeouts = [];

        if (isRecording) {
            createWaveAnimation(wave1Controls);
            timeouts.push(setTimeout(() => createWaveAnimation(wave2Controls), 600));
            timeouts.push(setTimeout(() => createWaveAnimation(wave3Controls), 1200));
        } else {
            [wave1Controls, wave2Controls, wave3Controls].forEach(control => {
                control.stop();
                control.set({ scale: 1, opacity: 0.5 });
            });
            
            timeouts.forEach(timeout => clearTimeout(timeout));
        }

        return () => {
            timeouts.forEach(timeout => clearTimeout(timeout));
            [wave1Controls, wave2Controls, wave3Controls].forEach(control => {
                control.stop();
            });
        };
    }, [isRecording]);

    return (
        <div className="fixed bottom-5 left-0 right-0 flex justify-center items-center">
            <div className="relative group">
                <AnimatePresence>
                    {showTooltip && (
                        <div className="tooltip-top -top-[38px] block">
                            {strings.speaking_lesson.conversational_ai.click_for_speak}
                        </div>
                    )}
                </AnimatePresence>

                <div className="relative">
                    {isRecording && (
                        <>
                            {[wave1Controls, wave2Controls, wave3Controls].map((control, index) => (
                                <motion.div
                                    key={`wave-${index}`}
                                    animate={control}
                                    initial={{ scale: 1, opacity: 0.6 }}
                                    className="absolute inset-0 bg-primary rounded-full will-change-transform"
                                    style={{
                                        transformOrigin: 'center center'
                                    }}
                                />
                            ))}
                        </>
                    )}

                    {/* Ana buton */}
                    <motion.button
                        variants={pulseVariants}
                        animate={isAnimateIcon && !isRecording ? "pulse" : ""}
                        disabled={isMicrophoneDisabled}
                        onClick={handlePress}
                        className={`w-20 h-20 bg-primary rounded-full flex items-center justify-center z-10 relative ${
                            isMicrophoneDisabled ? "opacity-50" : ""
                        }`}
                    >
                        {isRecording ? (
                            <div className="flex items-center justify-center gap-1.5 h-8">
                                {[0, 1, 2].map((i) => (
                                    <motion.div
                                        key={`line-${i}`}
                                        custom={i}
                                        variants={voiceLineVariants(i)}
                                        initial="initial"
                                        animate="animate"
                                        className="w-1 bg-white rounded-full"
                                    />
                                ))}
                            </div>
                        ) : (
                            <img src={Recording} className="w-10 h-10" />
                        )}
                    </motion.button>
                </div>
            </div>
        </div>
    );
};

export default RecordButton;