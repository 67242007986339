import React from "react";
import { useNavigate } from "react-router-dom";
import { QuizTest } from "../../../../../../assets/svg";
import { Accordion } from "../../../../../../components";
import { LessonResultAccordionType } from "../../../../../../components/Constants";
import useAccordionActivity from "../../../../../../hooks/useAccordionActivity";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import { url } from "../../../../../../routes/utility";

const QuizAndTest = (props) => {

    const { bookUnitId, selectedDate, lessonResultId, lessonResultAccordionActivities } = props;
    const strings = useLocalization();
    const navigate = useNavigate();
    const { activities, addAccordionActivity } = useAccordionActivity(lessonResultId, lessonResultAccordionActivities);

    const handleRouteQuiz = () => {
        navigate(
            url("speakinglesson.selectedquiz",
                { bookUnitId: bookUnitId }),
            { state: { date: selectedDate }}
        );
    }

    return (
        <Accordion
            title={strings.speaking_lesson.daily_lesson.lesson_study}
            icon={QuizTest}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            onClick={handleRouteQuiz}
            openCloseCheckboxStatus={
                activities
                    ?.find(l => LessonResultAccordionType.AFTER_LESSON_ACTIVITIES === l.lessonResultAccordionType)?.isOpened
                || false
            }
            action={() => addAccordionActivity(LessonResultAccordionType.AFTER_LESSON_ACTIVITIES)}
        />
    )
}

export default QuizAndTest;