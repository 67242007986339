import {useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import classNames from "classnames";
import {Arrow_Down} from "../assets/svg";
import OutsideClickHandler from "react-outside-click-handler";

const DropdownMultiselect = (props) => {

    const { state, setState, data, normalTitle, selectedTitle, selectedDataCount, className } = props;
    const [isOpen, setIsOpen] = useState(false);

    const isState = (value) => {
        return state.find((el) => el.id === value.id);
    }

    const handleSelect = (value) => {
        if (!isState(value)) {
            const statePersonsUpdated = [
                ...state,
                data.find((el) => el.id === value.id)
            ];
            setState(statePersonsUpdated);
        } else {
            handleDeselect(value);
        }
        setIsOpen(true);
    }

    const handleDeselect = (value) => {
        const statePersonsUpdated = state.filter((el) => el.id !== value.id);
        setState(statePersonsUpdated);
        setIsOpen(true);
    }

    const handleClose = () => {
        if (isOpen) {
            setIsOpen(isOpen => false);
        }
    }

    return (
        <OutsideClickHandler onOutsideClick={handleClose}>
            <div className={`min-w-[348px] lg-xl:min-w-[270px] max-w-max ${className || ""}`}>
                <Listbox
                    as="div"
                    className="space-y-1"
                    value={state}
                    onChange={(value) => handleSelect(value)}
                    open={isOpen}
                >
                    <div className="relative">
                        <span className="inline-block w-full rounded-[10px] shadow-sm">
                          <Listbox.Button
                              className="cursor-default relative w-full rounded-[10px] border h-[50px] bg-white pr-3 py-2 text-start pl-2 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                              onClick={() => setIsOpen(!isOpen)}
                              open={isOpen}
                          >
                            <span className="block truncate">
                                {selectedDataCount === 0 && normalTitle}
                                {selectedDataCount > 0 && selectedTitle
                                    .replace("#count#", selectedDataCount)
                                }
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <img src={Arrow_Down} className="" alt="arrow_down"/>
                            </span>
                          </Listbox.Button>
                        </span>
                        <Transition
                            unmount={false}
                            show={isOpen}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            className="absolute mt-1 w-full rounded-[10px] bg-white shadow-lg z-20"
                        >
                            <Listbox.Options
                                static
                                className="max-h-48 rounded-[10px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                            >
                                {data.map((item) => {
                                    const selected = isState(item);
                                    return (
                                        <Listbox.Option key={item.id} value={item}>
                                            {({ active }) => (
                                                <div
                                                    className="cursor-default group text-start select-none relative py-2"
                                                >
                                                    <span
                                                        className={classNames("block truncate hover:text-secondary pl-7", {
                                                            "font-medium text-secondary": selected,
                                                            "font-normal": !selected
                                                        })}
                                                    >
                                                       {item.name}
                                                    </span>
                                                    <span
                                                        className="absolute inset-y-0 left-1.5 mt-2.5"
                                                    >
                                                      <svg
                                                          className={classNames("h-4 w-4 border rounded-[3px]", {
                                                              "bg-white border-[#aaaaaa]": !selected,
                                                              "bg-secondary text-white border-secondary": selected
                                                          })}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 20 20"
                                                          fill="currentColor"
                                                      >
                                                          {selected && (
                                                              <path
                                                                  fillRule="evenodd"
                                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                  clipRule="evenodd"
                                                              />
                                                          )}
                                                      </svg>
                                                    </span>
                                                </div>
                                            )}
                                        </Listbox.Option>
                                    );
                                })}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
        </OutsideClickHandler>
    );
}

export default DropdownMultiselect;