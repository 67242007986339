import { fetchApi } from '../index';

export const GetDetailedUseCases = async () => {

    const url = '/ai/ai_use_case/detailed_list/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const UseCaseGenericService = async (url, model) => {

    const payload = model ? { payload: { ...model }} : {};

    return await fetchApi(url, payload);
}

export const GetAiUseCaseIdByKey = async (aiUseCaseKey) => {
    const url = '/_services/ai/v1/ai_use_case/get_ai_use_case_id_by_key/\n';

    const payload = {
        aiUseCaseKey: aiUseCaseKey
    };

    return await fetchApi(url, payload);
}

export const GetAiUseCaseById = async (aiUseCaseId) => {

    const url = '/_services/ai/v1/ai_use_case/get_by_id/';

    const payload = {
        aiUseCaseId: aiUseCaseId
    };

    return await fetchApi(url, payload);
}

export const GetAiPromptByType = async (type) => {

    const url = '/_services/ai/v1/ai_use_case/get_ai_prompt_by_type/';

    const payload = {
        type: type
    };

    return await fetchApi(url, payload);
}