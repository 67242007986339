import React, { useEffect, useState } from "react";
import { useLocalization } from "../../hooks/useLocalization";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDateFriendly, getTimeZone } from "../../utils";
import { Loading } from "../../components";
import NoRecordsFound from "../../components/NoRecordsFound";
import AIChatBubble from "../SpeakingLesson/components/IntelligentChat/components/AIChatBubble";
import UserChatBubble from "../SpeakingLesson/components/IntelligentChat/components/UserChatBubble";
import { GetMemberAiMessageDetail, SetMemberReadContent, SaveMemberAiMessageAnswer } from "../../services/Messages/MessagesService";
import { Button } from "../../components/Buttons";
import { StaticAiMessageType } from "../../components/Constants";
import { useDispatch, useSelector } from 'react-redux';
import { setUnreadCount } from '../../store/MessageSlice';

const MessagesDetail = () => {
    const navigate = useNavigate();
    const strings = useLocalization();
    const { memberAiMessageId } = useParams();
    const location = useLocation();
    const { isRead } = location.state || {};
    const timeZone = getTimeZone();
    const dispatch = useDispatch();
    const currentUnreadCount = useSelector(state => state.messageStore.unreadCount);

    const [messageDetail, setMessageDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isMarkingRead, setIsMarkingRead] = useState(false);

    const formatDate = (date) => {
        const formattedDate = formatDateFriendly(date, timeZone, {
            today: strings.date.today,
            yesterday: strings.date.yesterday,
            daysAgo: strings.date.days_ago
        });
        return formattedDate.displayDate;
    };

    // Cevap metnini dile göre görüntülemek için yardımcı fonksiyon
    const getLocalizedAnswerContent = (answer) => {
        if (answer === "İlgileniyorum") {
            return strings.messages.interested;
        } else if (answer === "İlgilenmiyorum") {
            return strings.messages.not_interested;
        }
        return answer;
    };

    // Öğrenciye gönderilen mesajların detayını getiren servis
    useEffect(() => {
        if (memberAiMessageId) {
            setLoading(true);
            GetMemberAiMessageDetail(memberAiMessageId)
                .then((result) => {
                    if (result.status === 200)
                        setMessageDetail(JSON.parse(result.content));
                })
                .catch()
                .finally(() => setLoading(false));
        }
    }, []);

    // Öğrenciye gönderilen mesajı okundu yapan servis
    useEffect(() => {
        if (messageDetail && !isRead && !messageDetail.answerContent) {
            setIsMarkingRead(true);
            SetMemberReadContent(memberAiMessageId)
                .then((result) => {
                    if (result.status === 200) {
                        if (currentUnreadCount > 0) {
                            dispatch(setUnreadCount(currentUnreadCount - 1));
                        }
                    }
                })
                .catch()
                .finally(() => setIsMarkingRead(false));
        }
    }, [messageDetail]);

    // Sabit mesajlardan seçilen cevabı kaydeden servis
    const saveMemberAiMessageAnswer = async (staticAiMessageType) => {
        if (!messageDetail || messageDetail.answerContent || isSaving) return;

        const answerContent = staticAiMessageType === StaticAiMessageType.INTERESTED
            ? "İlgileniyorum"
            : "İlgilenmiyorum";

        let model = {
            answerContent,
            memberAiMessageId
        };

        setIsSaving(true);

        SaveMemberAiMessageAnswer(model)
            .then((result) => {
                if (result.status === 200) {
                    const updatedMessageDetail = {
                        ...messageDetail,
                        answerContent: answerContent,
                        answeredDate: new Date().toISOString()
                    };
                    setMessageDetail(updatedMessageDetail);
                }
            })
            .catch()
            .finally(() => {
                setIsSaving(false);
            });
    }

    return (
        <div className="main-content px-4 py-5">
            {loading && !isSaving && !isMarkingRead && (
                <div className="mx-auto">
                    <Loading />
                </div>
            )}
            {(!loading || isSaving || isMarkingRead) && (
                <>
                    <button
                        className="ml-auto mr-2 border border-onboarding-border hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:text-base gap-2 h-8 max-w-max px-3 flex flex-row items-center justify-center rounded-[10px]"
                        onClick={() => navigate(-1)}
                    >
                        <span className="material-symbols-outlined">keyboard_return</span>
                        {strings.ai_chatbot.back}
                    </button>
                    {messageDetail ? (
                        <div className="flex flex-col h-full">
                            <div className="flex-1 overflow-y-auto space-y-4 mt-4">
                                <AIChatBubble
                                    content={messageDetail.messageContent}
                                    isLoading={false}
                                    isReadOnly={true}
                                    audio={null}
                                    ttsVoice={null}
                                    speakSentence={() => { }}
                                    stopTts={() => { }}
                                    onSpeakComplete={() => { }}
                                    onSpeakingStateChange={() => { }}
                                    sendLog={() => { }}
                                    timestamp={formatDate(messageDetail.messageDate)}
                                />

                                {messageDetail.answerContent && (
                                    <UserChatBubble
                                        content={getLocalizedAnswerContent(messageDetail.answerContent)}
                                        isReadOnly={true}
                                        isLoading={false}
                                        messageId={memberAiMessageId}
                                        autoCorrect={false}
                                        isSessionCompleted={false}
                                        isRead={messageDetail.employeeReadDate}
                                        timestamp={formatDate(messageDetail.answeredDate)}
                                    />
                                )}
                                {/* Response Buttons */}
                                {!messageDetail.answerContent && messageDetail?.staticAiMessageTypes?.length > 0 && (
                                    <div className="fixed bottom-10 md:bottom-0 left-0 md-lg:w-[70%] lg:w-[80%] w-full">
                                        <div className="w-full max-w-2xl mx-auto mb-10">
                                            <div className="bg-white p-4">
                                                <div className="flex justify-center items-center gap-4">
                                                    {messageDetail.staticAiMessageTypes.map((staticAiMessageType) => (
                                                        <div key={staticAiMessageType} className="btn-outer-div">
                                                            <Button
                                                                text={staticAiMessageType === StaticAiMessageType.INTERESTED
                                                                    ? strings.messages.interested
                                                                    : strings.messages.not_interested
                                                                }
                                                                action={() => saveMemberAiMessageAnswer(staticAiMessageType)}
                                                                classnames={`button primary-button w-full ${staticAiMessageType === StaticAiMessageType.INTERESTED ? "primary-button" : "primary-button-outline"}`}
                                                                disabled={isSaving}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <NoRecordsFound />
                    )}
                </>
            )}
            <div className="left-margin-bottom"></div>
        </div>
    );
};

export default MessagesDetail;