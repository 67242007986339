import React, {useCallback, useRef, useState} from "react";
import {ModalOneButton, ModalOverlay} from "../components";
import {SpeakWordWithTts} from "../services/SpeakingLesson/SpeakingLessonService";
import {useLocalization} from "./useLocalization";
import {openModal, closeModal} from "../utils";

const useSpeaker = (action = null) => {

    const strings = useLocalization();

    const modalRef = useRef();
    const audioRef = useRef();
    const modalOverlayRef = useRef();
    const [isSpeakingComplete, setIsSpeakingComplete] = useState(false);

    // Ses kaydını durdurmak için kullanılır
    const stopSpeaking = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsSpeakingComplete(true);
        }
        window.speechSynthesis.cancel();
    }, []);

    const speakSentence = useCallback(async (sentence, voice = "") => {
        setIsSpeakingComplete(false);
        const result = await SpeakWordWithTts(sentence, voice);

        if (result.statusCode === 200) {
            audioRef.current.src = result.message.audio;
            action?.(result.message.voice);

            audioRef.current.onended = () => {
                setIsSpeakingComplete(true);
            };

            audioRef.current.play();
        } else {
            openModal(modalOverlayRef, modalRef);
        }

        return new Promise((resolve) => {
            const checkCompletion = () => {
                if (isSpeakingComplete) {
                    resolve(true);
                } else {
                    setTimeout(checkCompletion, 100); // Check every 100ms
                }
            };
            checkCompletion();
        });
    }, []);

    const SpeakerComponent = () => {
        return (
            <>
                {/* Sesin çalması için gerekli olan kısım */}
                <audio ref={audioRef} id="audioPlayer" controls={false} autoPlay={false} className="hidden">
                    <source type="audio/mp3" />
                </audio>
                <ModalOverlay ref={modalOverlayRef} />
                <ModalOneButton
                    ref={modalRef}
                    overlayRef={modalOverlayRef}
                    title={strings.auth.information_messages.modal_title}
                    message={strings.menu.callbar.an_error_occured}
                    buttonText={strings.auth.form.okay_button}
                    buttonClick={() => closeModal(modalOverlayRef, modalRef)}
                />
            </>
        )
    }

    return {
        speakSentence,
        SpeakerComponent,
        isSpeakingComplete,
        stopSpeaking
    }
}

export default useSpeaker;