import { configureStore } from '@reduxjs/toolkit'
import { LocalizationSlice } from './LocalizationSlice'
import { MemberSlice } from './MemberSlice'
import { TermSlice } from './TermSlice'
import { AuthSlice } from './AuthSlice'
import { AgoraSlice } from './AgoraSlice'
import {ExerciseReportSlice} from "./ExerciseReportSlice";
import {ComponentRenderSlice} from "./ComponentRenderSlice";
import {SignalrHubSlice} from "./SignalrHubSlice";
import {MessageSlice} from './MessageSlice';

const store = configureStore({
  reducer: {
    localizationStore: LocalizationSlice.reducer,
    memberStore: MemberSlice.reducer,
    termStore: TermSlice.reducer,
    authStore: AuthSlice.reducer,
    agoraStore: AgoraSlice.reducer,
    exerciseReportStore: ExerciseReportSlice.reducer,
    componentRenderStore: ComponentRenderSlice.reducer,
    signalrHubStore: SignalrHubSlice.reducer,
    messageStore: MessageSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store