import { Outlet } from "react-router-dom";
import { Menu, SideBar } from "../../components";
import { useLocalization } from "../../hooks/useLocalization";

const CorporateCustomerLayout = () => {

    const strings = useLocalization();

    return (
        <>
            <Menu title={strings.corporate_customer.title} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>
        </>
    )
};

export default CorporateCustomerLayout;