import {fetchApi} from '../index';

export const GetMemberAiMessages = async (memberId) => {

    const url = '/_services/member/v1/member_ai_message/member_ai_messages/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
};

export const SetMemberReadContent = async (memberAiMessageId) => {
    const url = '/_services/member/v1/member_ai_message/set_member_read_content/';

    const payload = {
        memberAiMessageId: memberAiMessageId
    };

    return await fetchApi(url, payload);
};

export const GetMemberAiMessageDetail = async (memberAiMessageId) => {
    const url =  '/_services/member/v1/member_ai_message/member_ai_message_detail/';

    const payload = {
        memberAiMessageId: memberAiMessageId
    };

    return await fetchApi(url, payload);
};

export const SaveMemberAiMessageAnswer = async (model) => {
    const url = '/_services/member/v1/member_ai_message/save_member_ai_message_answer/';

    console.log("model", model);
    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
};