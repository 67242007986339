import React from 'react';
import { useLocalization } from '../../../hooks/useLocalization';

const HeroSection = () => {
  const strings = useLocalization();

  return (
    <section className="py-1 px-4">
      <div className="text-center">
        <h2 className="text-[20px] font-bold text-primary">
          {strings.corporate_customer.hero.title}
        </h2>
        <p className="text-[18px] mt-3">
          {strings.corporate_customer.hero.description}
        </p>
      </div>
    </section>
  );
};

export default HeroSection; 