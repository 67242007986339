import { fetchApi } from '../index';

export const CorporateCustomerSubmit = async (model) => {

    const url = '/_services/corporate/v1/corporate_submit/submit/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetCompanyLogos = async () => {

    const url = '/_services/corporate/v1/corporate_submit/get_corporate_company_logos/';

    const payload = {};

    return await fetchApi(url, payload);
}