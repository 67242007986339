import { motion } from 'framer-motion';
import { Loading } from '../../../../components';
import { useLocalization } from "../../../../hooks/useLocalization";

const MoneyboxDiscountFollow = ({ moneyboxRateDetails, loading }) => {

    const strings = useLocalization();

    return (
        <div className="bg-white rounded-2xl border-2 border-blue-100 shadow-md p-4 mt-5">
            <h2 className="flex items-center text-[18px] gap-1 font-bold mb-2.5">
                <span className="text-primary">%</span>
                <span>{strings.learn_together.moneybox_transfer.moneybox_discount_follow.title}</span>
            </h2>

            {loading ? (
                <div className="flex items-center justify-center w-full h-full">
                    <Loading classnames="mb-7" />
                </div>
            ) : (
                <>
                    {/* Toplam Kazanılan İndirim */}
                    <div className="flex items-center justify-between w-full p-4 bg-primary/10 rounded-[10px] mb-2.5">
                        <span>{strings.learn_together.moneybox_transfer.moneybox_discount_follow.total_discount_rate}</span>
                        <span className="text-[24px] font-bold text-primary">{moneyboxRateDetails?.totalMoneybox}%</span>
                    </div>

                    {/* İndirim Detayları */}
                    <div className="grid grid-cols-2 gap-2.5 mb-2.5">
                        <div className="flex flex-col md:flex-row md:justify-between px-4 py-3 md:py-[22px] bg-secondary/10 rounded-[10px]">
                            <span className="text-[14px] md:text-base mb-0.5 md:mb-0">{strings.learn_together.moneybox_transfer.moneybox_discount_follow.used_discount}</span>
                            <span className="text-[22px] md:text-[24px] font-bold text-secondary leading-none">{moneyboxRateDetails?.usedMoneyboxRate}%</span>
                        </div>
                        <div className="flex flex-col md:flex-row md:justify-between px-4 py-3 md:py-[22px] bg-primary/10 rounded-[10px]">
                            <span className="text-[14px] md:text-base mb-0.5 md:mb-0">{strings.learn_together.moneybox_transfer.moneybox_discount_follow.remaining_discount}</span>
                            <span className="text-[22px] md:text-[24px] font-bold text-primary leading-none">{moneyboxRateDetails?.activeMoneyboxRate}%</span>
                        </div>
                    </div>

                    {/* Progress Bar */}
                    {moneyboxRateDetails?.totalMoneybox > 0 && 
                        <>
                            <div className="relative w-full h-2 bg-box rounded-full overflow-hidden">
                                <motion.div 
                                    className="absolute left-0 top-0 h-full bg-secondary rounded-full"
                                    initial={{ width: "0%" }}
                                    animate={{ width: `${(moneyboxRateDetails?.usedMoneyboxRate / moneyboxRateDetails?.totalMoneybox) * 100}%` }}
                                    transition={{ 
                                        duration: 1.5,
                                        ease: "easeOut",
                                        delay: 0.3
                                    }}
                                />
                            </div>
                            <div className="flex justify-between mt-2">
                                <span>%0</span>
                                <span>%{moneyboxRateDetails?.totalMoneybox}</span>
                            </div>
                        </>
                    }
                </>
            )}
        </div>
    );
};

export default MoneyboxDiscountFollow;