import { fetchApi } from '../index';

export const CheckMemberDemoRight = async (memberId) => {
    const url = "/_services/member/v1/member_demo_right/check_member_demo_right/";

    const payload = {
        memberId: memberId
    }

    return await fetchApi(url, payload);
};

export const TeachersFreeTimesForMemberDemo = async (model) => {
    const url = "/_services/member/v1/support/teachers_free_times_for_member_demo/";

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
};

export const AddDemoTerm = async (model) => {
    const url = "/_services/member/v1/member_demo_right/add_demo_term/";

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
};

export const GetMemberLevelCollectionByMemberId = async (memberId) => {
    const url = '/_services/member/v1/member_level_collection/get_member_level_collection_by_member_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateMemberDemoUnit = async (model) => {
    const url = '/_services/member/v1/member_demo_right/update_demo_unit/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetDemoTeacherAsAuto = async (lessonDuration) => {
    const url = '/_services/member/v1/support/get_demo_teacher_as_auto/';

    const payload = {
        lessonDuration: lessonDuration
    };

    return await fetchApi(url, payload);
}

export const CheckIsDemoStudent = async (memberId) => {
    const url = '/_services/member/v1/member/check_is_demo_student/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}