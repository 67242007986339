import classNames from 'classnames'
import React from 'react'
import { DailyLessonType } from '../../../../components'
import { url } from '../../../../routes/utility';
import { useNavigate } from 'react-router-dom';
import { useLocalization } from '../../../../hooks/useLocalization';

const DailyLessonError = (data) => {

    const strings = useLocalization();
    const navigate = useNavigate();

    return (
        <div className="flex flex-col sm:flex-row sm:justify-between text-sm mt-4 mx-auto max-w-lg">
            <div className={classNames("",{
                "sm:w-7/12 sm:order-last": data.data.dailyLessonType !== DailyLessonType.NoActiveTerm
            })}>
                <div className="mb-5" dangerouslySetInnerHTML={{ __html: data.data.message1 }} />
                {data.data.dailyLessonType !== DailyLessonType.NoActiveTerm && (
                    <div className="mb-5" dangerouslySetInnerHTML={{ __html: data.data.message2 }} />
                )}
                {data.data.dailyLessonType === DailyLessonType.NoActiveTerm && (
                    <div className="mb-5 btn-outer-div">
                        <button className="button primary-button" onClick={() => navigate(url('market.packagecatalog'))}>
                            {strings.teachers.teacher_details.not_student_buy_button}
                        </button>
                    </div>
                )}
            </div>
            {data.data.dailyLessonType !== DailyLessonType.NoActiveTerm && (
                <div className="sm:w-5/12 mt-5 sm:mt-0">
                    <img
                        className="mx-auto sm:mx-0"
                        src={data.data.icon}
                        width="128"
                        height="128"
                        alt=""
                    />
                </div>
            )}
        </div>
    )
}

export default DailyLessonError