import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { GetMoneyboxRateDetails } from "../../services/LearnTogether/LearnTogetherService";
import { DiscountTransfer, MoneyboxDiscountFollow, MoneyboxInfo, TransferHistory } from "./components";
import FriendList from "./components/MoneyboxTransfer/FriendList";
import useAnalytics from "../../hooks/useAnalytics";
import { EventLogs } from "../../components/Constants";

const MoneyboxTransfer = () => {

    const { member } = useAuth();
    const analytics = useAnalytics();

    const [moneyboxRateDetails, setMoneyboxRateDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [shouldRefresh, setShouldRefresh] = useState(0);

    const refreshData = () => {
        setShouldRefresh(prev => prev + 1);
    };

    useEffect(() => {
        if (member) {
            setLoading(true);
            GetMoneyboxRateDetails(member?.MemberId)
                .then(result => {
                    if(result.status === 200)
                        setMoneyboxRateDetails(JSON.parse(result.content));
                })
                .catch()
                .finally(() => setLoading(false));
        }
    }, [shouldRefresh]);

    useEffect(() => {
        analytics.useAnalytics(EventLogs.TRANSFER_DISCOUNT_SCREEN_VIEWED);
    }, []);

    return (
        <div className="main-content">
            {/* Kumbara indirimi nedir */}
            <MoneyboxInfo />

            {/* Kumbara indirim takibi */}
            <MoneyboxDiscountFollow 
                moneyboxRateDetails={moneyboxRateDetails}
                loading={loading}
            />

            {/* İndirimi Aktar Butonu */}
            <DiscountTransfer 
                activeMoneyboxRate={moneyboxRateDetails?.activeMoneyboxRate}
                memberId={member?.MemberId}
                onTransferComplete={refreshData}
            />   

            {/* Arkadaş Listesi */}
            {member && 
                <FriendList memberId={member.MemberId} />
            }

            {/* Kumbara İndirimi Aktarma Geçmişi */}
            {member && 
                <TransferHistory 
                    memberId={member.MemberId} 
                    shouldRefresh={shouldRefresh}
                />
            }

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default MoneyboxTransfer;    