import { useEffect, useState } from "react";
import { useLocalization } from "../../../../hooks/useLocalization";
import { GetTransferMoneyboxActivities } from "../../../../services/LearnTogether/LearnTogetherService";
import { Loading } from "../../../../components";
import classNames from "classnames";
import { getTimeZone } from "../../../../utils";
import { formatDateFriendly } from "../../../../utils/Date";

const TransferHistory = ({ memberId, shouldRefresh }) => {

    const strings = useLocalization();

    const timeZone = getTimeZone();

    const [transferHistory, setTransferHistory] = useState([]);
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [activeFilter, setActiveFilter] = useState('all');
    const [loading, setLoading] = useState(true);
    const [hasSentTransfers, setHasSentTransfers] = useState(false);
    const [hasReceivedTransfers, setHasReceivedTransfers] = useState(false);

    const MoneyboxActivityType = {
        INCREMENT: 0,
        DECREMENT: 1
    };

    const handleFilter = (filterType) => {
        setActiveFilter(filterType);
        
        switch(filterType) {
            case 'received':
                setFilteredHistory(transferHistory.filter(item => 
                    item.moneyBoxActivityType === MoneyboxActivityType.INCREMENT
                ));
                break;
            case 'sent':
                setFilteredHistory(transferHistory.filter(item => 
                    item.moneyBoxActivityType === MoneyboxActivityType.DECREMENT
                ));
                break;
            default:
                setFilteredHistory(transferHistory);
                break;
        }
    };

    const formatDate = (date) => {
        const formattedDate = formatDateFriendly(date, timeZone, {
            today: strings.date.today,
            yesterday: strings.date.yesterday,
            daysAgo: strings.date.days_ago
        });
        
        return formattedDate.displayDate;
    }

    useEffect(() => {
        GetTransferMoneyboxActivities(memberId)
            .then(result => {
                if(result.status === 200) {
                    const data = JSON.parse(result.content);
                    setTransferHistory(data);
                    setFilteredHistory(data);

                    setHasSentTransfers(data.some(item => item.moneyBoxActivityType === MoneyboxActivityType.DECREMENT));
                    setHasReceivedTransfers(data.some(item => item.moneyBoxActivityType === MoneyboxActivityType.INCREMENT));
                }
            })
            .catch()
            .finally(() => setLoading(false));
    }, [shouldRefresh]);

    return (
        <div className="transparent-section mt-5 p-4">
            <h2 className="flex items-center gap-2 text-[18px] font-bold mb-2.5 text-left">
                <span className="material-symbols-outlined text-primary">history</span>
                <span>{strings.learn_together.moneybox_transfer.transfer_history.title}</span>
            </h2>

            <div className="flex gap-2.5 mb-2.5">
                <button 
                    className={classNames("px-6 py-2 rounded-[10px] text-[14px]", {
                        "bg-primary text-white": activeFilter === 'all',
                        "bg-primary/10 text-primary": activeFilter !== 'all',
                        "pointer-events-none opacity-50": transferHistory?.length === 0
                    })}
                    onClick={() => handleFilter('all')}
                >
                    {strings.learn_together.moneybox_transfer.transfer_history.all}
                </button>
                <button 
                    className={classNames("px-6 py-2 rounded-[10px] text-[14px]", {
                        "bg-primary text-white": activeFilter === 'received',
                        "bg-primary/10 text-primary": activeFilter !== 'received',
                        "pointer-events-none opacity-50": !hasReceivedTransfers
                    })}
                    onClick={() => handleFilter('received')}
                >
                    {strings.learn_together.moneybox_transfer.transfer_history.received}
                </button>
                <button 
                    className={classNames("px-6 py-2 rounded-[10px] text-[14px]", {
                        "bg-primary text-white": activeFilter === 'sent',
                        "bg-primary/10 text-primary": activeFilter !== 'sent',
                        "pointer-events-none opacity-50": !hasSentTransfers
                    })}
                    onClick={() => handleFilter('sent')}
                >
                    {strings.learn_together.moneybox_transfer.transfer_history.sent}       
                </button>
            </div>

            {loading ? (    
                <div className="flex items-center justify-center w-full h-full">
                    <Loading classnames="mb-7" />
                </div>
            ) : (
                <>
                    {transferHistory.length === 0 ? (
                        <div className="flex items-center justify-center w-full h-full py-5">
                            <span className="text-[14px]">{strings.learn_together.moneybox_transfer.transfer_history.description}</span>
                        </div>
                    ) : (
                        <>
                            <div className="space-y-2.5">
                                {filteredHistory.map((item, index) => (
                                    <div key={index}>
                                        {item.moneyBoxActivityType === MoneyboxActivityType.DECREMENT ? (
                                            <SendContent 
                                                data={item}
                                                senderText={strings.learn_together.moneybox_transfer.transfer_history.sender} 
                                                formatDate={formatDate}
                                            />
                                        ) : (
                                            <ReceivedContent 
                                                data={item}
                                                receiverText={strings.learn_together.moneybox_transfer.transfer_history.receiver} 
                                                formatDate={formatDate}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    )
}

const SendContent = ({ data, senderText, formatDate }) => {
    return (
        <div className="flex flex-col p-4 bg-white rounded-[10px] border border-[#F5F5F5]">
        <div className="flex items-center justify-between mb-1">
            <div className="flex items-center">
                <span className="material-symbols-outlined text-primary rotate-[135deg] text-[28px] flex items-center">arrow_upward</span>
                <span className="text-[16px] font-bold">{data.transferredMemberFullName}</span>
            </div>
            <span>{formatDate(data.transferDate)}</span>
        </div>
        <div className="flex items-center justify-between">
            <div className="flex flex-col items-start">
                <span className="text-[14px] text-[#999]">{senderText}</span>
                    <span className="text-[14px]">{data.transferringMemberFullName}</span>
                </div>
                <span className="text-[24px] font-bold text-primary">%{data.transferRate}</span>
            </div>
        </div>
    );
}

const ReceivedContent = ({ data, receiverText, formatDate }) => {
    return (
        <div className="flex flex-col p-4 bg-white rounded-[10px] border border-[#F5F5F5]">
            <div className="flex items-center justify-between mb-1">
                <div className="flex items-center">
                    <span className="material-symbols-outlined text-secondary rotate-45 text-[28px] flex items-center">arrow_upward</span>
                    <span className="text-[16px] font-bold">{data.transferringMemberFullName}</span>
                </div>
                <span>{formatDate(data.transferDate)}</span>
            </div>
            <div className="flex items-center justify-between">
                <div className="flex flex-col items-start">
                    <span className="text-[14px] text-[#999]">{receiverText}</span>
                    <span className="text-[14px]">{data.transferredMemberFullName}</span>
                </div>
                <span className="text-[24px] font-bold text-secondary">%{data.transferRate}</span>
            </div>
        </div>
    );
}

export default TransferHistory; 